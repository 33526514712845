import React, { useMemo, useState } from "react";

import TableHead from "./TableHead";
import TableBody from "./TableBody";

import "./Table.css";

const Table = ({
  primaryAction,
  secondaryActions,
  headings,
  rows,
  onSelectItem,
  sorts,
  onSort,
  filters,
  onFilter,
  tableName,
  textEmpty
}) => {

  const filteredRows = useMemo(() => {
    if (!filters) return rows;
    
    return rows.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(filters.toLowerCase())
      )
    );
  }, [rows, filters]);

  return (
    <div className="Table-Container">
      {(
        primaryAction ||
        (secondaryActions && secondaryActions.length > 0) ) && (
        <div className="Table-Header">
          <div className="Table-Title-Container">
            <div className="Display-Text displaySmall">{tableName} </div>
          </div>
          {(primaryAction ||
            (secondaryActions && secondaryActions.length > 0) ) && (
            <div className="Table-Header-Actions">
              {primaryAction && (
                 <button
                 className={("Button primary")}
                 onClick={primaryAction.onAction}
               >
                 {/* {icon && React.createElement(icon)} */}
                 <span className="Button--Text">{primaryAction.label}</span>
               </button>
              )}
            </div>
          )}
        </div>
      )}
      <table className="Table">
        <TableHead headings={headings} />
        <TableBody rows={filteredRows} onSelectItem={onSelectItem} />
      </table>
      {filteredRows.length <= 0 ? (
        <p className="Table-Text-Empty">{textEmpty}</p>
      ) : null}
    </div>
  );
};

export default Table;