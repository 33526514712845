
import {
  faTimes,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './MultiCheckbox.css'

const MultiCheckbox = ({options, isEmptyValue, tooltip, label,disabled, dropdownRef, filterState, isOpenDropdown, searchState, setSearchState, filteredOptions, handleDropdownClick, toggleCheckbox, mapFunction, nameLabel, disabledItem, width }) => {

  return (
      <div style={{width:width}} className="dropdown__clients" ref={dropdownRef}>
        <div className="container__tooltipMultiCheckbox">
          <div className="label__multiCheckboxinputFilter">{label}</div>
          {tooltip  && label === "Team Name" && <div className="tooltip-containerMultiCheck" >
            <span className="tooltip__multiCheck">Select Team Name(s) to see all available Health Check Alerts.</span>
            <div>
              <FontAwesomeIcon icon={faInfoCircle} className="icon__multiCheck" />
            </div>
          </div>}
          {tooltip  && label ==='Operational Maturity Categories' && <div className="tooltip-containerMultiCheck" >
            <span className="tooltip__multiCheck"><a className="href__omm" href="https://support.pagerduty.com/main/docs/operational-maturity" target="_blank">Operational Maturity</a></span>
            <div>
              <FontAwesomeIcon icon={faInfoCircle} className="icon__multiCheck" />
            </div>
          </div>}
        </div>
        <div style={{opacity: disabled  ? '0.5'  : '' }} className="dropdown-toggleButtonMultiCheckbox" onClick={handleDropdownClick}>
          <div className="container__buttonFilterMultiCheckbox">
            {filterState.map(filter => (
              filteredOptions.map(item => (
                filter === mapFunction(item) && 
                <div key={mapFunction(item)} className="container__nameSelectedMultiCheckbox">
                  <p className="item__filterMultiCheckbox">{nameLabel(item)} 
                    <FontAwesomeIcon 
                    className="delete__multiCheckbox"
                    onClick={() => !disabled && mapFunction(item) !== null && toggleCheckbox(mapFunction(item))} icon={faTimes} /></p>
                </div>
              ))
            ))}
            {filterState.length === 0 && !isEmptyValue && (
                <div className="placeholder__select">Select {label}</div>
            )}
            {isEmptyValue && (
              <div className="placeholder__select">No {label} with Health Check Alerts</div>
            )}
          </div>
        </div>
        {isOpenDropdown && options.length > 0 &&(
            <div className="dropdown-menuMyAlerts">
              <ul >
                <input
                  className="search__subdomainHomeFilter input__filter"
                  type="text"
                  placeholder="Search..."
                  value={searchState}
                  onChange={(e) => setSearchState(e.target.value)}
                
                />
                {filteredOptions?.map(item => (
                  <li  key={item.id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={filterState.includes(mapFunction(item))}
                        onChange={() => mapFunction(item) !== null && toggleCheckbox(mapFunction(item))}
                        disabled={disabled || disabledItem ||  !mapFunction(item)}
                      />
                      <span className="customer_name">
                        {nameLabel(item)}
                        {label === 'Team Name' && ` - (${item.count})`}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}
    </div>
      );
}

export default MultiCheckbox