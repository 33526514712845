import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  BigBottomMenu,
  BigBottomMenuTaskContainer,
  BigMenuChevronContainer,
  BigMenuContainer,
  BigMenuIconContainer,
  BigMenuIconContainerInner,
  BigMenuIconContainerInnerIcon,
  BigMenuIconContainerInnerLabel,
  BigMenuIconSectionTitle,
  BigMenuLabel,
  BigMenuSubtaskContainer,
  BigMenuTaskContainer,
  LogoutLabel,
  MenuContainer,
  MenuIcon,
  MenuIconContainer,
  MenuIconIcon,
  MenuLabel,
  MenuLogoutButton,
  FeedbackIcon,
  MenuLogoutContainer,
  MenuSubMenu,
  MenuSubMenuFirstLabel,
  LogoIMG,
  LogoIMGFull,
  LogoContainer,
  FeedbackLabel,
  IconSection,
} from "./MenuStyled.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faPowerOff,
  faThumbsUp,
  faChalkboardUser,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import {
  controlTour,
  feedbackSubject,
  openMenuSubject,
  resetTourSteps,
} from "../../services/subjects.js";
import { useNavigate } from "react-router-dom";
import logoP from "../../assets/PagerDuty-WhiteRGB-p.svg";
import logo from "../../assets/PagerDuty-WhiteRGB.svg";
import divider from "../../assets/logo-divider.svg";
import { apiRoutes, apiRequest } from "./../../services";
import Paths from "./../../paths";
import Joyride from "react-joyride";
import Jwt from "./../../utils/jwt";
import TourCard from "../../pages/Home/Partials/PagerdutyReconnect/components/TourCard/TourCard.jsx";
import React from "react";
import { LeftDiv, MenuRegularIcon } from "../Navbar/NavbarStyled.js";
import HomeIcon from "../../assets/SVGs/homeIcon.js";
import CartIcon from "../../assets/SVGs/cartIcon.js";
import AlertIcon from "../../assets/SVGs/AlertIcon.js";
import RocketIcon from "../../assets/SVGs/RocketIcon.js";
import DocPlusIcon from "../../assets/SVGs/DocPlusIcon.js";
import ToolsIcon from "../../assets/SVGs/ToolsIcon.js";
import HearthLinealIcon from "../../assets/SVGs/HearthLinealIcon.js";
import TurnOnLinealIcon from "../../assets/SVGs/TurnOnLinealIcon.js";
import ScreenLinealIcon from "../../assets/SVGs/ScreenLinealIcon.js";
import ServerLinealIcon from "../../assets/SVGs/ServerLinealIcon.js";
import ReceiptLinealIcon from "../../assets/SVGs/ReceiptLinealIcon.js";
import WindowLinealIcon from "../../assets/SVGs/WindowLinealIcon.js";
import ThumbUpLinealIcon from "../../assets/SVGs/ThumbUpLinealIcon.js";
import PowerOnLinealIcon from "../../assets/SVGs/PowerOnLinealIcon.js";

interface Props {
  basePath: string;
  menuItems: Array<any>;
}

const Menu = forwardRef(({ basePath, menuItems }: Props, Ref) => {
  const ALWAYS_ACCESSIBLE_APPS = ["SolutionGallery"];
  const navigate = useNavigate();
  const [userOptions, setUserOptions] = useState<any>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<Array<string>>([]);
  const [stepIndex, setStepIndex] = useState(1);
  const [runTour, setRunTour] = useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<
    number | null
  >(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  );
  const [userRole, setUserRole] = useState<string>("");
  const [activeMenu, setActiveMenu] = useState<string>("");
  const SVGLiteral = {
    "Home": <HomeIcon />,
    "Solution": <CartIcon />,
    "Alerts": <AlertIcon />,
    "Success": <RocketIcon />,
    "Deck": <DocPlusIcon />,
    "AdminIcon": <ToolsIcon />,
    "HealthIcon": <HearthLinealIcon />,
    "OnboardingIcon": <TurnOnLinealIcon />,
    "DataViewerIcon": <ScreenLinealIcon />,
    "TerraformationIcon": <ServerLinealIcon />,
    "SnapshotIcon": <ReceiptLinealIcon />,
    "WindowIcon": <WindowLinealIcon />,
    "ThumbUp": <ThumbUpLinealIcon />,
    "PowerOn": <PowerOnLinealIcon />,
  }
  useEffect(() => {
    controlTour.subscribe((state) => {
      setRunTour(state);
    });
  }, []);
  useEffect(() => {
    const savedActiveMenu = localStorage.getItem('activeMenu');
    if (savedActiveMenu) {
      setActiveMenu(savedActiveMenu);
    }
  }, []);
  useEffect(() => {
    resetTourSteps.subscribe((state) => {
      setStepIndex(state);
    });
  }, []);

  const getRole = (tokenData) => {
    tokenData.claims.roles.map((role) => {
      setUserRole(role.role);
    });
};

  useEffect(() => {
    const tokenData = Jwt.getItem("all");
    if (tokenData !== null) {
        getRole(tokenData);
    }
}, []);

  useEffect(() => {
    userRole === "Free Trial Viewer" && sessionStorage.setItem("currentStep", stepIndex.toString());
  }, [stepIndex,userRole]);

  const steps = [
    {
      target: "#home-welcome-user-container",
      content: "", // we need this because if it start at 0 it will show the icon to start the tour instead of the step
    },
    {
      target: "#home-welcome-user-container",
      disableOverlayClose: true,
      content: (
        <TourCard
          title={"Here is your brief"}
          description={"You have all the information related to your profile"}
          currentStep={1}
          totalSteps={6}
          onNext={() => setStepIndex(2)}
          onClose={() => {
            setRunTour(false);
            setStepIndex(1);
          }}
        />
      ),
      hideFooter: true,
    },
    {
      target: "#container__btnPremiumRequest",
      disableOverlayClose: true,
      content: (
        <TourCard
          title={"Request a Service"}
          description={"You can place a service request related to your needs."}
          currentStep={2}
          totalSteps={6}
          onNext={() => {
            setMenuOpen(true);
            setTimeout(() => {
              setStepIndex(3);
            }, 150);
          }}
          onBack={() => setStepIndex(1)}
          onClose={() => {
            setRunTour(false);
            setStepIndex(1);
          }}
        />
      ),
      hideFooter: true,
    },
    {
      target: "#SolutionGalleryMenuOption",
      disableOverlayClose: true,
      content: (
        <TourCard
          title={"Solutions Gallery"}
          description={
            "Explore our Premium Service Solutions to accelerate your business."
          }
          currentStep={3}
          totalSteps={6}
          onNext={() => {
            setMenuOpen(false);
            setRunTour(false);
            navigate(Paths.SolutionGalleryNoId);
            setStepIndex(4);
          }}
          onBack={() => {
            setMenuOpen(false);
            if (window.location.pathname !== '/') {
              setRunTour(false);
              navigate(Paths.Base);
            }
            setStepIndex(2);
          }}
          onClose={() => {
            setRunTour(false);
            setStepIndex(1);
          }}
        />
      ),
      hideFooter: true,
      placement: "right-end",
    },
    {
      target: "#card__solutionTop",
      disableOverlayClose: true,
      content: (
        <TourCard
          title={"Most requested solutions"}
          description={
            "Explore the most requested Service Solutions to see its details and requirements."
          }
          currentStep={4}
          totalSteps={6}
          onNext={() => {
            setMenuOpen(true);
            setTimeout(() => {
              setStepIndex(5);
            }, 150);
          }}
          onBack={() => {
            setMenuOpen(true);
            setTimeout(() => {
              setStepIndex(3);
            }, 150);
          }}
          onClose={() => {
            setRunTour(false);
            setStepIndex(1);
          }}
        />
      ),
      hideFooter: true,
      placement: "right-start",
    },
    {
      target: "#MyAlertsAppMenuOption",
      disableOverlayClose: true,
      content: (
        <TourCard
          title={"Manage your Alerts"}
          description={
            "Search and filter your alerts by severity to keep your issues updated."
          }
          currentStep={5}
          totalSteps={6}
          onNext={() => {
            setMenuOpen(false);
            setRunTour(false);
            navigate(Paths.MyAlertsApp);
            setStepIndex(6);
          }}
          onBack={() => {
            setMenuOpen(false);
            if (window.location.pathname !== '/solution-gallery') {
              setRunTour(false);
              navigate(Paths.SolutionGalleryNoId);
            }
            setStepIndex(4);
          }}
          onClose={() => {
            setRunTour(false);
            setStepIndex(1);
          }}
        />
      ),
      hideFooter: true,
      placement: "right-start",
    },
    {
      target: "#advancedFilters",
      disableOverlayClose: true,
      content: (
        <TourCard
          title={"Filter and see your alerts"}
          description={
            "View services, details or submit a new Service Request related to your alerts."
          }
          currentStep={6}
          totalSteps={6}
          onNext={() => {
            setStepIndex(1);
            setRunTour(false);
          }}
          onBack={() => {
            setMenuOpen(true);
            setTimeout(() => {
              setStepIndex(5);
            }, 150);
          }}
          onClose={() => {
            setRunTour(false);
            setStepIndex(1);
          }}
        />
      ),
      hideFooter: true,
    },
  ];

  useImperativeHandle(Ref, () => ({}));

  useEffect(() => {
    const tokenData = Jwt.getItem("all");
    if (tokenData) {
      const userOptions = tokenData.claims.roles.flatMap((role) =>
        role?.apps?.map((app) => app.app)
      );
      setUserOptions(userOptions);

      tokenData?.claims?.roles.map((role) => {
        if (role.role === "Admin" || role.role === "Premium Csg Member") {
          setShowIcon(true);
        }
      });
    }
  }, []);

  const userHasPermission = (menuElement, userOptions) => {
    if (ALWAYS_ACCESSIBLE_APPS.includes(menuElement.appName)) {
      return true;
    }
    return userOptions.includes(menuElement.appName);
  }

  const filteredMenuItems = userOptions
    ? menuItems
        .map((section) => ({
          ...section,
          items: section.items.filter((item) =>
            userHasPermission(item, userOptions)
          ),
        }))
        .filter((section) => section.items.length > 0)
    : [];

  useEffect(() => {
    openMenuSubject.subscribe((state: boolean) => {
      setMenuOpen(state);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPage(
      window.location.pathname
        .split("/")
        .map((element) => element.replace(" ", ""))
        .filter((element: string) => element)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const logoutService = async () => {
    navigate(Paths.Logout);
  };

  return (
    <>
      <MenuContainer className={menuOpen ? "expandedMenu" : ""}  style={{ width: menuOpen ? "250px" : "60px" }}>
        <LogoContainer>
          {menuOpen ? (
            <LogoIMGFull src={logo} alt="logo" />
          ) : null /*(<LogoIMG src={logoP} alt="logo" />)*/
          }
          <LeftDiv>
            <MenuRegularIcon
              className={"center-corner"}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <FontAwesomeIcon icon={faBars} />
            </MenuRegularIcon>
          </LeftDiv>
        </LogoContainer>
        <img src={divider} alt="logo divider" />
      
        {!menuOpen &&
          filteredMenuItems
            .flatMap((section) => section.items)
            .map((menuElement, index) => (
              <MenuIcon key={index}>
                <MenuIconContainer
                className={activeMenu === menuElement.to ? "activeItemSmall" : ""}
                  selected={currentPage[1] && currentPage[1] === menuElement.to}
                  onClick={() => {
                    setActiveMenu(menuElement.to);
                    localStorage.setItem('activeMenu', menuElement.to);
                    navigate(menuElement.to);
                  }}
                >
                  <IconSection>
                    <MenuIconIcon>
                      {SVGLiteral[menuElement.icon]}
                    
                        {/* <FontAwesomeIcon icon={menuElement.icon} /> */}
                    </MenuIconIcon>
                  </IconSection>
                  <MenuSubMenuFirstLabel>
                    <span>{menuElement.label}</span>
                  </MenuSubMenuFirstLabel>
                </MenuIconContainer>
                {menuElement.subItems && (
                  <MenuSubMenu>
                    {menuElement.subItems.map((subItem: any) => (
                      <MenuLabel
                        selected={
                          currentPage[2] &&
                          currentPage[2] === subItem.label.replace(" ", "")
                        }
                        onClick={() =>
                          navigate(
                            `${menuElement.to}/${subItem.label.replace(
                              " ",
                              ""
                            )}`
                          )
                        }
                      >
                        {subItem.label}
                      </MenuLabel>
                    ))}
                  </MenuSubMenu>
                )}
              </MenuIcon>
            ))}
        {menuOpen &&
          filteredMenuItems.map((section, sectionIndex) => (
            <BigMenuContainer>
              <BigMenuIconSectionTitle>
                {section.sectionLabel}
              </BigMenuIconSectionTitle>
              {section.items.map((menuElement: any, index: number) => (
                <BigMenuIconContainer className={activeMenu === menuElement.to ? "activeItem" : ""}
                id={menuElement.label.replaceAll(" ", "") + "MenuOption"}
                >
                  <BigMenuTaskContainer>
                    <BigMenuIconContainerInner
                      selected={
                        currentPage[1] &&
                        currentPage[1] === menuElement.label.replace(" ", "")
                      }
                      onClick={() => {
                        setActiveMenu(menuElement.to); 
                        navigate(menuElement.to);
                        localStorage.setItem('activeMenu', menuElement.to);
                      }}
                    >
                      <BigMenuIconContainerInnerIcon>
                      {SVGLiteral[menuElement.icon]}
                        {/* <FontAwesomeIcon icon={menuElement.icon} /> */}
                      </BigMenuIconContainerInnerIcon>
                      <BigMenuIconContainerInnerLabel>
                        <span> {menuElement.label}</span>
                      
                      </BigMenuIconContainerInnerLabel>
                    </BigMenuIconContainerInner>
                    {menuElement.subItems && (
                      <BigMenuChevronContainer
                        selected={
                          index === selectedItemIndex &&
                          Sectionndex === selectedSectionIndex
                        }
                        onClick={() => {
                          setSelectedItemIndex(
                            index === selectedItemIndex ? null : index
                          );
                          setSelectedSectionIndex(
                            Sectionndex === selectedSectionIndex
                              ? null
                              : Sectionndex
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </BigMenuChevronContainer>
                    )}
                  </BigMenuTaskContainer>
                  {menuElement.subItems && (
                    <BigMenuSubtaskContainer
                      selected={
                        index === selectedItemIndex &&
                        Sectionndex === selectedSectionIndex
                      }
                    >
                      {menuElement.subItems.map((subItem: any) => (
                        <BigMenuLabel
                          selected={
                            currentPage[2] &&
                            currentPage[2] === subItem.label.replace(" ", "")
                          }
                          onClick={() =>
                            navigate(
                              `${menuElement.to}/${subItem.label.replace(
                                " ",
                                ""
                              )}`
                            )
                          }
                        >
                          {subItem.label}
                        </BigMenuLabel>
                      ))}
                    </BigMenuSubtaskContainer>
                  )}
                </BigMenuIconContainer>
              ))}
            </BigMenuContainer>
          ))}
        <MenuLogoutContainer>
          {!menuOpen && (
            <MenuLogoutContainer>
              {showIcon && (
                <MenuIcon>
                  <MenuIconContainer
                    onClick={() => navigate(Paths.SelectClient)}
                  >
                     <IconSection>
                      <MenuIconIcon>
                      {SVGLiteral.WindowIcon}
                      </MenuIconIcon>
                    </IconSection>
                    <MenuSubMenuFirstLabel><span>Customer View</span></MenuSubMenuFirstLabel>
                  </MenuIconContainer>
                </MenuIcon>
              )}
              <MenuIcon>
                <MenuIconContainer onClick={() => feedbackSubject.next(true)}>
                  <IconSection>
                    <MenuIconIcon>
                    {SVGLiteral.ThumbUp}
                    </MenuIconIcon>
                  </IconSection>
                  <MenuSubMenuFirstLabel><span>Feedback</span></MenuSubMenuFirstLabel>
                </MenuIconContainer>
              </MenuIcon>
              <MenuIcon>
                <MenuIconContainer
                  onClick={() => {
                    logoutService();
                  }}
                >
                  <IconSection>
                    <MenuIconIcon>
                    {SVGLiteral.PowerOn}
                    </MenuIconIcon>
                  </IconSection>
                  <MenuSubMenuFirstLabel><span>Sign Out</span></MenuSubMenuFirstLabel>
                </MenuIconContainer>
              </MenuIcon>
            </MenuLogoutContainer>
          )}
          {menuOpen && (
            <BigBottomMenu>
              <BigBottomMenuTaskContainer>
                {showIcon && (
                  <BigMenuIconContainerInner
                    onClick={() => navigate(Paths.SelectClient)}
                  >
                    <BigMenuIconContainerInnerIcon>
                    {SVGLiteral.WindowIcon}
                      {/* <FontAwesomeIcon icon={faChalkboardUser} /> */}
                    </BigMenuIconContainerInnerIcon>
                    <BigMenuIconContainerInnerLabel>
                      <span>
                      Customer View
                      </span>
                    </BigMenuIconContainerInnerLabel>
                  </BigMenuIconContainerInner>
                )}
              </BigBottomMenuTaskContainer>
              <BigBottomMenuTaskContainer>
                <BigMenuIconContainerInner
                  onClick={() => feedbackSubject.next(true)}
                >
                  <BigMenuIconContainerInnerIcon>
                    {SVGLiteral.ThumbUp}
                  </BigMenuIconContainerInnerIcon>
                  <BigMenuIconContainerInnerLabel>
                    <span>
                    Feedback
                    </span>
                  </BigMenuIconContainerInnerLabel>
                </BigMenuIconContainerInner>
              </BigBottomMenuTaskContainer>
              <BigBottomMenuTaskContainer>
                <BigMenuIconContainerInner
                  onClick={() => {
                    logoutService();
                  }}
                >
                  <BigMenuIconContainerInnerIcon>
                  {SVGLiteral.PowerOn}
                  </BigMenuIconContainerInnerIcon>
                  <BigMenuIconContainerInnerLabel>
                    <span>
                    Sign Out
                    </span>
                  </BigMenuIconContainerInnerLabel>
                </BigMenuIconContainerInner>
              </BigBottomMenuTaskContainer>
            </BigBottomMenu>
          )}
        </MenuLogoutContainer>
      </MenuContainer>
      <Joyride
        continuous={true}
        run={runTour}
        stepIndex={stepIndex}
        hideBackButton={true}
        hideCloseButton={true}
        steps={steps}
        disableScrolling={true}
        // styles={{
        //   options: {
        //     primaryColor: '#048A24',
        //   },
        // }}
      />
    </>
  );
});

export default Menu;
