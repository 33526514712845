import React from 'react';
import './VerticalCard.css'; 

const VerticalCard = ({ children, borderColor }) => {
  return (
    <div className="vertical__card" style={{ borderTop: `2px solid ${borderColor}`}}>
      {children}
    </div>
  );
};

export default  VerticalCard;
