import React from 'react';
import './ContainerCard.css'; 

const ContainerCard = ({ children  }) => {
  return (
    <div className="container__card">
      {children}
    </div>
  );
};

export default ContainerCard;
