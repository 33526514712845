import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pageyUser from "../../assets/pageyUser.png";
import DoughnutChart from "../../components/DoughnutChart";
import "./Home.css";
import {
  faFileMedicalAlt,
  faChessQueen,
  faBook,
  faExchangeAlt,
  faShip,
  faChartLine,
  faSwatchbook,
  faFilePowerpoint,
  faCompass,
  faNewspaper,
  faMagnifyingGlass,
  faBuildingUser,
  faIdCard,
  faIdCardClip,
  faUser,
  faUserShield,
  faUserSecret,
  faUserTag,
  faGear,
  faTrash,
  faSitemap,
  faGears,
  faPeopleGroup,
  faBell,
  faImage,
  faCalendarDays,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import Jwt from "../../utils/jwt";
import { ReactComponent as AlertIcon } from "../../assets/info.svg";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import ChartSlider from "../../components/ChartSlider";
import { apiRoutes, apiRequest } from "../../services";
import SkeletonProfile from "../../components/Skeletons/Home/SkeletonProfile";
import SkeletonPremiumService from "../../components/Skeletons/Home/SkeletonPremiumService";
import SkeletonSupportSummary from "../../components/Skeletons/Home/SkeletonSupportSummary";
import SkeletonProducts from "../../components/Skeletons/Home/SkeletonProducts";
import SkeletonPdUniversity from "../../components/Skeletons/Home/SkeletonPdUniversity";
import SkeletonBanner from "../../components/Skeletons/Home/SkeletonBanner";
import HomeUserNotCustomer from "./HomeUserNotCustomer";
import Copyright from "../../components/Copyright";
import Modal from "../../components/Modal";
import ModalHome from "../../components/ModalHome";
import ThankYou from "../../components/ThankYou";
import Pagination from "../../components/Pagination/index.js";
import { haveClientDeliveryPermissions } from "../../utils/clientDelivery.js";
import Dashboard from "./Dashboard.js";
import useCSV from "../../components/DownloadCSV/index.js";
import PagerDutyReconnect from "./Partials/PagerdutyReconnect/PagerdutyReconnect.jsx";
import CloudCheckMark from "../../assets/CloudCheckMark.svg";
import PremiumRequestModal from "../../components/PremiumRequestModal/PremiumRequestModal.js";
import PremiumRequestTable from "../PremiumRequest/PremiumRequestTable.js";
import PremiumSupportTable from "../PremiumRequest/PremiumSupportTable.js";
import { controlTour, reconnectModal, resetTourSteps, stepsState, freeTrialButton } from "../../services/subjects.js";
import AlertsMock from "./Mocks/AlertsMock.js";
import CalendarLinealIcon from "../../assets/SVGs/CalendarLinealIcon.js";
import HorizontalCard from "../../components/HorizontalCard/ HorizontalCard.js";
import ClockLinealIcon from "../../assets/SVGs/ClockLinealIcon.js";
import CartIcon from "../../assets/SVGs/cartIcon.js";
import CircleChart from "../../components/CircleChart/CircleChart.js";
import Table from "../../components/Table/Table.js";
import DocDoneLinealIcon from "../../assets/SVGs/DocDoneLinealIcon.js";
import EducationLinealIcon from "../../assets/SVGs/EducationLinealIcon.js";
import EyeLinealIcon from "../../assets/SVGs/EyeLinealIcon.js";
import DownloadLinealIcon from "../../assets/SVGs/DownloadLinealIcon.js";
import UxPagination from "../../components/UxPagination/UxPagination.js";
import VerticalCard from "../../components/VerticalCard"
import { colorBySeverity } from "../../utils/colors";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import TitleApp from '../../components/TitleApp'
import { useAuth } from "../../UserContext.js";
import CartAlert from "../../pages/MyAlertsApp/CartAlert/CartAlert.js"
import { showCartAlert } from "../../services/subjects.js";


export default function Home() {
  const { addAlertToCart, deleteAlertsInCart, cartAlert, clearAllAlerts } = useAuth()
  const [userName, setUserName] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userCSMName, setUserCSMName] = useState("");
  const [userSubdomainName, setUserSubdomainName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [caseSummary, setCaseSummary] = useState([]);
  const [currentPageCaseSummary, setCurrentPageCaseSummary] = useState(0);
  const [, setHasClientDeliveryDashboardApp] = useState(false);
  const [showClientDeliveryDashboard, setShowClientDeliveryDashboard] =
    useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [, setUserAuthenticationType] = useState("");
  const [profile, setProfile] = useState([]);
  const [premiumSupportSummary, setPremiumSupportSummary] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoadingPresupsum, setIsLoadingPresupsum] = useState(true);
  const [isLoadingAlerts, setIsLoadingAlerts] = useState(true);
  const [pduStatus, setPduStatus] = useState([]);
  const [isLoadingPduStatus, setIsLoadingPduStatus] = useState(true);
  const [pduStatusError, setPduStatusError] = useState(false);
  const [premiumServiceRequest, setPremiumServiceRequest] = useState([]);
  const [isLoadingPremiumServiceRequest, setIsLoadingPremiumServiceRequest] =
    useState(true);
  const [email, setEmail] = useState("");
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [products, setProducts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [showModalOngoingProjects, setShowModalOngoingProjects] =
    useState(false);
  const [showErrorCustomerRequest, setShowErrorCustomerRequest] =
    useState(false);
  const [showModalCustomerRequest, setShowModalCustomerRequest] = useState({
    title: "",
    show: false,
    alert: false,
    to: "",
  });
  const [formValues, setFormValues] = useState({
    requestType: "",
    requestUrgency: "Normal Urgency 7-10 Business Days",
    requestDetails: "",
  });
  const [loadingRequestForm, setLoadingRequestForm] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [bannerNews, setBannerNews] = useState(userRole !== "Free Trial Viewer" ? AlertsMock : []);
  const [bannerNewsError, setBannerNewsError] = useState(false);
  const [premiumRequest, setPremiumRequest] = useState([]);
  const [projectsByPage, setProjectsByPage] = useState(5);
  const [currentPagePduDetail, setCurrentPagePduDetail] = useState(1);
  const [pduDetailByPage, setPduDetailByPage] = useState(5);
  const [totalPageCaseSummary, setTotalPageCaseSummary] = useState(0);
  const [onSearchCaseSummary, setOnSearchCaseSummary] = useState("");
  const [, setCurrentQuarterStart] = useState("");
  const [, setCurrentQuarterEnd] = useState("");
  const [onSearchProjects, setOnSearchProjects] = useState("");
  const [showModalUniversitySummary, setShowModalUniversitySummary] =
    useState(false);
  const [showModalSummaryOfCases, setShowModalSummaryOfCases] = useState(false);
  const [buttonInfo, setButtonInfo] = useState([]);
  const [totalPagePduDetail, setTotalPagePduDetail] = useState(0);
  const [pduDetail, setPduDetail] = useState([]);
  const [searchPduDetail, setSearchPduDetail] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [caseSummaryByPage, setCaseSummaryByPage] = useState(5);
  const [totalPageProject, setTotalPageProject] = useState(0);
  const [showTrialNewsButton, setShowTrailNewsButton] = useState(false);
  const [professionalServicesSummary, setProfessionalServicesSummary] =
    useState([]);
  const [
    professionalServicesSummaryError,
    setProfessionalServicesSummaryError,
  ] = useState(false);
  const [
    currentPageProfessionalServicesSummary,
    setCurrentPageProfessionalServicesSummary,
  ] = useState(1);
  const [
    totalPageProfessionalServicesSummary,
    setTotalProfessionalServicesSummary,
  ] = useState(0);
  const [
    onSearchProfessionalServicesSummary,
    setOnSearchProfessionalServicesSummary,
  ] = useState("");
  const [
    professionalServicesSummaryByPage
  ] = useState(10);
  const [
    isLoadingProfessionalServicesSummary,
    setIsLoadingProfessionalServicesSummary,
  ] = useState(true);
  const [subdomain, setSubdomain] = useState([]);
  const [searchTermSubdomain, setSearchTermSubdomain] = useState("");
  const [filterSubdomain, setFilterSubdomain] = useState({});
  const [isOpenDropdownSubdomain, setIsOpenDropdownSubdomain] = useState(false);
  const dropdownRefSubdomain = useRef(null);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [selectedSubdomain, setSelectedSubdomain] = useState(false);
  const [isLoadingUsersSummary, setIsLoadingUsersSummary] = useState(false);
  const [isLoadingCaseSummary, setIsLoadingCaseSummary] = useState(false);
  const [profileError, setProfileError] = useState(false);
  const [productsError, setProductsError] = useState(false);
  const [premiumServiceRequestError, setPremiumServiceRequestError] =
    useState(false);
  const [premiumSupportSummaryError, setPremiumSupportSummaryError] =
    useState(false);
  const [totalNews, setTotalNews] = useState(0);
  const [loadingSupportSummaryCsv, setLoadingSupportSummaryCsv] =
    useState(false);
  const [loadingProjectCsv, setLoadingProjectCsv] = useState(false);
  const [openModalPagerDutyReconnect, setOpenModalPagerDutyReconnect] =
    useState(false);
  const [openModalWeAreWorkingOnIt, setOpenModalWeAreWorkingOnIt] =
    useState(false);
  const [openModalNewRequest, setOpenModalNewRequest] = useState(false);
  const [clientRole, setClientRole] = useState(null);
  const [profileRegion, setProfileRegion] = useState('');
  const { downloadCSV } = useCSV();
  const history = useNavigate();
  const [steps, setSteps] = useState([]);
  const [showModalOpenCart, setShowModalOpenCart] = useState(false);

  useEffect(() => {
    const subdomain =
      sessionStorage.subdomain && JSON.parse(sessionStorage.subdomain);
    if (subdomain) {
      setSelectedSubdomain(true);
      setFilterSubdomain(subdomain);
    } else {
      getSubdomain();
    }

    showCartAlert.subscribe((state) => {
      setShowModalOpenCart(state)
    });

  }, []);


  const getSupportSummaryDetail = async () => {
    setIsLoadingCaseSummary(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.supportSummaryDetail}?limit=${caseSummaryByPage}&page=${currentPageCaseSummary + 1}`,
    });
    if (data?.data?.data.length > 0) {
      setCaseSummary(data?.data?.data);
      setTotalPageCaseSummary(data?.data?.total_rows);
    }
    setIsLoadingCaseSummary(false);
  };

  const currentStepLocal = sessionStorage.getItem("currentStep");

  useEffect(() => {
    // Suscribirse al BehaviorSubject para obtener el estado actual de los pasos
    const subscription = stepsState.subscribe(setSteps);

    // Limpiar la suscripción cuando el componente se desmonte
    return () => subscription.unsubscribe();
  }, []);
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const element = document.getElementById('home-welcome-user-container');
          if (element) {
            // Obtener el estado actual de los pasos
            const currentSteps = stepsState.getValue();
            // Verificar y actualizar el estado de isLoaded
            if (currentSteps.step1.isLoaded) {
              controlTour.next(true);
              // Actualizar el estado de step1
              const updatedSteps = {
                ...currentSteps,
                step1: { isLoaded: false }
              };

              // Actualizar el BehaviorSubject solo si hubo cambios
              if (JSON.stringify(updatedSteps) !== JSON.stringify(currentSteps)) {
                stepsState.next(updatedSteps);
              }

              observer.disconnect(); // Deja de observar una vez que el elemento ha sido encontrado
              break;
            }
          }
        }
      }
    });

    // Comienza a observar el documento entero
    observer.observe(document.body, { childList: true, subtree: true });

    // Limpia el observer cuando el componente se desmonta
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (userRole === "Free Trial Viewer") {
      const mostrarModal = sessionStorage.getItem("mostrarModal");
      reconnectModal.subscribe(() => {
        setOpenModalPagerDutyReconnect(!openModalPagerDutyReconnect);
      });
      if (!mostrarModal) {
        (currentStepLocal === "1" || !currentStepLocal) && setOpenModalPagerDutyReconnect(true);
        sessionStorage.setItem("mostrarModal", true);
      }
    }
  }, [userRole]);


  useEffect(() => {
    if (userRole === "Free Trial Viewer" && currentStepLocal === "2" && !isLoadingProfile) {
      controlTour.next(true);
    }
  }, [currentStepLocal, isLoadingProfile]);

  const getClientDeliveryUserType = async () => {
    const token = Jwt.getItem("all");
    const role = getUserRole(token);
    setEmail(token?.sub);
    const hasApp = verifyClientDeliveryApp(token);
    if (role !== 'Customer' && hasApp) {
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.clientDeliveryType}`,
      });
      if (data?.type_key) {
        data.type_key === "DELIVERY_MANAGER_TEAM_MANAGER" ? setClientRole("SUPER_USER") : setClientRole(data.type_key);
        setShowClientDeliveryDashboard(haveClientDeliveryPermissions(data.type_key));
      }
    }
  };


  function getUserRole(token) {
    if (token?.claims?.roles && Array.isArray(token.claims.roles) && token.claims.roles.length > 0) {
      return token.claims.roles[0].role;
    }
    return null;
  }


  useEffect(() => {
    getClientDeliveryUserType();
    getProfile();
    getMenu();
  }, []);

  useEffect(() => {
    if ((userRole === 'Customer' || profile?.customer_dynamic_view_mode) && subdomain) {
      getSubdomain();
      getProfile();
      getPremiumRequest();
      getNews();
      getPremiumServiceRequest();
      getPDUniversity();
      getProfessionalServicesSummary();
      getProducts();
      getPremiumSupportSummary();
      setSelectedSubdomain(false);
    }
  }, [userRole, profile?.customer_dynamic_view_mode], subdomain);


  const getNewsTrial = async () => {
    setIsLoadingAlerts(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getNews}?showAlert=${1}&limit=5&page=1`,
    });
    if (data?.news?.news) {
      setBannerNews(data?.news?.news);
      setTotalNews(data?.news?.total_rows);
      setShowTrailNewsButton(true);
    }
    setIsLoadingAlerts(false);
  };

  useEffect(() => {
    if (userRole === "Free Trial Viewer") {
      getNewsTrial();
    }
  }, [userRole]);

  useEffect(() => {
    if (showModalUniversitySummary) {
      getPduDetail();
    }
  }, [currentPagePduDetail, showModalUniversitySummary]);

  useEffect(() => {
    if (showModalOngoingProjects) {
      getProjects();
    }
  }, [showModalOngoingProjects, currentPage, projectsByPage]);


  useEffect(() => {
    if (showModalSummaryOfCases) {
      getSupportSummaryDetail();
    }
  }, [showModalSummaryOfCases, currentPageCaseSummary, caseSummaryByPage]);


  const handlePageChange = (newPage) => {
    setCurrentPageCaseSummary(newPage);
  };

  const handlePremiumRequestPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setCaseSummaryByPage(newItemsPerPage);
  };

  const handlePremiumRequestItemsPerPageChange = (newItemsPerPage) => {
    setProjectsByPage(newItemsPerPage);
  };


  useEffect(() => {
    const closeDropdown = (event) => {
      if (
        dropdownRefSubdomain.current &&
        !dropdownRefSubdomain.current.contains(event.target)
      ) {
        setIsOpenDropdownSubdomain(false);
      }
    };

    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) {
      return "No data";
    }
    const date = new Date(dateString);
    const year = date.getUTCFullYear(); // Get the year as YYYY
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Get month as MM
    const day = String(date.getUTCDate()).padStart(2, "0"); // Get day as DD

    // Construct the formatted date string as 'YYYY-MM-DD'
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const getNews = async () => {
    setIsLoadingAlerts(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getNews}?showAlert=${1}&limit=5&page=1`,
    });
    if (data?.news?.news) {
      setBannerNews(data?.news?.news);
      setTotalNews(data?.news?.total_rows);
      setIsLoadingAlerts(false);
      setBannerNewsError(false);
    } else {
      setBannerNewsError(true);
      setIsLoadingAlerts(false);
    }
  };

  const verifyClientDeliveryApp = (token) => {
    if (!token.claims || !token.claims.roles || !Array.isArray(token.claims.roles)) {
      return false;
    }

    for (const role of token.claims.roles) {
      if (role.apps && Array.isArray(role.apps)) {
        const hasApp = role.apps.some(app => app.app === "ClientDeliveryDashboardApp");
        if (hasApp) {
          setHasClientDeliveryDashboardApp(true);
          return true;
        }
      }
    }
    setHasClientDeliveryDashboardApp(false);
    return false;
  }

  const getMenu = () => {
    const iconLiteral = {
      OnBoardingApp: () => faShip,
      SuccessOnDemandApp: () => faChessQueen,
      AdministrationApp: () => faBook,
      MigrationApp: () => faExchangeAlt,
      PsStatusApp: () => faChartLine,
      PDUStatusApp: () => faSwatchbook,
      NextDeckGen: () => faFilePowerpoint,
      HealthCheckApp: () => faFileMedicalAlt,
      HealthCheckDataViewer: () => faCompass,
      AlertsAdministration: () => faNewspaper,
      MyAlertsApp: () => faBell,
      SolutionGallery: () => faImage,
      CustomerSnapshots: () => faBell,
      TerraformerApp: () => faFileImport,
    };
    const urlApp = {
      OnBoardingApp: () => "/onboarding",
      SuccessOnDemandApp: () => "/success-on-demand",
      AdministrationApp: () => "/users",
      MigrationApp: () => "",
      PsStatusApp: () => "/ps-status",
      PDUStatusApp: () => "/pd-university",
      NextDeckGen: () => "/deck-generator",
      HealthCheckApp: () => "/health-check-client",
      HealthCheckDataViewer: () => "/health-check-data-viewer",
      AlertsAdministration: () => "/alerts-administration",
      MyAlertsApp: () => "/my-alerts-app",
      CustomerSnapshots: () => "/customer-snapshots",
      SolutionGallery: () => "/solution-gallery",
      TerraformerApp: () => "/terraformer",
    };
    const token = Jwt.getItem("all");
    let menu = token?.claims?.roles.map((role) => {
      setUserRole(role.role);
      if (role.role === "Free Trial Viewer") { setIsLoadingAlerts(false); }
      setUserAuthenticationType(token?.authentication_type);

      if (role.role === 'Admin' || role.role === 'Premium Csg Member') {
        const region = localStorage.getItem("region");
        setProfileRegion(region)
      }

      return role?.apps
        ?.reduce((acc, app) => {
          if (app.app && iconLiteral[app.app] && urlApp[app.app]) {
            acc.push({
              appName: app.app,
              icon: iconLiteral[app.app](),
              url: urlApp[app.app](),
            });
          }
          return acc;
        }, [])
        .concat({ role: role.role });
    });

    setUserName(token?.name);
    setUserOptions(menu[0]);
  };

  const makeANewRequest = async (section) => {
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.trialContact,
      data: {
        section: "Home"
      }
    });
    if (data?.status_code === 200) {
      setOpenModalNewRequest(true);
    }
  }

  const makeANewRequestAlert = async (section) => {
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.trialContact,
      data: {
        section: "Alert",
        "request_details": selectedAlert
      }
    });
    if (data?.status_code === 200) {
      setOpenModalNewRequest(true);
    }
  }

  const getProfileInfo = async () => {
    setIsLoadingProfile(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.freeTrialUserInfo,
    });

    if (data?.subdomains) {
      setUserCompanyName(data.subdomains.company_name);
      setUserCSMName(data.subdomains.free_trial_csm);
      setUserSubdomainName(data.subdomains.customer_subdomain);
    }
  };
  useEffect(() => {
    userRole !== "Free Trial Viewer" && getProfileInfo();
  }, [userRole]);

  const getProfile = async () => {
    setIsLoadingProfile(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.userProfile,
    });

    if (data?.data) {
      //data.data.customer_dynamic_view_mode = true
      setProfile(data?.data || []);
      setProfileRegion(data?.data.customer_region || []);
      setIsLoadingProfile(false);
    } else {
      setIsLoadingProfile(false);
      setIsLoadingProducts(false);
    }
  };

  const getPremiumSupportSummary = async () => {
    setIsLoadingPresupsum(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.premiumSupportSummary,
    });

    if (data.status_code === 200) {
      const filterDataIfAllTitlesZero = (data) => {
        const allTitlesZero = data.every((item) => item.title === "0");
        return allTitlesZero ? [] : data;
      };
      setPremiumSupportSummary(filterDataIfAllTitlesZero(data?.data));
      setPremiumSupportSummaryError(false);
    } else {
      setPremiumSupportSummaryError(true);
    }
    setIsLoadingPresupsum(false);
  };

  const getPDUniversity = async () => {
    setIsLoadingPduStatus(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.pduStatus,
    });

    if (data) {
      Object.keys(data?.data || {}).length > 0
        ? setPduStatus(data?.data)
        : setPduStatus(false);
      setPduStatusError(false);
    }
    if (error) {
      setPduStatusError(true);
    }
    setIsLoadingPduStatus(false);
  };

  const getPremiumServiceRequest = async () => {
    setIsLoadingPremiumServiceRequest(true);
    const [error, data] = await apiRequest({
     method: "get",
      url: apiRoutes.premiumServiceRequest,
    });
    if (data) {
      setPremiumServiceRequest(
      data?.data && Object.keys(data.data).length ? data.data : false
      );
      setCurrentQuarterStart(
        data?.data?.project_current_active_quarter_start_date || ""
      );
      setCurrentQuarterEnd(
        data?.data?.project_current_active_quarter_end_date || ""
      );
      setPremiumServiceRequestError(false);
    } else {
      setPremiumServiceRequestError(true);
    }
    setIsLoadingPremiumServiceRequest(false);
  };

  const getProducts = async () => {
    setIsLoadingProducts(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.productsHome,
    });
    if (!error) {
      setProducts(data?.products || []);
      setIsLoadingProducts(false);
    } else {
      setProductsError(true);
    }
    setIsLoadingProducts(false);
  };

  const getProjects = async () => {
    setIsLoadingProjects(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.projectsHome}?limit=5&page=${currentPage + 1}`,
    });

    if (Object.keys(data?.data?.data).length > 0) {
      setTotalPageProject(data?.data?.total_rows);
      setProjects(data?.data?.data);
    }
    setIsLoadingProjects(false);
  };

  const onInfoServiceRequest = () => {
    setShowModalOngoingProjects(!showModalOngoingProjects);
  };


  const getPremiumRequest = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.premiumRequest,
    });
    if (!error) {
      setPremiumRequest(data?.data?.request_severity);
      setButtonInfo(data?.data?.request_type);
    }
  };

  const handleInputChange = (e) => {
    const fieldName = e.target.name;

    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: e.target.value,
      requestType: showModalCustomerRequest.title,
    }));
  };



  const onSubmit = async () => {
    setLoadingRequestForm(true);
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.premiumRequest,
      data: {
        request_type: formValues.requestType,
        request_urgency: formValues.requestUrgency,
        request_details: formValues.requestDetails,
        request_alert_title: selectedAlert.title,
        request_customer_specify_person_name: showModalCustomerRequest.to,
      },
    });
    if (data.status === 200) {
      setShowThankYou(true);
      setLoadingRequestForm(false);
      setTimeout(() => {
        setShowThankYou(false);
        setFormValues({
          requestType: "",
          requestUrgency: "Normal Urgency 7-10 Business Days",
          requestDetails: "",
        });
      }, 2000);
    } else {
      setLoadingRequestForm(false);
      setShowErrorCustomerRequest(true);
    }
  };

  const onInfoModalOngoingProjects = async () => {
    setShowModalUniversitySummary(!showModalUniversitySummary);
  };

  const onInfoModalSummaryOfCases = async () => {
    setShowModalSummaryOfCases(!showModalSummaryOfCases);
  };

  const getPduDetail = async () => {
    setIsLoadingUsersSummary(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.pduDetail}?limit=5&page=${currentPagePduDetail}`,
    });

    if (data && Object.keys(data?.data || {}).length > 0) {
      setTotalPagePduDetail(data?.data?.total_rows);
      setPduDetail(data?.data?.data);
    }
    setIsLoadingUsersSummary(false);
  };

  const getProfessionalServicesSummary = async () => {
    setIsLoadingProfessionalServicesSummary(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.professionalServicesSummary}`,
    });
   if (!error) {
      setProfessionalServicesSummaryError(false);
      setProfessionalServicesSummary(data?.data?.professional_services || []);
      setTotalProfessionalServicesSummary(data?.data?.professional_services.length);
    } else {
      setProfessionalServicesSummaryError(true);
    }
    setIsLoadingProfessionalServicesSummary(false);
  };


  const TableHeadings = [
    ["Project Name"],
    ["Program Manager"],
    ["Consultant"],
  ]
  const updateCustomerDynamicViewMode = async () => {
    setIsLoadingExit(true);
    const [error, data] = await apiRequest({
      method: "put",
      url: apiRoutes.setCustomer,
    });
    if (data.status_code === 200) {
      getProfile();
      setProfileError(false);
    } else {
      setProfileError(true);
    }
    setIsLoadingExit(false);
  };

  const getSubdomain = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getSubdomainHome,
    });

    if (data?.subdomains?.length > 0) {
      // sessionStorage.setItem("subdomain", JSON.stringify(data?.subdomains));
      const subdomain = JSON.parse(sessionStorage.subdomain);
      !subdomain &&
        data?.subdomains &&
        data?.subdomains.length > 0 &&
        toggleCheckboxSubdomain(data.subdomains[0], true);
      setSubdomain(data?.subdomains);
    }
  };

  const handleChangePremiumRequest = (e) => {
    setShowRequestModal(true);
  };

  const handleSearchChangeSubdomains = (e) => {
    setSearchTermSubdomain(e.target.value);
  };
  const toggleCheckboxSubdomain = async (
    subdomain,
    dontChangeDropdownStatus
  ) => {
    if (userRole === "Customer" || profile?.customer_dynamic_view_mode) {
      setSelectedSubdomain(true);
      setFilterSubdomain(subdomain);
      !dontChangeDropdownStatus && handleDropdownClickSubdomain();

      const [error, data] = await apiRequest({
        method: "post",
        url: apiRoutes.setCustomer,
        data: {
          customer_subdomain: subdomain.subdomain_name,
          asana_sod_id: subdomain.asana_sod_id,
        },
      });

      if (
        (userRole === "Customer" || profile?.customer_dynamic_view_mode) &&
        subdomain &&
        data.status_code === 200
      ) {
        getSubdomain();
        getProfile();
        getPremiumRequest();
        getNews();
        getPremiumServiceRequest();
        getPDUniversity();
        getProfessionalServicesSummary();
        getProducts();
        getPremiumSupportSummary();
        setSelectedSubdomain(false);
      }
    }
  };

  const downloadServiceRequestCsv = async () => {
    setLoadingSupportSummaryCsv(true);
    try {
      await downloadCSV(
        `${apiRoutes.serviceRequestCsv}`,
        "Support Summary",
        "csv"
      );
    } catch (error) {
      console.error("Error downloading support summary CSV:", error);
    } finally {
      setLoadingSupportSummaryCsv(false);
    }
  };

  const downloadSupportSummary = async () => {
    setLoadingProjectCsv(true);
    try {
      await downloadCSV(`${apiRoutes.supportSummaryCsv}`, "Project", "csv");
    } catch (error) {
      console.error("Error downloading support summary CSV:", error);
    } finally {
      setLoadingProjectCsv(false);
    }
  };


  const handleDropdownClickSubdomain = () => {
    setIsOpenDropdownSubdomain(!isOpenDropdownSubdomain);
  };

  const filteredPduDetail = pduDetail?.filter((option) =>
    option?.member_name.toLowerCase().includes(searchPduDetail.toLowerCase())
  );

  const filteredProjects = projects?.filter((option) =>
    option?.task_name?.toLowerCase().includes(onSearchProjects.toLowerCase())
  );

  const filteredProfessionalServicesSummary = professionalServicesSummary?.filter((option) =>
    option?.project_name
      ?.toLowerCase()
      .includes(onSearchProfessionalServicesSummary.toLowerCase())
  );

  const filteredOptionsSubdomains = subdomain?.filter((option) =>
    option?.subdomain_name
      .toLowerCase()
      .includes(searchTermSubdomain.toLowerCase())
  );

  const convertPercentageToNumber = (value) => {
    const numericPart = value.replace("%", "");
    return Number(numericPart);
  };

  const showTextSeverity = (severity) => {
    const severityMapping = {
      1: "Critical",
      13: "Major",
      11: "High",
      4: "Medium",
      2: "Low",
      12: "Minor",
    };

    return severityMapping[severity] || "";
  };

  const backgroundStatus = (status) => {
    const severityMapping = {
      1: { backgroundStatus: "#FFE7E7", color: "#f00" },
      13: { backgroundStatus: "#ff872e", color: "#fff" },
      11: { backgroundStatus: "#f7c604", color: "#fff" },
      4: { backgroundStatus: "#690375", color: "#fff" },
      2: { backgroundStatus: "#329cad", color: "#fff" },
      12: { backgroundStatus: "#cecece", color: "#808080" },
    };
    return severityMapping[status] || "";
  };

  const returnIcon = (entity) => {
    const icon = {
      team: faPeopleGroup,
      service: faGears,
      escalation_policy: faSitemap,
      user: faUser,
      schedule: faCalendarDays,
    };

    return icon[entity] || "";
  };

  const onClickNewServiceRequest = (to) => {
    if (to !== "TBD") {
      setShowModalCustomerRequest({
        title: "Account Team Request",
        show: !showModalCustomerRequest.show,
        to: to,
      });
    }
  };

  const claimNow = async () => {
    const [error, data] = await apiRequest({
      method: "post",
      url: `${apiRoutes.freeTrial}`,
    });
    if (!error) {
      setOpenModalPagerDutyReconnect(false);
      setOpenModalWeAreWorkingOnIt(true);
      freeTrialButton.next(false);
    }
  };

  const customerTypeClassMap = (type) => {
    const customerTypeClass = {
      SILVER: "silver",
      GOLD: "gold",
    };
    return customerTypeClass[type] || "silver";
  };

  const renderExpectedValue = (parts) => {
    const expectedParts = parts[1].split(' The expected value is : ');
    return expectedParts.length > 1 ? expectedParts[1] : 'N/A';
  };

  const renderSections = (parsedValue) => (
    parsedValue?.metadata?.sections?.map((section, index) => (
      <div key={index} style={{ marginTop: '3px' }}>
        <span style={{ fontWeight: section.bold ? 'bold' : 'normal' }}>
          {section.label}:
        </span> {section.value}
      </div>
    ))
  );

  const disabledBtnSave =
    formValues.requestType !== "" &&
      formValues.requestUrgency !== "" &&
      formValues.requestDetails !== ""
      ? ""
      : "disabled__dtnSave";
  const disabledVieWAll = userOptions?.find(
    (item) => item.appName === "MyAlertsApp"
  )
    ? false
    : true;

  const indexOfLastItem = currentPageProfessionalServicesSummary * professionalServicesSummaryByPage;
  const indexOfFirstItem = indexOfLastItem - professionalServicesSummaryByPage;
  const currentItems = filteredProfessionalServicesSummary.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {isLoadingProfile && (
        <div className="spinner">
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      
      {!isLoadingProfile && (userRole === 'Customer' || profile?.customer_dynamic_view_mode || userRole === "Free Trial Viewer")  ?
        (<div className="home-container">
        <div className="home-wrapper">
          <div className="container__sectionHome">
            <div className="container__all">
              {(userRole === "Admin" ||
                userRole === "Premium Csg Member") && (
                  <div className="container__admin">
                    <div className="containerMessage">
                      <AlertIcon className="alert-icon" />
                      Customer Preview Mode (On Behalf Of) Granted for{" "}
                      {profile?.customer_name}.
                    </div>
                    <div onClick={() => updateCustomerDynamicViewMode()}
                    >
                      {isLoadingExit && (
                        <svg className="icon__svg" viewBox="25 25 50 50">
                          <circle
                            className="icon__circle"
                            r="20"
                            cy="50"
                            cx="50"
                          ></circle>
                        </svg>
                      )}
                      <CloseIcon className="iconAlertExit" />
                    </div>
                  </div>
                )}
              {selectedSubdomain && (
                <div className="subdomain__circle">
                  <svg className="icon__svg " viewBox="25 25 50 50">
                    <circle
                      className="icon__circleSubdomain"
                      r="20"
                      cy="50"
                      cx="50"
                    ></circle>
                  </svg>
                </div>
              )}
              {(userRole === "Customer" ||
                userRole === "Admin" ||
                userRole === "Premium Csg Member") &&
                filteredOptionsSubdomains.length > 1 && (
                  <div
                    className="containerContent card-dashboard-fit-sortingtable"
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="container__headerPremiumServiceRequest">
                      <span>Select Subdomain:</span>
                    </div>
                    <div className="container__multiSelectSubdomain">
                      <div
                        className="multiSelect__subdomainHome"
                        ref={dropdownRefSubdomain}
                      >
                        <button
                          className="dropdown-toggleButton"
                          onClick={() => handleDropdownClickSubdomain()}
                        >
                          {Object.keys(filterSubdomain).length === 0
                            ? "Filter by subdomain"
                            : filterSubdomain.subdomain_name}
                        </button>
                        {isOpenDropdownSubdomain && (
                          <div className="dropdown__subdomainHome">
                            <input
                              className="search__subdomainHomeFilter"
                              type="text"
                              placeholder="Search client..."
                              value={searchTermSubdomain}
                              onChange={handleSearchChangeSubdomains}
                            />
                            <ul>
                              {filteredOptionsSubdomains
                                ?.sort(
                                  (a, b) =>
                                    a.subdomain_name - b.subdomain_name
                                )
                                .map((option) => (
                                  <li key={option.project_id}>
                                    <label>
                                      <input
                                        type="radio"
                                        checked={
                                          filterSubdomain.subdomain_name ===
                                          option.subdomain_name
                                        }
                                        onChange={() =>
                                          toggleCheckboxSubdomain(option)
                                        }
                                      />
                                      <span className="customer_name">
                                        {option.subdomain_name}
                                      </span>
                                    </label>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              {isLoadingProfile && <SkeletonProfile />}
              {!isLoadingProfile && (
                <div
                  className={`container__profile ${profile?.customer_type === "Silver" ||
                    profile?.customer_type === "SILVER"
                    ? "container__startEndSilver"
                    : "container__startEnd"
                    }`}
                >
                  {profile !== null && profile?.customer_type !== null ? (
                    <div className="container__infoHome">
                      <div
                        style={{ display: "flex", width: "100%" }}
                        id="home-welcome-user-container"
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <div className="home-welcome-container-user">
                            <img
                              className="home-welcome-container-user-img"
                              src={pageyUser}
                            />
                          </div>
                          <p
                            className={customerTypeClassMap(
                              profile?.customer_type
                            )}
                          >
                            {userRole !== "Free Trial Viewer" ? profile?.customer_type : 'premium customer portal experience'}
                          </p>
                        </div>
                        <div className="header__infoProfile">
                          <div className="title__headerHome">
                            {userRole !== "Free Trial Viewer" ? profile?.customer_welcome_message : `So glad you're back, ${userName}! We've got some exciting things for you.`}
                          </div>
                          <div className="container__tooltipInfo">
                            <div className="tooltip-container">
                              <span className="tooltip__home">Company Name</span>
                              <div>
                                <FontAwesomeIcon
                                  icon={faBuildingUser}
                                  className="icon__infoHeader"
                                />
                                <span className="text">
                                  {userRole !== "Free Trial Viewer" ? profile?.customer_company_name : userCompanyName}
                                </span>
                              </div>
                            </div>
                            <div className="tooltip-container">
                              <span className="tooltip__home">Subdomain</span>
                              <div>
                                <FontAwesomeIcon
                                  icon={faGear}
                                  className="icon__infoHeader"
                                />
                                <span className="text">
                                  {userRole !== "Free Trial Viewer" ? profile?.customer_subdomain : userSubdomainName}
                                </span>
                              </div>
                            </div>
                            {userRole !== "Free Trial Viewer" && <div
                              className="tooltip-container"
                              onClick={() =>
                                onClickNewServiceRequest(
                                  profile?.account_manager
                                )
                              }
                            >
                              <span className="tooltip__home">
                                Account Manager
                              </span>
                              <div>
                                <FontAwesomeIcon
                                  icon={faBuildingUser}
                                  className="icon__infoHeader"
                                />
                                <span className="text">
                                  {profile?.account_manager}
                                </span>
                              </div>
                            </div>}
                            <div
                              className="tooltip-container"
                              onClick={() => userRole !== "Free Trial Viewer" ?
                                handleChangePremiumRequest() : makeANewRequest()}
                            //onClick={() => userRole !== "Free Trial Viewer" && onClickNewServiceRequest(profile?.customer_success_manager)}
                            >
                              <span className="tooltip__home">
                                Customer Success Manager
                              </span>
                              <div>
                                <FontAwesomeIcon
                                  icon={faIdCard}
                                  className="icon__infoHeader"
                                />
                                <span className="text">
                                  {userRole !== "Free Trial Viewer" ? profile?.customer_success_manager : userCSMName}
                                </span>
                              </div>
                            </div>
                            {profile?.customer_type === "Gold" && (
                              profile?.gold_program_manager !== '' && 
                              <div
                                className="tooltip-container"
                                onClick={() =>
                                  onClickNewServiceRequest(
                                    profile?.gold_program_manager
                                  )
                                }
                              >
                                <span className="tooltip__home">
                                  Gold Program Manager
                                </span>
                                <div>
                                  <FontAwesomeIcon
                                    icon={faIdCardClip}
                                    className="icon__infoHeader"
                                  />
                                  <span className="text">
                                    {profile?.gold_program_manager}
                                  </span>
                                </div>
                              </div>
                            )}
                            {userRole !== "Free Trial Viewer" && 
                              profile?.customer_success_engineer  !== '' && 
                              <div
                                className="tooltip-container"
                                onClick={() =>
                                  onClickNewServiceRequest(
                                    profile?.customer_success_engineer
                                  )
                                }
                              >
                                <span className="tooltip__home">
                                  Customer Success Engineer
                                </span>
                                <div>
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    className="icon__infoHeader"
                                  />
                                  <span className="text">
                                    {profile?.customer_success_engineer}
                                  </span>
                                </div>
                              </div>}
                            {userRole !== "Free Trial Viewer" && 
                              profile?.technical_support_engineer  !== '' && <div
                                className="tooltip-container"
                                onClick={() =>
                                  onClickNewServiceRequest(
                                    profile?.technical_support_engineer
                                  )
                                }
                              >
                                <span className="tooltip__home">
                                  Technical Support Engineer
                                </span>
                                <div>
                                  <FontAwesomeIcon
                                    icon={faUserShield}
                                    className="icon__infoHeader"
                                  />
                                  <span className="text">
                                    {profile?.technical_support_engineer}
                                  </span>
                                </div>
                              </div>}
                            {userRole !== "Free Trial Viewer" && 
                              profile?.engagement_manager  !== '' && <div
                                className="tooltip-container"
                                onClick={() =>
                                  onClickNewServiceRequest(
                                    profile?.engagement_manager
                                  )
                                }
                              >
                                <span className="tooltip__home">
                                  Program Manager
                                </span>
                                <div>
                                  <FontAwesomeIcon
                                    icon={faUserTag}
                                    className="icon__infoHeader"
                                  />
                                  <span className="text">
                                    {profile?.engagement_manager}
                                  </span>
                                </div>
                              </div>}
                            {userRole !== "Free Trial Viewer" && 
                            profile?.professional_services_lead !== '' &&
                              <div
                                className="tooltip-container"
                                onClick={() =>
                                  onClickNewServiceRequest(
                                    profile?.professional_services_lead
                                  )
                                }
                              >
                                <span className="tooltip__home">
                                  Professional Services Lead
                                </span>
                                <div>
                                  <FontAwesomeIcon
                                    icon={faUserSecret}
                                    className="icon__infoHeader"
                                  />
                                  <span className="text">
                                    {profile?.professional_services_lead}
                                  </span>
                                </div>
                              </div>}
                          </div>
                        </div>
                      </div>
                      {/* <div className="container__premiumRequestBtn">
                        <button id="container__btnPremiumRequest" className="container__btnPremiumRequestButton" onClick={() => userRole !== "Free Trial Viewer" ?
                          handleChangePremiumRequest() : makeANewRequest()}>Submit New Request Here
                        </button>
                      </div> */}
                    </div>
                  ) : profileError ? (
                    <div className="container__dataNotFound">
                      <div>
                        Oops, it looks like we weren’t able to find your
                        Customer Profile information. We kindly request you to
                        try Again later
                      </div>
                      <button
                        className="button__try"
                        onClick={() => getProfile()}
                      >
                        Try Again
                      </button>
                    </div>
                  ) : null}
                </div>
              )}


              {userRole !== "Free Trial Viewer" && <>

                {isLoadingPremiumServiceRequest && <SkeletonPremiumService />}
                {!isLoadingPremiumServiceRequest && premiumServiceRequest ? (
                  <div className="containerContent card-dashboard-fit-sortingtable">
                    <div>
                      <div className="projects-section-header">
                        <div className="container__headerPremiumServiceRequest">
                          <span>Service Requests Summary</span>
                        </div>
              
                      </div>
                      <div className="container__currentDate">
                        <div>
                          Current Period:{" "}
                          <span className="date__current">
                            {
                              premiumServiceRequest?.project_current_active_quarter_start_date
                            }
                          </span>{" "}
                          -{" "}
                          <span className="date__current">
                            {
                              premiumServiceRequest?.project_current_active_quarter_end_date
                            }
                          </span>
                        </div>
                      </div>
             
                      <div className="container__premiumServiceRequestCard">
                      <HorizontalCard className="gap-5 card__service" borderColor="#117170">
                        <div className="d-flex align-items-center gap-3">
                          <div className="HorizontalCardIcon">
                            <ClockLinealIcon />
                          </div>
                          <div className="d-flex flex-column">
                            <div className="title__card">
                              Hours Used{" "}
                            </div>
                            <div className="subtitle__premiumServiceRequestCard">
                              {premiumServiceRequest?.hours_used} /  {(premiumServiceRequest?.hours_used || 0) + (premiumServiceRequest?.hours_available || 0)}
                            </div>
                          </div>
                        </div>
                        <span className="percentage">
                          <CircleChart percentage={premiumServiceRequest?.used_hours_percentage} label={`${premiumServiceRequest?.used_hours_percentage}%`} />
                        </span>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5 card__service" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <ClockLinealIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                                Hours Available{" "}
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                                {premiumServiceRequest?.hours_available} /  {(premiumServiceRequest?.hours_used || 0) + (premiumServiceRequest?.hours_available || 0)}
                              </div>
                            </div>
                          </div>
                          <span className="percentage">
                          <CircleChart percentage={ premiumServiceRequest?.available_hours_percentage} label={`${premiumServiceRequest?.available_hours_percentage}%`} />
                          </span>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5 card__service" borderColor="#117170">
                        <div className="d-flex align-items-center gap-3">
                          <div className="HorizontalCardIcon">
                            <ClockLinealIcon />
                          </div>
                          <div className="d-flex flex-column">
                            <div className="title__card">Hours Expire</div>
                            <div className="subtitle__premiumServiceRequestCard">
                              {
                                premiumServiceRequest?.project_current_active_quarter_end_date
                              }
                            </div>
                          </div>
                        </div>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5 card__service" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <CartIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                                Open Requests{" "}
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                                {premiumServiceRequest?.open_cases}
                              </div>
                            </div>
                          </div>
                            <span className="percentage">
                            <CircleChart percentage={premiumServiceRequest?.percent_open} label={`${premiumServiceRequest?.percent_open}%`} />
                            </span>{" "}
                        </HorizontalCard>
                        <HorizontalCard className="gap-5 card__service" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <CartIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                                Closed Requests{" "}
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                                {premiumServiceRequest?.closed_cases}
                              </div>
                            </div>
                            </div>
                          <span className="percentage">
                          <CircleChart percentage={premiumServiceRequest?.percent_closed} label={`${premiumServiceRequest?.percent_closed}%`} />
                          </span>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5 card__service" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <CartIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                                Total Requests in Period{" "}
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                                {premiumServiceRequest?.total_cases}
                              </div>
                            </div>
                          </div>
                        </HorizontalCard>
            
                        <div className="d-flex w-100 gap-3 justify-content-end">
                          <div className="btn btn-secondary download__summary">
                            <div
                              onClick={() => downloadServiceRequestCsv()}
                            >
                              Download Service Requests
                            </div>
                            {loadingSupportSummaryCsv && (
                              <div>
                                <svg viewBox="25 25 50 50" className="small-svg">
                                  <circle r="20" cy="50" cx="50"></circle>
                                </svg>
                              </div>
                            )}
                          </div>
                          <span
                            style={{ marginRight: "10px" }}
                            className="btn align-items-center btn-secondary"
                            onClick={() => onInfoServiceRequest()}
                          >
                            <CalendarLinealIcon />
                            View Summary of Requests in Period
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : premiumServiceRequestError ? (
                  <div className="container__dataNotFound notfound__premiumServiceRequest">
                    <div>
                      Oops, it looks like we weren’t able to find your Premium
                      Service Request information. We kindly request you to try
                      Again later.
                    </div>
                    <button
                      className="button__try"
                      onClick={() => getPremiumServiceRequest()}
                    >
                      Try Again
                    </button>
                  </div>
                ) : null}

                {isLoadingPduStatus && <SkeletonPdUniversity />}
                {!isLoadingPduStatus && pduStatus ? (
                  <div className="containerContent card-dashboard-fit-sortingtable">
                    <div>
                      <div className="projects-section-header">
                        <div className="mb-3 container__headerPremiumServiceRequest">
                          <span>PagerDuty University Summary</span>
                        </div>
                      </div>
                      <div className="container__premiumServiceRequestCard">
                        <HorizontalCard className="gap-5" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <EducationLinealIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                              Instructor Led Training Purchased{" "}
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                              {pduStatus?.ilt_purchased}
                              </div>
                            </div>
                          </div>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <EducationLinealIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                              Instructor Led Training Available
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                              {pduStatus?.ilt_remaining}
                              </div>
                            </div>
                          </div>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <EducationLinealIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                              Registered Courses
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                              {pduStatus?.registered_courses}
                              </div>
                            </div>
                          </div>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <DocDoneLinealIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                              Completed Courses
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                              {pduStatus?.completed_courses}
                              </div>
                            </div>
                          </div>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                              <DocDoneLinealIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                              Certifications
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                              {pduStatus?.certificates}
                              </div>
                            </div>
                          </div>
                        </HorizontalCard>
                        <HorizontalCard className="gap-5" borderColor="#117170">
                          <div className="d-flex align-items-center gap-3">
                            <div className="HorizontalCardIcon">
                            <ClockLinealIcon />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="title__card">
                              Expiration Date
                              </div>
                              <div className="subtitle__premiumServiceRequestCard">
                              {formatDate(pduStatus?.contract_expiry)}
                              </div>
                            </div>
                          </div>
                        </HorizontalCard>
                      </div>
                      <div className="d-flex flex-wrap justify-content-end">
                        <div className="mt-3 btn btn-secondary gap-3"  onClick={() => onInfoModalOngoingProjects()}>
                            <EyeLinealIcon />
                            View Summary By User
                          {loadingSupportSummaryCsv && (
                            <div>
                              <svg viewBox="25 25 50 50" className="small-svg">
                                <circle r="20" cy="50" cx="50"></circle>
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : pduStatusError ? (
                  <div className="container__dataNotFound notfound__premiumServiceRequest">
                    <div>
                      Oops, it looks like we weren’t able to find your PagerDuty
                      University Summary information. We kindly request you to
                      try Again later.
                    </div>
                    <button
                      className="button__try"
                      onClick={() => getPDUniversity()}
                    >
                      Try Again
                    </button>
                  </div>
                ) : null}

                {!isLoadingProfessionalServicesSummary ? (
                  <div className="containerContent card-dashboard-fit-sortingtable">
                    <div>
                      <div className="projects-section-header">
                        <div className="container__headerPremiumServiceRequest">
                          <span>Professional Services Summary</span>
                        </div>
                      </div>
                      {professionalServicesSummary.length > 0 ? (
                        <>
                          <div className="container__searchProjects">
                            <div className="input__containerProjects">
                              <input
                                name="search-form"
                                id="search-form"
                                className="searchProjects__input"
                                placeholder="Search by name project name ..."
                                value={onSearchProfessionalServicesSummary}
                                onChange={(e) =>
                                  setOnSearchProfessionalServicesSummary(
                                    e.target.value
                                  )
                                }
                              />
                              <div className="icon__containerSearchProjects">
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                              </div>
                            </div>
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <Table filters={onSearchProfessionalServicesSummary}
                              rows={currentItems.map(service => [
                                service?.project_name,
                                service?.engagement_manager,
                                service?.consultant
                              ])}
                              headings={TableHeadings}
                            />
                            {/* <table className="projects-table">
                              <thead className="tablet__home">
                                <tr>
                                  <th>Project Name</th>
                                  <th>Engagement Manager</th>
                                  <th>Consultant</th>
                                </tr>
                                {currentItems?.map(
                                  (item) => (
                                    <tr className="danger-item">
                                      <td>
                                        <p>{item?.project_name}</p>
                                      </td>
                                      <td>
                                        <p>{item?.engagement_manager}</p>
                                      </td>
                                      <td>
                                        <p>{item?.consultant}</p>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </thead>
                            </table> */}
                          </div>
                          <div className="pagination_container">
                            <UxPagination
                              productsPage={professionalServicesSummaryByPage}
                              currentPage={
                                currentPageProfessionalServicesSummary
                              }
                              setCurrentPage={
                                setCurrentPageProfessionalServicesSummary
                              }
                              totalProducts={filteredProfessionalServicesSummary.length || 1}
                            />
                            <div className="container__totalProjects">
                              <div>
                                Total services:{" "}
                                {filteredProfessionalServicesSummary.length},{" "}
                                <span className="showing__total">
                                {`showing ${currentItems.length} elements`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : professionalServicesSummaryError ? (
                        <div className="container__dataNotFound notfound__premiumServiceRequest">
                          <div>
                            Oops, it looks like we weren’t able to find your
                            Professional Services Summary information. We kindly
                            request you to try Again later.
                          </div>
                          <button
                            className="button__try"
                            onClick={() => getProfessionalServicesSummary()}
                          >
                            Try Again
                          </button>
                        </div>
                      ) : (
                        <div className="container__dataNotFound notfound__premiumServiceRequest">
                          <div>
                            You haven't had a Professional Services Optimization
                            or Automation engagement in the past year, click
                            here to learn about our current Professional
                            Services offerings.
                          </div>
                          <button
                            className="button__try"
                            onClick={() =>
                              window.open(
                                "https://www.pagerduty.com/services/",
                                "_blank"
                              )
                            }
                          >
                            Click Here
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : professionalServicesSummaryError ? (
                  <div className="container__dataNotFound notfound__premiumServiceRequest">
                    <div>
                      Oops, it looks like we weren’t able to find your
                      Professional Services Summary information. We kindly
                      request you to try Again later.
                    </div>
                    <button
                      className="button__try"
                      onClick={() => getProfessionalServicesSummary()}
                    >
                      Try Again
                    </button>
                  </div>
                ) : null}

                <div className="container__productSupportSummar">
                  {isLoadingProducts && <SkeletonProducts />}
                  {isLoadingPresupsum && <SkeletonSupportSummary />}

                  {!isLoadingPresupsum && (
                    <div className="container__supportSummaryHome containerContent">
                      <div className="header__PremiumServiceRequest">
                        <span>Premium Support Summary</span>
                      </div>
                      {premiumSupportSummary?.length > 0 ? (
                        <div className="container_premiumSummary">
                          <div className="container_premiumSummary_items">
                          <div className="capsuleChartContainer">
                              {premiumSupportSummary?.map((item) => (
                                <div className="item_premiumSummary">
                                  <div className="item_premiumSummary_subtitle">
                                    {item?.subtitle}{" "}
                                  </div>
                                  <div className="itemPremiumSummaryNumber">{item.title}</div>
                                </div>
                              ))}
                            </div>
                            <div style={{ width: "210px" }}>
                              <DoughnutChart
                                labels={["Open cases %", "Closed cases %"]}
                                backgroundColors={["#06ac38", "#FFD300"]}
                                values={[
                                  convertPercentageToNumber(
                                    premiumSupportSummary[0].value
                                  ),
                                  convertPercentageToNumber(
                                    premiumSupportSummary[1].value
                                  ),
                                ]}
                                height="200px"
                                width="200px"
                                options={{ maintainAspectRatio: false }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : premiumSupportSummaryError ? (
                        <div className="container__dataNotFound">
                          <div>
                            Oops, it looks like we weren’t able to find your
                            Premium Support Summary information. We kindly
                            request you to try Again later.
                          </div>
                          <button
                            className="button__try"
                            onClick={() => getPremiumSupportSummary()}
                          >
                            Try Again
                          </button>
                        </div>
                      ) : (
                        <div className="container__dataNotFound notfound__premiumServiceRequest">
                          <div>
                            You don't have Premium Support Summary information.
                          </div>
                        </div>
                      )}
                      <div className="mt-4 d-flex justify-content-end gap-3 align-items-center">
                        {premiumSupportSummary?.length > 0 && (
                            <span
                              className="btn btn-secondary"
                              onClick={() => onInfoModalSummaryOfCases()}
                            >
                              Case Summaries
                              <EyeLinealIcon />
                            </span>
                          )}
                        <div className="selectSession__button button__andSpinner download__summary ">
                          <div
                            onClick={() => downloadSupportSummary()}
                            className="btn btn-secondary"
                          >
                            Download Summary
                            {loadingProjectCsv && (
                            <div>
                              <svg viewBox="25 25 50 50" className="small-svg">
                                <circle r="20" cy="50" cx="50"></circle>
                              </svg>
                            </div>
                          )}
                           <DownloadLinealIcon />
                          </div>
                  
                        </div>
                      </div>
                    </div>
                  )}
                  {!isLoadingProducts && (
                    <div className="container__productHome containerContent">
                      <div className="container__headerPremiumServiceRequest title__myProducts">
                        <span>My Products</span>
                      </div>
                      {products?.length > 0 ? (
                        <>
                          <ChartSlider menuItems={products}></ChartSlider>
                          <div className="products__total">
                            Total Amount:{" "}
                            <span style={{ color: "#06AC38" }}>
                              {products?.length}
                            </span>
                          </div>{" "}
                        </>
                      ) : productsError ? (
                        <div className="container__dataNotFound notfound__products">
                          <div>
                            Oops, it looks like we weren’t able to find your
                            Product information. We kindly request you to try
                            Again later.
                          </div>
                          <button
                            className="button__try"
                            onClick={() => getProducts()}
                          >
                            Try Again
                          </button>
                        </div>
                      ) : (
                        <div className="container__dataNotFound notfound__premiumServiceRequest">
                          <div>You don't have Products information.</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>}

              {userRole === "Free Trial Viewer" && <img src="https://images.innovation.csg.pagerduty.com/Group+354.png" alt="Free Trial Viewer" className="free-trial-viewer-blur-img" />}
            </div>
            <div className="container__alerts">
              {isLoadingAlerts && <SkeletonBanner />}
              {!isLoadingAlerts && (
                <div className="containeAlertPills">
                  <div className="container__viewAllAlert d-flex align-items-center">
                    <div>
                      <h2 className="new-title__home">Latest Alerts</h2>
                      <p>{totalNews} Total Alerts</p>
                    </div>
                    {totalNews > 0 && (
                      <button
                        disabled={disabledVieWAll}
                        onClick={() => history("/my-alerts-app")}
                        className={`btn h-100 btn-secondary ${
                          disabledVieWAll && "disabled__dtnSave"
                        }`}
                      >
                        View Alerts
                      </button>
                    )}
                    </div>
                  {bannerNews?.length > 0 ? (
                    <>
                      {bannerNews?.slice(0, 20).map((item) => (
                        <VerticalCard borderColor={colorBySeverity(item?.severity)?.color}>
                          <div  key={item} className="container__healthCheckAlerts">
                            <div className="healthCheckAlerts__header">
                                <div style={{color: colorBySeverity(item?.severity)?.color}} >{showTextSeverity(item?.severity)}</div>
                                <div className="healthCheckAlerts__headerDate">
                                    <div className="headerDate__alert"> 
                                      <Calendar/>
                                      <span className="creation_date">{item?.creation_date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="healthCheckAlerts__body">
                              <TitleApp>{item?.title}</TitleApp>
                              <div className="body__entity" >
                                <div>
                                  <FontAwesomeIcon  icon={returnIcon(
                                  item.pd_entities
                                    ? item.pd_entities
                                    : item.pd_entities_labels
                                )} /></div>
                                <div>{item?.pd_entities_labels ? item?.pd_entities_labels : item?.pd_entities}</div>
                              </div>
                              <div className="body__descriptionAlert">{item?.description?.split('The current value is : ')[0]?.replace('. .', '.') || 'N/A'}</div>
                              <p className="description__newHome">
                                {(() => {
                              const parts = item?.description?.split('The current value is : ');;
                              if (parts && parts.length > 1) {
                                const currentValue = parts[1]?.split(' The expected value is : ')[0];

                                const parsedValue = currentValue?.startsWith('{') ? JSON.parse(currentValue) : null;

                                return (
                                  <>
                                    {(parsedValue !== null ?
                                      <div>
                                        <b className="expected__value" > The current value is : </b> {parsedValue?.current_value || 'N/A'}
                                        <p className="description__newHome">
                                          <b className="expected__value">The expected value is : </b>
                                          <span className="body__descriptionAlert">
                                            {(() => {
                                              const parts = item?.description?.split('The current value is : ');
                                              if (parts && parts.length > 1) {
                                                const expectedParts = parts[1].split(' The expected value is : ');
                                                if (expectedParts.length > 1) {
                                                  return expectedParts[1] || 'N/A';
                                                }
                                              }
                                              return 'N/A';
                                            })()}
                                          </span>
                                        </p>
                                        <div style={{ marginBottom: '15px', marginTop: '10px' }}>
                                          <b >{parsedValue?.metadata?.title}:</b>
                                          <div style={{ marginTop: '4px', marginBottom: '15px' }} >
                                            {parsedValue?.metadata?.sections?.map((section, index) => (
                                              <div key={index} style={{ marginTop: '3px' }}>
                                                <div>
                                                  <span style={{ fontWeight: section.bold ? 'bold' : 'normal' }}>{section.label}: </span>
                                                  <>
                                                    {section.value}
                                                  </>
                                                </div>
                                              </div>))}
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <>
                                        <b className="expected__value">The current value is : </b> <span className="body__descriptionAlert">{currentValue || 'N/A'}</span>
                                        <p className="description__newHome">
                                          <b className="expected__value">The expected value is : </b>
                                          <span className="body__descriptionAlert">
                                              {(() => {
                                              const parts = item?.description?.split('The current value is : ');
                                              if (parts && parts.length > 1) {
                                                const expectedParts = parts[1].split(' The expected value is : ');
                                                if (expectedParts.length > 1) {
                                                  return expectedParts[1] || 'N/A';
                                                }
                                              }
                                              return 'N/A';
                                            })()}
                                          </span>
                                        </p>
                                      </>

                                    ) || 'N/A'}
                                  </>
                                )
                              }
                            })()}
                              </p>
                              <div>
                              {userRole !== "Free Trial Viewer" && cartAlert?.some(alert => alert?.id === item?.id) ? 
                                <button onClick={() => {
                                  deleteAlertsInCart(item?.id)
                                  }} 
                                  className="btn__serviceRequest"><FontAwesomeIcon icon={faTrash} /></button> :
                                  <button onClick={() => {
                                      addAlertToCart(item)
                                    }} 
                                    className="btn__serviceRequest">Service Request</button> 
                                  }
                                {/* {userRole !== "Free Trial Viewer" && <button
                                    onClick={() => {
                                      setSelectedAlert(item);
                                      requestSupport();
                                    }}
                                    className="btn__serviceRequest"
                                  >
                                    Service Request
                                  </button>} */}
                                {(userRole === "Free Trial Viewer" && !showTrialNewsButton) && <button
                                  onClick={() => {
                                    setSelectedAlert(item)
                                    makeANewRequestAlert()
                                  }}
                                  className="btn__serviceRequest"
                                >
                                  Service Request
                                </button>}
                              </div>
                            </div>
                          </div>
                        </VerticalCard>
                        // <div className="news-item__home">
                        //   <h3 className="title__home">{item?.title}</h3>
                        //   <div className="container__entity">
                        //     <div className="icon__entity">
                        //       <FontAwesomeIcon
                        //         icon={returnIcon(
                        //           item.pd_entities
                        //             ? item.pd_entities
                        //             : item.pd_entities_labels
                        //         )}
                        //       />
                        //     </div>
                        //     <div>
                        //       {item.pd_entities_labels
                        //         ? item.pd_entities_labels
                        //         : item.pd_entities}
                        //     </div>
                        //   </div>
                        //   <p className="description__newHome">
                        //     {item?.description
                        //       .split("The current value is : ")[0]
                        //       .replace(". .", ".")}
                        //   </p>
                        //   <p className="description__newHome">
                        //     {(() => {
                        //       const parts = item?.description?.split('The current value is : ');;
                        //       if (parts && parts.length > 1) {
                        //         const currentValue = parts[1]?.split(' The expected value is : ')[0];

                        //         const parsedValue = currentValue?.startsWith('{') ? JSON.parse(currentValue) : null;

                        //         return (
                        //           <>
                        //             {(parsedValue !== null ?
                        //               <div>
                        //                 <b > The current value is : </b> {parsedValue?.current_value || 'N/A'}
                        //                 <p className="description__newHome">
                        //                   <b>The expected value is : </b>
                        //                   {(() => {
                        //                     const parts = item?.description?.split('The current value is : ');
                        //                     if (parts && parts.length > 1) {
                        //                       const expectedParts = parts[1].split(' The expected value is : ');
                        //                       if (expectedParts.length > 1) {
                        //                         return expectedParts[1] || 'N/A';
                        //                       }
                        //                     }
                        //                     return 'N/A';
                        //                   })()}
                        //                 </p>
                        //                 <div style={{ marginBottom: '15px', marginTop: '10px' }}>
                        //                   <b >{parsedValue?.metadata?.title}:</b>
                        //                   <div style={{ marginTop: '4px', marginBottom: '15px' }} >
                        //                     {parsedValue?.metadata?.sections?.map((section, index) => (
                        //                       <div key={index} style={{ marginTop: '3px' }}>
                        //                         <div>
                        //                           <span style={{ fontWeight: section.bold ? 'bold' : 'normal' }}>{section.label}: </span>
                        //                           <>
                        //                             {section.value}
                        //                           </>
                        //                         </div>
                        //                       </div>))}
                        //                   </div>
                        //                 </div>
                        //               </div>
                        //               :
                        //               <>
                        //                 <b>The current value is : </b> {currentValue || 'N/A'}
                        //                 <p className="description__newHome">
                        //                   <b>The expected value is : </b>
                        //                   {(() => {
                        //                     const parts = item?.description?.split('The current value is : ');
                        //                     if (parts && parts.length > 1) {
                        //                       const expectedParts = parts[1].split(' The expected value is : ');
                        //                       if (expectedParts.length > 1) {
                        //                         return expectedParts[1] || 'N/A';
                        //                       }
                        //                     }
                        //                     return 'N/A';
                        //                   })()}
                        //                 </p>
                        //               </>

                        //             ) || 'N/A'}
                        //           </>
                        //         )
                        //       }
                        //     })()}
                        //   </p>
                        //   <div className="meta__home">
                        //     <span
                        //       className="urgency__home"
                        //       style={{
                        //         backgroundColor: backgroundStatus(
                        //           item?.severity
                        //         ).backgroundStatus,
                        //         color: backgroundStatus(item.severity).color,
                        //       }}
                        //     >
                        //       {showTextSeverity(item.severity)}
                        //     </span>
                        //     <span className="date__home">
                        //       {item?.creation_date}
                        //     </span>
                        //   </div>
                        //   <div>
                        //     {userRole !== "Free Trial Viewer" && <button
                        //       onClick={() => {
                        //         setSelectedAlert(item);
                        //         requestSupport();
                        //       }}
                        //       className="btn__requestSupport"
                        //     >
                        //       Service Request
                        //     </button>}
                        //     {(userRole === "Free Trial Viewer" && !showTrialNewsButton) && <button
                        //       onClick={() => {
                        //         makeANewRequestAlert()
                        //       }}
                        //       className="btn__requestSupport"
                        //     >
                        //       Service Request
                        //     </button>}
                        //   </div>
                        // </div>
                      ))}
                      {/* <button disabled={disabledVieWAll} onClick={() => history('/my-alerts-app')} className={`view-all ${disabledVieWAll && 'disabled__dtnSave'}`}>View All</button> */}
                    </>
                  ) : bannerNewsError ? (
                    <div className="container__dataNotFound notfound__banners">
                      <div>
                        Oops, it looks like we weren’t able to find your
                        Alerts information. We kindly request you to try Again
                        later.
                      </div>
                      <button
                        className="button__try try__alerts"
                        onClick={() => getNews()}
                      >
                        Try Again
                      </button>
                    </div>
                  ) : (
                    <div className="container__dataNotFound notfound__banners">
                      <div className="home_text_1_no_alerts">
                        Health Check Alerts Coming Soon!
                      </div>
                      {profileRegion !== "JAPAN" && <div className="home_text_2_no_alerts">
                        We're working completing the Health Check and
                        Technical Account Review for your domain.
                      </div>}
                      {profileRegion !== "JAPAN" && <div className="home_text_3_no_alerts">
                        When complete, you will see actionable Health Check
                        Alerts here!
                      </div>}
                      {profileRegion === "JAPAN" && <div className="home_text_2_no_alerts">
                        Are you interested in seeing the health of your current PagerDuty environment? Please contact your PagerDuty Account Team and request to be onboarded for PagerDuty Automated Health Check services for Premium Customers.
                      </div>}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="container__copyrightHome-home">
              <Copyright />
            </div>
          </div>
        </div>
      </div>
      ) : !isLoadingProfile && !showClientDeliveryDashboard ? (
        <HomeUserNotCustomer
          userOptions={userOptions}
          role={userRole}
          name={userName}
        />
      ) : (
        !isLoadingProfile &&
        showClientDeliveryDashboard && (
          <Dashboard name={userName} role={userRole} clientRole={clientRole} />
        )
      )}
      {/* {!isLoadingProfile && (!profile[0]?.customer_dynamic_view_mode) &&
        <HomeUserNotCustomer
        userOptions={userOptions}
        role={userRole}
        name={userName}
        /> 
      } */}
      <Modal
        show={showModalOngoingProjects}
        handleClose={() => {
          setShowModalOngoingProjects(!showModalOngoingProjects);
          setProjects([]);
        }}
        title={"Service Requests in Period"}
      >
        {!isLoadingProjects ? (
          projects?.length > 0 ? (
            <>
              {filteredProjects?.length > 0 &&
                <PremiumRequestTable
                  projects={filteredProjects}
                  totalProjects={totalPageProject}
                  currentPage={currentPage}
                  setCurrentPage={handlePremiumRequestPageChange}
                  itemsPerPage={projectsByPage}
                  setItemsPerPage={handlePremiumRequestItemsPerPageChange}
                />
              }
            </>
          ) : (
            <div
              className="container__dataNotFound notfound__projects"
              style={{ marginBottom: "20px" }}
            >
              You don't have Service Request Period information.
            </div>
          )
        ) : (
          <div className="spinner">
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
      </Modal>
      <Modal
        show={showModalUniversitySummary}
        handleClose={() => {
          setShowModalUniversitySummary(!showModalUniversitySummary);
          setPduDetail([]);
        }}
        title={"Users Summary"}
      >
        {!isLoadingUsersSummary ? (
          filteredPduDetail?.length > 0 ? (
            <>
              <div className="container__searchProjects">
                <div className="input__containerProjects">
                  <input
                    name="search-form"
                    id="search-form"
                    className="searchProjects__input"
                    placeholder="Search by member name..."
                    value={searchPduDetail}
                    onChange={(e) => setSearchPduDetail(e.target.value)}
                  />
                  <div className="icon__containerSearchProjects">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </div>
                </div>
              </div>
              <div className="container__paginationSolution">
                <Pagination
                  productsPage={pduDetailByPage}
                  currentPage={currentPagePduDetail}
                  setCurrentPage={setCurrentPagePduDetail}
                  totalProducts={totalPagePduDetail || 1}
                />
                <div className="container__totalProjects">
                  <div>
                    Total services {filteredPduDetail?.length},{" "}
                    <span className="showing__total">
                      showing {pduDetailByPage} Elements
                    </span>
                  </div>
                </div>
              </div>
              <div className="projects mb-4">
                <div className="projects-inner">
                  <table className="projects-table">
                    <thead>
                      <tr>
                        <th>User Name</th>
                        <th>User Email </th>
                        <th>User Registered Courses</th>
                        <th>User Completed Courses</th>
                        <th>User Certificates</th>
                      </tr>
                    </thead>
                    {filteredPduDetail?.map((item) => (
                      <tr className="danger-item">
                        <td>
                          <p>{item?.member_name}</p>
                        </td>
                        <td>
                          <p>{item?.member_email}</p>
                        </td>
                        <td>
                          <p>{item?.member_registered_courses}</p>
                        </td>
                        <td>
                          <p>{item?.member_completed_courses}</p>
                        </td>
                        <td>
                          <p>{item?.member_certificates}</p>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </>
          ) : (
            <div
              className="container__dataNotFound notfound__projects"
              style={{ marginBottom: "20px" }}
            >
              You don't have Pagerduty University User information.
            </div>
          )
        ) : (
          <div className="spinner">
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
      </Modal>
      {showRequestModal && <PremiumRequestModal showRequestModa={showRequestModal} subdomain={filterSubdomain.subdomain_name} email={email} setShowRequestModal={setShowRequestModal} />}
      <Modal
        show={showModalSummaryOfCases}
        handleClose={() => {
          setShowModalSummaryOfCases(!showModalSummaryOfCases);
        }}
        title={"Case Summary"}
      >
        {!isLoadingCaseSummary ?
          caseSummary.length > 0 &&
          <PremiumSupportTable
            dataArray={caseSummary}
            totalPageCaseSummary={totalPageCaseSummary}
            currentPageCaseSummary={currentPageCaseSummary}
            setCurrentPageCaseSummary={handlePageChange}
            itemsPerPage={caseSummaryByPage}
            setItemsPerPage={handleItemsPerPageChange}
          /> :
          (
            <div className="spinner">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )
        }
      </Modal>
      <ModalHome
        show={openModalPagerDutyReconnect}
        handleClose={() =>
          setOpenModalPagerDutyReconnect(!openModalPagerDutyReconnect)
        }
      >
        <PagerDutyReconnect
          onClaim={() => {
            claimNow();
          }}
          onStartTour={() => {
            setOpenModalPagerDutyReconnect(false);
            resetTourSteps.next(1);
            controlTour.next(true);
          }}
        />
      </ModalHome>
      <ModalHome
        show={openModalWeAreWorkingOnIt}
        handleClose={() =>
          setOpenModalWeAreWorkingOnIt(!openModalWeAreWorkingOnIt)
        }
        title={"We Are Working On It"}
        modalFitContent
      >
        <div>
          <div className="popupModalHomeSmall">
            <div
              className="round__icon__modal"
              style={{ backgroundColor: "#E2F0E7" }}
            >
              <img src={CloudCheckMark} alt="" role="display" />
            </div>
            <div className="popupModalHomeSmallText">
              <span className="popupModalHomeSecondSmallTextTitle">
                Congratulations! You placed your upgrade account successfully,
                it could take up to{" "}
                <span className="popupModalHomeSecondBoldSmallTextTitle">
                  48 business hours
                </span>{" "}
                until you get all the features, thank you for your patience.
              </span>
            </div>
          </div>
          <button
            onClick={() => {
              setOpenModalWeAreWorkingOnIt(false);
            }}
            className="popupModalHomeSmallButton"
          >
            Close
          </button>
        </div>
      </ModalHome>
      <ModalHome
        show={openModalNewRequest}
        handleClose={() =>
          setOpenModalNewRequest(!openModalNewRequest)
        }
        title={"New Request"}
        modalFitContent
      >
        <div>
          <div className="popupModalHomeSmall">
            <div
              className="round__icon__modal"
              style={{ backgroundColor: "#E2F0E7" }}
            >
              <img src={CloudCheckMark} alt="" role="display" />
            </div>
            <div className="popupModalHomeSmallText">
              <span className="popupModalHomeSecondSmallTextTitle">
                Thank you for your interest, your Customer Success Manager will follow up in the next{" "}
                <span className="popupModalHomeSecondBoldSmallTextTitle">
                  72 business hours!
                </span>{" "}
              </span>
            </div>
          </div>
          <button
            onClick={() => {
              setOpenModalNewRequest(false);
            }}
            className="popupModalHomeSmallButton"
          >
            Close
          </button>
        </div>
      </ModalHome>
      {showModalOpenCart && 
        <CartAlert
        userRole={userRole}
        setShowModalOpenCart={setShowModalOpenCart}
        setOpenFreeTrialModal={setOpenModalNewRequest}
        />
      }
    </>
  );
}
