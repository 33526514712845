export default {
  login: "/mfa/",
  loginOauth: "/mfa/oauth2",
  profile: "/user/profile",
  createSession: "/user/session",
  logout: "/user/destroySession",
  featureFlags: "/ms-home/adm_dashboard_review/feature-flags",
  all: "neptune/findAll",
  findObjects: "neptune/findAvailableObjects",
  findByObjectName: "neptune/findByObjectName",
  findByObjectFields: "neptune/findObjectFields",
  createEdge: "/neptune/createEdge",
  deleteEdge: "/neptune/deleteEdge",
  deleteNode: "/neptune/deleteVertex",
  deleteMultipleEdges: "/neptune/deleteMultipleEdge",
  deleteMultpleNodes: "/neptune/deleteMultipleVertex",
  getMigrationProjects: "/ms-migrationv2-web/migrationv2/getProjectList",
  generateMigrationScript: "/ms-migrationv2-web/migrationv2/generateMigrationScript",
  readLogFiles: "/ms-migrationv2-web/migrationv2/readLogFile",
  readLogFolder: "/ms-migrationv2-web/migrationv2/readLogFolder",
  readSettings: "/ms-migrationv2-web/migrationv2/readSettings",
  readSettingsFile: "/ms-migrationv2-web/migrationv2/readSettingsFile",
  setProjectUrl: "/ms-migrationv2-web/migrationv2/setProjectEc2bUrl",
  updateSettings: "/ms-migrationv2-web/migrationv2/updateSettings",
  startMigration: "/ms-migrationv2-web/migrationv2/runMigration",
  startPopulation: "/ms-migrationv2-web/migrationv2/runPopulation",
  monitorStatus: "/ms-migrationv2-web/migrationv2/monitorMigrationStatus",
  statusLog: "/ms-migrationv2-web/migrationv2/readRunStatusLine",
  getLogFolders: "/ms-migrationv2-web/migrationv2/getDataFolders",
  menu: "ms-home/menu/user",
  users: "/ms-home/user/",
  getRoles: "/ms-home/role/",
  userProfile: "/ms-home/cst_dashboard_review/profile",
  userProfileAdmin: "/ms-home/adm_dashboard_review/profile",
  userFeedback: "/ms-home/feedback/",
  getManagers: "/ms-home/user/manager",
  userDetails: "/ms-home/user/details",
  techDetails: "/ms-sod/Tech_Details/querys_days_remaining/",
  slaReport: "/ms-sod/apiReports/sla_report/",
  graphSku: "ms-sod/apiGraph/graph_sku/1",
  graphSku2: "/ms-sod/apiGraph/graph_sku/",
  dataRow: "/ms-sod/Projects/projectssectionDetails/",
  postCsm: "ms-sod/apiUpdateAsana/updateAsanaProjects_csm/",
  lastInfo: "/ms-sod/Tech_Details/getlastinfo_sectionDetails/",
  listProjects: "/ms-sod/Tech_Details/list_of_projects_hours/",
  successOnDemandlastDataLoaded: "ms-sod/Tech_Details/getlastupdatecrone/",
  milestoneCsv: "/ms-sod/apiReports/sla_report_csv/",
  allCsv: "/ms-sod/apiReports/csv_report_all_projects/",
  detailCsv: "/ms-sod/apiReports/csv_report_all_projects_detail_tasks/",
  editModalSod: "/ms-sod/apiUpdateAsana/updateAsanaProjects/",
  HealthCkeckService: "/ms-optimizer/health-check/services/",
  healthCkecklastDataLoaded: "/ms-optimizer/health-check/metrics/execution-date",
  healthCkeckEntities: "/ms-optimizer/health-check/metrics/available-entities",
  healthCkeckTeams: "/ms-optimizer/health-check/teams/",
  healthCkeckTopTen: "/ms-optimizer/health-check/metrics/services/incidents/top-ten",
  healthCkeckUsers: "/ms-optimizer/health-check/users/",
  healthCkeckBestPractice: "/ms-optimizer/health-check/metrics/services/best-practices/count",
  healthCkeckBestPracticeUsers: "/ms-optimizer/health-check/users/best-practices/count",
  healthCkeckMetricsCore: "/ms-optimizer/health-check/metrics/core",
  healthCkeckTeamInfo: "/ms-optimizer/health-check/teams/meta_data?teams_ids=",
  healthCkeckMetrics: "/ms-optimizer/health-check/metrics/meditions/",
  healthCheckClients: "/stacks/",
  healthCheckStacks: "/stacks/byClientName",
  healthCheckDataViewerDomains: "/stacks/domains",
  healthCheckDataViewerDomainsAvailableDates: "/stacks/folders/",
  healthCheckDataViewerInfo: "/stacks/analysis/",
  healthCheckDataViewerEntities: "/stacks/analysis/entities/",
  healthCheckDataViewerGetPDF: "/stacks/analysis/PDF",
  healthCheckDataViewerSendPDF: "/stacks/analysis/PDF/email",
  healthCheckDataViewerEntitiesInfo: "/configuration/entities/",
  healthCheckDataViewerTeamsList: "/reports/failures/",
  healthCheckDataViewerPPTemplate: "/reports/infographic/",
  teamRoles: "/ms-onboarding/team_role/",
  baseRoles: "/ms-onboarding/base_role/",
  schedulesDescription: "/ms-onboarding/schedule/",
  onboardingDraft: "/ms-onboarding/draft/",
  onboardingSubmit: "/ms-onboarding/setup/",
  onboardingGetSubdomain: "/ms-onboarding/setup/subdomain/",
  avgSku: "/ms-sod/apiGraph/graph_average_sku/",
  avgNotStarted: "/ms-sod/apiGraph/graph_average_days_not_kicked/",
  notStarted: "/ms-sod/apiGraph/graph_projects_not_kicked/",
  fiscalYears: "/ms-sod/apiGraph/filter_for_fiscal_years/",
  psLoadTime: '/ms-ps-status/projects/execution_date',
  psProjects: "/ms-ps-status/projects/revenue",
  psProjectsStatusUpdates: "/ms-ps-status/projects/professional-services",
  psProjectsHistoric: "/ms-ps-status/reports/general/historic",
  psStatusAppLastDataLoaded: "/ms-ps-status/projects/execution-date",
  psStatusCount: "/ms-ps-status/reports/revenue/portfolio-count",
  psStatusValueSum: "/ms-ps-status/reports/revenue/order-value-sum",
  psStatusCountRecent: "/ms-ps-status/reports/revenue/portfolio-count/up-to-date",
  psStatusValueSumRecent: "/ms-ps-status/reports/revenue/order-value-sum/up-to-date",
  psStatusPortfolio: "/ms-ps-status/projects/portfolios",
  psStatusExportGeneralHistorical: "/ms-ps-status/reports/general/csv/historical",
  psStatusExportGeneralUpToDate: "/ms-ps-status/reports/general/csv/up-to-date",
  psStatusExportRevenueHistorical: "/ms-ps-status/reports/revenue/csv/historical",
  psStatusExportRevenueUpToDate: "/ms-ps-status/reports/revenue/csv/up-to-date",
  psStatusProjectsByRole: "/ms-ps-status/projects/professional-services/filtered",
  psStatusSkus: "/ms-ps-status/projects/skus",
  pduLoadTime: "/ms-pdu-status/asana/execution_date",
  pduVilt: "/ms-pdu-status/skilljar/vilt/sessions/trained-users",
  pduGold: "/ms-pdu-status/asana/projects/gold",
  pduSilver: "/ms-pdu-status/asana/projects/silver",
  pduSkilljarGroup: "/ms-pdu-status/skilljar/groups",
  pduItlDetail: "/ms-pdu-status/asana/projects/",
  pduSkilljarFiltered: "/ms-pdu-status/skilljar/groups/not-valid-names",
  createDeck: "/ms-nextgen/deck/generate_deck",
  deckAccounts: "/ms-nextgen/deck/customers_list",
  deckTour: "/ms-nextgen/deck/tour",
  premiumSupportSummary: "/ms-home/cst_dashboard_review/presupsum",
  pduStatus: "/ms-home/cst_dashboard_review/pdustatus",
  pduStatusAdmin: "/ms-home/adm_dashboard_review/pdustatus",
  premiumServiceRequest: "/ms-home/cst_dashboard_review/preserreq",
  premiumServiceRequestAdmin: "/ms-home/adm_dashboard_review/preserreq",
  productsHomeAdmin: "/ms-home/adm_dashboard_review/products",
  productsHome: "/ms-home/cst_dashboard_review/products",
  projectsHome: "/ms-home/cst_dashboard_review/projects",
  projectsHomeAdmin: "/ms-home/adm_dashboard_review/projects",
  getNews: "/ms-home/cst_dashboard_review/news",
  getNewsAdmin: "/ms-home/adm_dashboard_review/news",
  getNewsType: "/ms-home/cst_dashboard_review/newstype",
  createAndUpdateNews: "/ms-home/cst_dashboard_review/news",
  getNewsClients: "/ms-home/cst_dashboard_review/get_client",
  getNewsClientsAdmin: "/ms-home/adm_dashboard_review/get_client",
  newsStatus: "/ms-home/cst_dashboard_review/news_status",
  getNewsTeam: "/ms-home/cst_dashboard_review/get_team",
  getSeverity: "/ms-home/cst_dashboard_review/severity",
  premiumRequest: "/ms-home/premium_request/",
  getClients: "/ms-home/adm_dashboard_review/customers_list",
  getSubdomain: "/ms-home/adm_dashboard_review/customers_subdomain",
  getCustomersEmail: "/ms-home/adm_dashboard_review/customers_email",
  pduDetail: "/ms-home/cst_dashboard_review/pdu_detail",
  professionalServicesSummary: '/ms-home/cst_dashboard_review/professional_services',
  supportSummaryDetail: 'ms-home/cst_dashboard_review/support_summary_detail',
  getSubdomainHome: '/ms-home/adm_dashboard_review/set_customer',
  getEntities: '/ms-home/cst_dashboard_review/pd_entities',
  setCustomer: "/ms-home/adm_dashboard_review/set_customer",
  getCSMdashboard: "/ms-csm-pm-360-dashboard/csm-pm/dashboard-summaries",
  getCSMdashboardModels: "/ms-csm-pm-360-dashboard/csm-pm/delivery-model",
  getCSMdashboardDeliveryData: "/ms-csm-pm-360-dashboard/csm-pm/delivery-summary?domains=deltaairlines%2Cthehomedepot",
  postCSMdashboardDeliveryData: "/ms-csm-pm-360-dashboard/csm-pm/delivery-summary",
  snapshot: "/ms-customer-snapshot/adm_dashboard_review/customer/snapshot",
  snapshotcustomers: "ms-customer-snapshot/adm_dashboard_review/customers",
  solutionType: "/ms-solution-gallery/solutions_gallery/solution_type",
  solutionCategory: "/ms-solution-gallery/solutions_gallery/solution_category",
  operationCloudProducts: "/ms-solution-gallery/solutions_gallery/operation_cloud_products",
  getOperationCloudArea: "/ms-solution-gallery/solutions_gallery/operation_area",
  classifications: "ms-solution-gallery/solutions_gallery/solution_classification",
  solutionFeature: "/ms-solution-gallery/solutions_gallery/solution_feature",
  solutionProduct: "/ms-solution-gallery/solutions_gallery/solutions",
  hoursExpire: "/ms-solution-gallery/solution_gallery/customer/preserreq",
  solutionCart: "/ms-solution-gallery/solutions_gallery/solutions_car",
  filterEntity: "/ms-home/cst_dashboard_review/filter_entity",
  multiservicerequest: "/ms-home/premium_request/multiservicerequest",
  getGWP: "/get_well_plan/recommendation",
  statusAlerts: '/ms-home/cst_dashboard_review/news_status_filter',
  getTotalAlert: '/ms-home/cst_dashboard_review/total_entity',
  getSeverityEntity: '/ms-home/cst_dashboard_review/entity_severity',
  getEntityStatus: '/ms-home/cst_dashboard_review/entity_status',
  getStatusSummary: '/ms-home/cst_dashboard_review/status_summary',
  getMonthly: '/ms-home/cst_dashboard_review/alerts/monthly',
  getAllTeam: '/ms-home/cst_dashboard_review/news/related_entities',
  downloadMonthlyOverview: '/ms-home/cst_dashboard_review/news/monthly-overview',
  downloadAlerts: '/ms-home/cst_dashboard_review/alerts_csv',
  getCSMdashboard: "/ms-csm-pm-360-dashboard/csg-operations/client-delivery-dashboard",
  getCSMdashboardModels: "/ms-csm-pm-360-dashboard/csg-operations/delivery-model",
  getCSMdashboardDeliveryData: "/ms-csm-pm-360-dashboard/csg-operations/delivery-summary",
  supportSummaryCsv: "/ms-home/cst_dashboard_review/support-summary-csv",
  serviceRequestCsv: "/ms-home/cst_dashboard_review/service-request-csv",
  clientDeliveryCsvExport: '/ms-csm-pm-360-dashboard/csm-pm/dashboard-summaries/reports?report_name',
  getFilterTeams: '/ms-home/cst_dashboard_review/filter-teams',
  freeTrial: '/ms-home/free-trial/healthcheck',
  freeTrialUserInfo: '/ms-home/adm_dashboard_review/customer_data',
  getTotalAlertType: '/ms-home/cst_dashboard_review/news/session_type_filter',
  geBetterWorse: '/ms-home/cst_dashboard_review/news/session_entities_filter',
  clientDeliveryCsvExport: '/ms-csm-pm-360-dashboard/csg-operations/client-delivery-dashboard/reports?report_name',
  clientDeliveryType: "/ms-csm-pm-360-dashboard/csg-operations/csg-members/type-key",
  clientDeliveryTypesOptions: "/ms-csm-pm-360-dashboard/csg-operations/csg-member-types",
  clientDeliveryMembers: "/ms-csm-pm-360-dashboard/csg-operations/csg-members",
  teamManager: "/ms-csm-pm-360-dashboard/csg-operations/csg-members/subordinates",
  trialContact: "/ms-home/free-trial/contact",
  alertCountBySubdomains: "ms-home/cst_dashboard_review/news/alert_count_by_active_session",
  alertTypeMonthly: "/ms-home/premium_request/alerts/type/monthly-results",
  getMonthTeam: "/ms-home/cst_dashboard_review/news/month-over-month/team",
  getOmmCategories:'/ms-home/cst_dashboard_review/news/omm_categories',
  tferApp: '/ms-terraformer/api/v1',
  tferAppAPICredentials: '/ms-terraformer/api/v1/api-credentials',
  tferAppCustomers: '/ms-terraformer/api/v1/customers',
  tferAppCustomersAPICredentialsStatus: '/ms-terraformer/api/v1/customers/api-credentials-status',
  tferAppFAQs: '/ms-terraformer/api/v1/faqs',
  tferAppTerraformations: '/ms-terraformer/api/v1/terraformations',
  tferAppRemoteConfig: '/ms-terraformer/api/v1/remote-config',
}
