import React, { useState } from "react";

const TableBody = ({ rows, onSelectItem }) => {
  return (
    <tbody className="Table-Body">
      {rows &&
        rows.map((row, i) => (
          <TableRow
            key={`table-row-${i}`}
            id={i}
            row={row}
            onSelectItem={onSelectItem}
          />
        ))}
    </tbody>
  );
};

const TableRow = ({ onSelectItem, row, id }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOver = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  // console.log("row", row);
  return (
    <tr>
      {row &&
        row.map((item, i) => {
          // console.log("item", item);
          if (!item) return (<td></td>)
          else
            return (
              <td
                key={`table-body-cell-${i}`}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                onClick={() => onSelectItem && onSelectItem(id)}
              >
                {typeof item !== "string" && item?.type !== React.Fragment
                  ? React.createElement(item?.type, {
                    ...item?.props,
                    active: isHovered,
                  })
                  : item}
              </td>
            );
        })}
    </tr>
  );
};

export default TableBody;
