import { useState  } from "react";
import  './Pagination.css';

 const  Pagination = ({productsPage, currentPage, setCurrentPage, totalProducts}) => { 
  
  const pageNumber = []

  for(let i= 1; i <= Math.ceil(totalProducts / productsPage); i++) {
    pageNumber.push(i)
  }

  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1)
  }
  const onNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const onSpecificPage = (page) => {
    setCurrentPage(page)
  }

  return (
    <div className="pagination__container">
        <div >
          <button className="btn__pagination" disabled={currentPage === 1} onClick={() => onPreviousPage()}>Previous</button>
        </div>
          <div className="containerNumber__page">
            {pageNumber.map(page => (
              <div className={`number__page ${page === currentPage && 'current__page'}`} onClick={() => onSpecificPage(page)} key={page}>{page}</div>
            ))}
          </div>
        <div >
          <button className="btn__pagination" disabled={currentPage >= pageNumber.length} onClick={() => onNextPage()}>Next</button>
        </div>
    </div>
  )
}

export default Pagination;