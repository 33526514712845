import { forwardRef, useImperativeHandle } from "react";
import {
  ChartContainerAbsolute,
  ChartContainerRelative,
} from "./CircleChartStyled.js";
import Chart from "react-apexcharts";

const CircleChart = forwardRef(({ color = "#117170", label, percentage }, ref) => {
    useImperativeHandle(ref, () => ({}));

    return (
      <ChartContainerRelative>
        <ChartContainerAbsolute>
          <Chart
            options={{
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      show: !!label,
                      fontSize: "14px",
                      offsetY: 3,
                    },
                    value: {
                      show: !label,
                      offsetY: 5,
                      color: color,
                    },
                    total: {
                      show: false,
                    },
                  },
                },
              },
              stroke: {
                lineCap: "round",
              },
              labels: [label || ""],
              colors: [color],
            }}
            series={[percentage]}
            type="radialBar"
            height={72 + 90}
          />
        </ChartContainerAbsolute>
      </ChartContainerRelative>
    );
  },
);

CircleChart.displayName = "CircleChart";

export default CircleChart;
