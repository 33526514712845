import { useState } from "react";
import './UxPagination.css';

const UxPagination = ({ productsPage, currentPage, setCurrentPage, totalProducts }) => {
  const totalPages = Math.ceil(totalProducts / productsPage);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const showEllipsis = totalPages > 10;

    if (showEllipsis) {
      if (currentPage <= 4) {
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else if (currentPage >= totalPages - 3) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1, 2, 3, '...');
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
        pageNumbers.push('...');
        pageNumbers.push(totalPages - 1, totalPages);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const onSpecificPage = (page) => {
    if (typeof page === 'number') {
      setCurrentPage(page);
    }
  };

  return (
    <div className="pagination__container">
      <div>
        <button 
          className="btn__pagination" 
          disabled={currentPage === 1} 
          onClick={onPreviousPage}
        >
          Previous
        </button>
      </div>
      <div className="containerNumber__page">
        {getPageNumbers().map((page, index) => (
          <div 
            key={index}
            className={`number__page ${page === currentPage ? 'current__page' : ''} ${page === '...' ? 'ellipsis' : ''}`}
            onClick={() => onSpecificPage(page)}
          >
            {page}
          </div>
        ))}
      </div>
      <div>
        <button 
          className="btn__pagination" 
          disabled={currentPage >= totalPages} 
          onClick={onNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UxPagination;