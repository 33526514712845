import React, { useState, useRef, useEffect } from 'react';
import './CustomSelect.css';

const CustomSelect = ({ 
  label, 
  options, 
  placeholder, 
  style = 'default',
  required = false,
  onChange 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <div className={`custom-select-container ${style}`} ref={dropdownRef}>
      <label className="custom-select-label">
        {label}
        {required && <span className="optional-label"><em>(Required)</em></span>}
        <span className="info-icon">?</span>
      </label>
      
      <div className="custom-select">
        <div 
          className="select-selected"
          onClick={handleToggleDropdown}
          role="button"
          tabIndex={0}
        >
          {selectedOption || placeholder}
          <span className="select-arrow"></span>
        </div>
        {isOpen && (
          <ul className="select-items">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className={selectedOption === option ? 'selected' : ''}
                role="option"
                aria-selected={selectedOption === option}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;