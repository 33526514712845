import React from 'react';
import './TitleApp.css'; 

const TitleApp = ({ children  }) => {
  return (
    <div className="title__app">
      {children}
    </div>
  );
};

export default TitleApp;
