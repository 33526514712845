import React from 'react';
import './SubTitleApp.css'; 

const SubTitleApp = ({ children  }) => {
  return (
    <div className="subTitle__app">
      {children}
    </div>
  );
};

export default SubTitleApp;
