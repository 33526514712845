import { useEffect, useState, useCallback } from "react";
import { apiRoutes, apiRequest } from "../../../services";
import DashboardAlertSkeleton from "../../../components/Skeletons/DashboardAlertSkeleton/DashboardAlertSkeleton";
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LogarithmicScale
} from 'chart.js';
import {
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalHealthCheckAlert from "../ModalHealthCheckAlert.js"
import './Dashboard.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ContainerCard from '../../../components/ContainerCard'
import {
  SEVERITY_CRITICAL,
  SEVERITY_MAJOR,
  SEVERITY_HIGH,
  ENTITY_USER,
  ENTITY_ESCALATION_POLICY,
  ENTITY_SCHEDULE,
  ENTITY_SERVICE,
  ENTITY_TEAMS,
  MONTH_JANUARY,
  MONTH_FEBRUARY,
  MONTH_MARCH,
  MONTH_APRIL,
  MONTH_MAY,
  MONTH_JUNE,
  MONTH_JULY,
  MONTH_AUGUST,
  MONTH_SEPTEMBER,
  MONTH_OCTOBER,
  MONTH_NOVEMBER,
  MONTH_DECEMBER,
} from "../../../utils/constants.js";
import TitleApp from '../../../components/TitleApp'
import MetricCard from '../../../components/MetricCard'
import SubTitleApp from "../../../components/SubTitleApp";
import HorizontalCard from "../../../components/HorizontalCard";
import ContentTabs from '../../../components/ContentTabs'
import SummaryTable from '../../../components/SummaryTable'



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  LogarithmicScale
);


export default function Dashboard({ searchedSelectedAlert, toggleCheckbox, returnIdSeverity }) {
  const [severityEntity, setSeverityEntity] = useState([]);
  const [, setEntityStatus] = useState([]);
  const [loading, setLoading] = useState({ severity: false, status: false, statusSummary: false, monthlyAlert: false, teams: false, trendMonthlyAlert: false });
  const [error, setError] = useState({ severity: false, status: false, statusSummary: false, monthlyAlert: false });
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });
  const [entityName, setEntityName] = useState([]);
  const [selectedEntityName, setSelectedEntityName] = useState('user');
  const [getMonthlyAlert, setGetMonthlyAlert] = useState([]);
  const [getTeams, setGetTeams] = useState([]);
  const [showModalHealthCheckAlert, setShowModalHealthCheckAlert] = useState(false);
  const [alertDetail, setAlertDetail] = useState({});
  const [notDataAlertsTypeSummary, setNotDataAlertsTypeSummary] = useState(false);
  const [topThreeMostCriticalAlerts, setTopThreeMostCriticalAlerts] = useState([]);
  const [dataAlertsTypeMonthlyEntityNameList, setDataAlertsTypeMonthlyEntityNameList] = useState([]);
  const [dataAlertsTypeMonthlyEntityNameSelected, setDataAlertsTypeMonthlyEntityNameSelected] = useState('');
  const [dataAlertsTypeMonthlyData, setDataAlertsTypeMonthlyData] = useState({});
  const [dataAlertsTypeMonthlyDataToRender, setDataAlertsTypeMonthlyDataToRender] = useState([]);

  const fetchData = useCallback(async (endpoint, setData, setLoadingKey, setErrorKey) => {
    setLoading(prev => ({ ...prev, [setLoadingKey]: true }));
    setError(prev => ({ ...prev, [setErrorKey]: false }));

    const [err, data] = await apiRequest({
      method: "get",
      url: endpoint,
    });

    if (data?.status_code === 200) {
      setData(data[setLoadingKey === 'severity' ? 'news_summary' : 'status_summary']);
    } else {
      setError(prev => ({ ...prev, [setErrorKey]: true }));
    }
    setLoading(prev => ({ ...prev, [setLoadingKey]: false }));
  }, []);

  useEffect(() => {
    fetchData(apiRoutes.getSeverityEntity, setSeverityEntity, 'severity', 'severity');
    // fetchData(apiRoutes.getStatusSummary, setStatusSummary, 'statusSummary', 'statusSummary');
  }, [fetchData]);

  useEffect(() => {
    getMonthly()
    getTeam()
    getTopThreeMostCriticalAlertsByEntity()
    getDataAlertsTypeMonthlyData()
    getDataAlertsTypeMonthlyData()
  }, []);


  useEffect(() => {

    if (Object.keys(getMonthlyAlert).length > 0) {
      const labels = getMonthlyAlert?.calculations.map(calc => calc.month);
      const entities = {};
      let entityName = []

      getMonthlyAlert?.calculations?.forEach(entry => {
        entry.calculation.forEach(calculation => {
          Object.keys(calculation).forEach(key => {
            if (calculation[key] === 0) {
              calculation[key] = null;
            }
          });
        });
      });

      getMonthlyAlert?.calculations?.forEach(calc => {
        calc?.calculation?.forEach(entity => {
          if (!entityName.includes(entity.entity_name)) {
            entityName.push(entity.entity_name);
          }

          if (selectedEntityName === entity.entity_name) {
            if (!entities[entity.entity_name]) {
              entities[entity.entity_name] = {
                low: [],
                minor: [],
                mid: [],
                high: [],
                major: [],
                critical: [],
              };
            }
            entities[entity.entity_name].low.push(entity.low_count);
            entities[entity.entity_name].minor.push(entity.minor_count);
            entities[entity.entity_name].mid.push(entity.mid_count);
            entities[entity.entity_name].high.push(entity.high_count);
            entities[entity.entity_name].major.push(entity.major_count);
            entities[entity.entity_name].critical.push(entity.critical_count);
          }
        })
      });

      const datasets = [];

      Object.entries(entities).forEach(([entity_name, counts]) => {
        Object.entries(counts).forEach(([severity, data]) => {
          if (data.some(value => value > 0)) {
            let backgroundColor;
            switch (severity) {
              case 'low':
                backgroundColor = '#329cad';
                break;
              case 'minor':
                backgroundColor = '#8dcfa1';
                break;
              case 'mid':
                backgroundColor = '#b37bb9';
                break;
              case 'high':
                backgroundColor = '#f7c604';
                break;
              case 'major':
                backgroundColor = '#ff872e';
                break;
              case 'critical':
                backgroundColor = '#ff0000';
                break;
              default:
                backgroundColor = '#000000';
            }

            datasets.push({
              label: `${entity_name} - ${severity.charAt(0).toUpperCase() + severity.slice(1)}`,
              data: data,
              backgroundColor: backgroundColor,
            });
          }
        });
      });

      setData({
        labels: labels,
        datasets: datasets
      });
      setEntityName(entityName)
    }


  }, [selectedEntityName, getMonthlyAlert]);


  useEffect(() => {
    dataAlertsTypeMonthlyProcessData()
  }, [dataAlertsTypeMonthlyEntityNameSelected])

  const getMonthly = async () => {
    setLoading(prev => ({ ...prev, monthlyAlert: true }));

    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getMonthly,
      });

      if (Object.keys(data).length > 0) {
        setGetMonthlyAlert(data.alerts_summary)
        setLoading(prev => ({ ...prev, monthlyAlert: false }));
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(prev => ({ ...prev, monthlyAlert: false }));
    }
  }

  const getTeam = async () => {
    setLoading(prev => ({ ...prev, teams: true }));

    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getTotalAlertType,
      });

      if (Object.keys(data).length > 0 && data?.alerts_summary?.length) {
        setGetTeams(data.alerts_summary)
        setLoading(prev => ({ ...prev, teams: false }));
      }
      if (data?.alerts_summary?.length === 0) {
        setNotDataAlertsTypeSummary(true)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(prev => ({ ...prev, teams: false }));
    }
  }


  const getTopThreeMostCriticalAlertsByEntity = async () => {
    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.alertCountBySubdomains}`,
      })
      if (!error) {
        const groupedByEntity = data.alerts_summary[0].alerts.reduce((acc, item) => {
          const key = item.entity_of_alert;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});

        const preData = Object.keys(groupedByEntity).map(key => ({
          entity_of_alert: key,
          topThreeAlerts: groupedByEntity[key]
            .sort((a, b) => b.alert_count - a.alert_count)
            .slice(0, 3),
        }));

        const result = preData.map(r => {

          const dataScore = r.topThreeAlerts.map(item => {
            let score = 0
            const criticalAlert = item.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_CRITICAL)
            const criticalMajor = item.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_MAJOR)
            const criticalHigh = item.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_HIGH)

            if (criticalAlert.length > 0) {
              criticalAlert.forEach(alert => {
                if (alert.alert_count > score) {
                  score = alert.alert_count
                }
              });
            } else if (criticalMajor.length > 0) {
              criticalMajor.forEach(alert => {
                if (alert.alert_count > score) {
                  score = alert.alert_count
                }
              });
            } else if (criticalHigh.length > 0) {
              criticalHigh.forEach(alert => {
                if (alert.alert_count > score) {
                  score = alert.alert_count
                }
              });
            }

            return {
              ...item,
              score
            }
          })

          return {
            entity: r.entity_of_alert,
            data: dataScore,
          }
        })

        setTopThreeMostCriticalAlerts(result);
      }

    } catch (error) {
      console.log("ERROR.getTopThreeMostCriticalAlertsByEntity", error);
      setTopThreeMostCriticalAlerts([]);
    }
  }

  const getDataAlertsTypeMonthlyData = async () => {
    setLoading(prev => ({ ...prev, ["trendMonthlyAlert"]: true }));
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.alertTypeMonthly}`,
    })

    if (!error) {
      const groupedData = data.reduce((acc, item) => {
        const existingEntity = acc.find(entry => entry.entity === item.entity && entry.month === item.month);
        if (existingEntity) {
          existingEntity.items.push(item);
        } else {
          acc.push({
            entity: item.entity,
            month: item.month,
            items: [item]
          });
        }
        return acc;
      }, []);

      setDataAlertsTypeMonthlyEntityNameList([
        ENTITY_USER,
        ENTITY_SCHEDULE,
        ENTITY_ESCALATION_POLICY,
        ENTITY_SERVICE,
        ENTITY_TEAMS
      ]);
      setDataAlertsTypeMonthlyData(groupedData)
      setDataAlertsTypeMonthlyEntityNameSelected(ENTITY_USER)
    }
    setLoading(prev => ({ ...prev, ["trendMonthlyAlert"]: false }));
  }

  const dataAlertsTypeMonthlyGetDataByEntityAndMonth = (data, entity) => {
    const monthList = [
      MONTH_JANUARY,
      MONTH_FEBRUARY,
      MONTH_MARCH,
      MONTH_APRIL,
      MONTH_MAY,
      MONTH_JUNE,
      MONTH_JULY,
      MONTH_AUGUST,
      MONTH_SEPTEMBER,
      MONTH_OCTOBER,
      MONTH_NOVEMBER,
      MONTH_DECEMBER
    ]
    const labels = data?.filter(r => r.entity === entity).map(item => item.month)
    const labelsOrdered = monthList.filter(month => labels.includes(month))
    const count = item => {
      return {
        x: item.month,
        y: item.items.reduce((sum, item) => sum + item.count, 0),
        additionalInfo: item
      }
    }
    const january = data?.filter(r => r.entity === entity && r.month === MONTH_JANUARY).map(count)
    const february = data?.filter(r => r.entity === entity && r.month === MONTH_FEBRUARY).map(count)
    const march = data?.filter(r => r.entity === entity && r.month === MONTH_MARCH).map(count)
    const april = data?.filter(r => r.entity === entity && r.month === MONTH_APRIL).map(count)
    const may = data?.filter(r => r.entity === entity && r.month === MONTH_MAY).map(count)
    const june = data?.filter(r => r.entity === entity && r.month === MONTH_JUNE).map(count)
    const july = data?.filter(r => r.entity === entity && r.month === MONTH_JULY).map(count)
    const august = data?.filter(r => r.entity === entity && r.month === MONTH_AUGUST).map(count)
    const september = data?.filter(r => r.entity === entity && r.month === MONTH_SEPTEMBER).map(count)
    const october = data?.filter(r => r.entity === entity && r.month === MONTH_OCTOBER).map(count)
    const november = data?.filter(r => r.entity === entity && r.month === MONTH_NOVEMBER).map(count)
    const december = data?.filter(r => r.entity === entity && r.month === MONTH_DECEMBER).map(count)
    const monthWithData = [january, february, march, april, may, june, july, august, september, october, november, december]
      .filter(r => r.length > 0)
      .flat()
      .map(r => {
        return {
          y: r.y,
          x: r.x,
          additionalInfo: r.additionalInfo
        }
      })

    return {
      labels: labelsOrdered,
      data: monthWithData
    }
  }

  const dataAlertsTypeMonthlyProcessData = () => {
    if (!dataAlertsTypeMonthlyData) return;
    const color = 'rgb(5, 172, 56)'
    const dataset = {
      fill: false,
      borderColor: color,
      pointRadius: 3,
      pointHoverRadius: 7,
      pointBackgroundColor: color,
      pointHoverBackgroundColor: color
    }
    let data, preData

    switch (dataAlertsTypeMonthlyEntityNameSelected) {
      case ENTITY_USER:
        preData = dataAlertsTypeMonthlyGetDataByEntityAndMonth(dataAlertsTypeMonthlyData, ENTITY_USER)
        data = {
          labels: preData.labels,
          datasets: [{
            ...dataset,
            label: 'Users',
            data: preData.data
          }]
        }
        break;
      case ENTITY_ESCALATION_POLICY:
        preData = dataAlertsTypeMonthlyGetDataByEntityAndMonth(dataAlertsTypeMonthlyData, ENTITY_ESCALATION_POLICY)
        data = {
          labels: preData.labels,
          datasets: [{
            ...dataset,
            label: 'Escalation Policy',
            data: preData.data
          }]
        }
        break;
      case ENTITY_SCHEDULE:
        preData = dataAlertsTypeMonthlyGetDataByEntityAndMonth(dataAlertsTypeMonthlyData, ENTITY_SCHEDULE)
        data = {
          labels: preData.labels,
          datasets: [{
            ...dataset,
            label: 'Schedule',
            data: preData.data
          }]
        }
        break;
      case ENTITY_SERVICE:
        preData = dataAlertsTypeMonthlyGetDataByEntityAndMonth(dataAlertsTypeMonthlyData, ENTITY_SERVICE)
        data = {
          labels: preData.labels,
          datasets: [{
            ...dataset,
            label: 'Service',
            data: preData.data
          }]
        }
        break;
      case ENTITY_TEAMS:
        preData = dataAlertsTypeMonthlyGetDataByEntityAndMonth(dataAlertsTypeMonthlyData, ENTITY_TEAMS)
        data = {
          labels: preData.labels,
          datasets: [{
            ...dataset,
            label: 'Teams',
            data: preData.data
          }]
        }
        break;
    }
    setDataAlertsTypeMonthlyDataToRender(data)
  }

  const renderTable = (data, headers, loadingKey, errorKey, title) => {
    const dataBody = data.map((category) => ({
      objectType: category.pd_entities_labels, 
      severityLevels: headers.map((value) => {
        const severity = category[value];
        return severity !== null ? severity : undefined; 
      }).filter(level => level !== undefined), 
    }));


    return(
      <div >
      {error[errorKey] &&
        <div className="container__dataNotFound">
          <div>Oops, looks like we couldn't find the information. Please try again later.</div>
          <button className="button__try" onClick={() => fetchData(apiRoutes[errorKey === 'severity' ? 'getSeverityEntity' : 'getEntityStatus'], errorKey === 'severity' ? setSeverityEntity : setEntityStatus, loadingKey, errorKey)}>Try Again</button>
        </div>
      }
      {!loading[loadingKey] && <TitleApp>{title}</TitleApp>}
      {data.length === 0 && !loading[loadingKey] &&
        <div style={{ marginTop: "40px" }} className="container__dataNotFound notfound__premiumServiceRequest">
          <div>Oops, it looks like we weren’t able to find your information. We kindly request you to try Again later.</div>
          <div className="container__buttonTry"><button className="button__try" onClick={() => loadingKey === 'severity' ? fetchData(apiRoutes.getSeverityEntity, setSeverityEntity, 'severity', 'severity') : fetchData(apiRoutes.getEntityStatus, setEntityStatus, 'status', 'status')}>Try Again</button></div>
        </div>}
      {loading[loadingKey] && <DashboardAlertSkeleton />}
      {!loading[loadingKey] && data.length > 0 &&
        <div>
          <SummaryTable data={dataBody} headers={headers} />
        </div>
      }
      </div>
    )
  };

  const backgroundStatus = (status) => {
    const severityMapping = {
      6: { 'backgroundStatus': '#FFE7E7', 'color': '#f00' },
      5: { 'backgroundStatus': '#ff872e', 'color': '#fff' },
      4: { 'backgroundStatus': '#f7c604', 'color': '#fff' },
      3: { 'backgroundStatus': '#690375', 'color': '#fff' },
      2: { 'backgroundStatus': '#329cad', 'color': '#fff' },
      12: { 'backgroundStatus': '#cecece', 'color': '#808080' },
    };
    return severityMapping[status] || '';
  }

  const showTextSeverity = (severity) => {
    const severityMapping = {
      6: 'Critical',
      5: 'Major',
      4: 'High',
      3: 'Medium',
      2: 'Low',
      12: 'Minor',
    };

    return severityMapping[severity];
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const onClickInfoCircle = (alert) => {
    setAlertDetail({})
    setShowModalHealthCheckAlert(true)
    setAlertDetail(alert)
  }

  const options = {
    indexAxis: 'x',
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true, 
          pointStyle: 'circle', 
        },
      },
    },
    barThickness: 40,
    barSpacing: 8,
  };

  const optionsLine = {
    responsive: true,
    parsing: {
      xAxisKey: 'x',
      yAxisKey: 'y'
    },
    plugins: {
      datalabels: { display: false },
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            let newLabel = []
            let total = 0
            if (context.raw.additionalInfo) {
              newLabel = context.raw.additionalInfo.items.map(item => {
                total += item.count
                return `${item.field_commercial_name}: ${item.count}`
              })
            }
            return [...newLabel, `Total: ${total}`];
          }
        }
      }
    }
  }

  let isDataAlertsTypeMonthlyDataToRender = "ERROR_DATA"

  if (dataAlertsTypeMonthlyDataToRender &&
    dataAlertsTypeMonthlyDataToRender.datasets &&
    dataAlertsTypeMonthlyDataToRender.datasets[0].data.length > 0 &&
    dataAlertsTypeMonthlyDataToRender.datasets[0].data.length === 1) {
    isDataAlertsTypeMonthlyDataToRender = "NO_DATA"
  } else if (dataAlertsTypeMonthlyDataToRender &&
    dataAlertsTypeMonthlyDataToRender.datasets &&
    dataAlertsTypeMonthlyDataToRender.datasets[0].data.length > 1) {
    isDataAlertsTypeMonthlyDataToRender = "OK_DATA"
  }

  const entityNameMap = entityName.map(entity => {return { 'name':capitalizeFirstLetter(entity === 'escalation_policy' ? 'escalation Policy' : entity), 'id': entity }})
  const dataAlertsTypeMonthlyEntityNameListMap  = dataAlertsTypeMonthlyEntityNameList.map(entity => {return { 'name':capitalizeFirstLetter(entity === 'escalation_policy' ? 'escalation Policy' : entity), 'id': entity }})

  return (
    <ContainerCard>
      {topThreeMostCriticalAlerts && topThreeMostCriticalAlerts.length > 0 &&
        <div >
          <TitleApp>Top 3 Most Critical Alerts </TitleApp>
          <div className="top-three-most-critical-alerts-container">
            {topThreeMostCriticalAlerts.map((item, index) => (
              <div key={index} className="container__cardTopThreeMost">
                <SubTitleApp>{item.entity}</SubTitleApp>
                {item.data.length === 0 || item.data.every(r => r.score === 0) && <div className="top-three-most-critical-alerts-row">
                  <div className="top-three-most-critical-alerts-score">Congrats, you don't have any Critical/Major Alerts for {item.entity}</div>
                </div>}
                <div className="card__topThreeMost">
                  {item.data.filter(r => r.score > 0).sort((a, b) => b.score - a.score).map((alert, alertIndex) => (
                    <MetricCard
                    title={alert?.alert_name}
                    value={alert?.score}
                    onClick={() =>  searchedSelectedAlert(alert, true)}
                    text={"ALERTS"}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      }
      
      <div className="alertsSummary__container">
        {loading.teams && <DashboardAlertSkeleton />}
        {!loading.teams && !notDataAlertsTypeSummary && <TitleApp>Alerts Type Summary</TitleApp>}
        {!loading.teams && !notDataAlertsTypeSummary &&
          <div className="container__cardTypeAlert">
            {getTeams?.map(item => (
              item?.count > 0 && 
              <HorizontalCard borderColor={"#117170"}> 
                <div className="container__alertsTypeSummary">
                    <div onClick={() => onClickInfoCircle(item)} className="icon__infoMyAlert button__infoCircleTypeAlert" ><FontAwesomeIcon icon={faInfoCircle} /></div>
                    <div className="card__typeAlert">
                      <div onClick={() => searchedSelectedAlert(item.alert)}>{item.alert}</div>
                      <div className="container__countTotalAlertsSummary">
                        <div className="count__totalAlertsSummary">{item.count}</div>
                        <div className="name__alerts">ALERTS</div>
                      </div>
                    </div>
                    <div className="container__severityDashboard">
                        {item?.alerts_critical?.map(severity => (
                          <div class="meta__home" style={{ marginTop: 'auto', marginBottom: '0px' }}>
                            <div
                              onClick={() => {
                                searchedSelectedAlert(item.alert)
                                toggleCheckbox(returnIdSeverity(severity.impact))
                              }
                              }
                              class="urgency__dashboard" style={{ backgroundColor: backgroundStatus(severity.severity).backgroundStatus, color: backgroundStatus(severity.severity).color, textAlign: 'center' }}>
                              <div >{showTextSeverity(severity.severity)}</div>
                              <div>{severity?.count}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                </div>
              </HorizontalCard>
            ))}
          </div>}
      </div>
      <div className="alertsSummary__container">
          {!loading.trendMonthlyAlert && <TitleApp>Trend Monthly</TitleApp>}
          {loading.trendMonthlyAlert && <div ><DashboardAlertSkeleton /></div>}
          {!loading.trendMonthlyAlert && 
          <div className="container__chartDashboard" >
            <div style={{width: '100%',  marginTop: '10px' }}>
              <ContentTabs
                    tabs={dataAlertsTypeMonthlyEntityNameListMap}
                    activeTab={dataAlertsTypeMonthlyEntityNameSelected}
                    onTabClick={setDataAlertsTypeMonthlyEntityNameSelected}
                />
              {(isDataAlertsTypeMonthlyDataToRender === 'NO_DATA' || isDataAlertsTypeMonthlyDataToRender === 'ERROR_DATA') &&
                <div className="centered-message">Unfortunately, we do not have enough data to display the graph.</div>}
              {isDataAlertsTypeMonthlyDataToRender === 'OK_DATA' &&
                <div className="container__barMonthlyHealthCheck">
                  <div className="line__nameSelected">{capitalizeFirstLetter(dataAlertsTypeMonthlyEntityNameSelected === 'escalation_policy' ? 'escalation Policy' : dataAlertsTypeMonthlyEntityNameSelected)}</div>
                  <Line height={'100px'} data={dataAlertsTypeMonthlyDataToRender} options={optionsLine} />
                </div>}
            </div>
          </div>}
      </div>
      <div className="alertsSummary__container">
        {loading?.monthlyAlert && <div ><DashboardAlertSkeleton /></div>}
        {!loading?.monthlyAlert && <TitleApp>Monthly Health Check Alert Trends</TitleApp>}
        {getMonthlyAlert?.calculations?.length === 0 && !loading?.monthlyAlert &&
          <div style={{ marginTop: "40px" }} className="container__dataNotFound notfound__premiumServiceRequest">
            <div>Oops, it looks like we weren’t able to find your information. We kindly request you to try Again later.</div>
            <div><button className="button__try" onClick={() => getMonthly()}>Try Again</button></div>
          </div>}
          {!loading?.monthlyAlert && 
          <div className="container__chartDashboard" >
            <div style={{ width: '100%', marginTop: '10px' }} >
              <div>
                <ContentTabs
                  tabs={entityNameMap}
                  activeTab={selectedEntityName}
                  onTabClick={setSelectedEntityName}
                />
              </div>
              <div className="container__barMonthlyHealthCheck"><Bar data={data} options={options} height={"110px"} /></div>
            </div>
        </div>}
      </div>
      <div className="alertsSummary__container">
        {renderTable(severityEntity, ['', 'Low', 'Minor', 'Medium', 'High', 'Major', 'Critical'], 'severity', 'severity', 'Object Type vs Severity Summary')}
      </div>
      {showModalHealthCheckAlert && <ModalHealthCheckAlert
        onePage={alertDetail}
        onShowModalHealthCheckAlert={setShowModalHealthCheckAlert}
        showGWP={false}
      />}
    </ContainerCard>
  );
}
