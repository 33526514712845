import {
  faSitemap,
  faGears,
  faPeopleGroup,
  faUser,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getDescendingColors = () => [
  '#9C0101',
  '#FF3A38',
  '#FF4B47',
  '#FF5F58',
  '#FF7066',
  '#FF8377',
  '#FF9485',
  '#FFA896',
  '#FFB8A4',
  '#FFCCB5'
];

export const getAscendingColors = () => [
  '#0CA401',
  '#24AD01',
  '#37B401',
  '#5BC201',
  '#70CA01',
  '#72CB01',
  '#87D301',
  '#9CDA01',
  '#AFE201',
  '#E3F301'
];

export const colorBySeverity = (severity) => {
  const severityMapping = {
    1: {'color': '#CD0000' },
    13: {'color': '#E87000' },
    11: {'color': '#F7C000' },
    4: {'color': '#C000F0' },
    2: {'color': '#007DF0' },
    12: {'color': '#117170' },
  };
  return severityMapping[severity] || '';
}

export const backgroundOmm = (omm) => {
  const severityMapping = {
    'Automation': { 'background': '#adf07e', 'color': '#000000' },
    "People Management": { 'background': '#EEB900', 'color': '#000000' },
    "Noise Reduction": { 'background': '#FFE2B1', 'color': '#000000' },
    "Integrations": { 'background': '#146BD5', 'color': '#FAFAFA' },
    "Collaboration": { 'background': '#D1D4DE', 'color': '#000000' },
    "Learning": { 'background': '#9A4A00', 'color': '#FAFAFA' },
  };
  return severityMapping[omm] ||  { 'background': '#adf07e', 'color': '#000000' };
}

export const showTextSeverity = (severity) => {
  const severityMapping = {
    1: 'Critical',
    13: 'Major',
    11: 'High',
    4: 'Medium',
    2: 'Low',
    12: 'Minor',
  };
  return severityMapping[severity] || '';
}


export const returnIcon = (entity) => {
  const icon = {
    'team': faPeopleGroup,
    'service': faGears,
    'escalation_policy': faSitemap,
    'user': faUser,
    'schedule': faCalendarDays,
    'schedules': faCalendarDays,
    'teams': faPeopleGroup,
    'users': faUser,
    'escalation policy': faSitemap,
  };

  return icon[entity.toLowerCase()] || faCalendarDays;
}