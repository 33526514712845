import styled from "styled-components";

export const Contenedor = styled.div`
  display: flex;
  width: 100%;
  color: #252525;
`;

export const ContenedorCarousel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContenedorApps = styled.div`
  width: 100%;
`;

export const ContenedorItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 120px;
  justify-content: center;
  align-items: center;
  row-gap: 14px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #1a73e8;
  }
`;

export const ContenedorItems = styled.div`
  display: flex;
  justify-content: space-evenly;
  column-gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

export const CarouselButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 17px;
  padding: 0;
  &:disabled{
    background-color: transparent !important;
    cursor: not-allowed;
  }
`;

export const CarouselIcon = styled.div`
  font-size: 30px;
`;

export const BarChartContainer = styled.div`
  margin: 0px 0px;
`;