import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Banner.css';
import { bannerSubject } from './../../services/subjects';

const Banner = () => {
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    const subscription = bannerSubject.subscribe((data) => {
      setBannerData(data);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!bannerData) return null;

  return (
    <div className={`banner banner-${bannerData.type}`}>
      <div className="banner-content">
        {bannerData.icon && <FontAwesomeIcon icon={bannerData.icon} className="maintenance-banner-icon" />}
        <span className="banner-message">{bannerData.message}</span>
      </div>
    </div>
  );
};

export default Banner;