import { SUPPORTED_RESOURCES } from "../constants"
import { ResourcePicker } from "./ResourcePicker"

/** @param {Object} props
 * @param {import("../index").TFerReducerState} props.tferState
 * @param {import("../index").TFerDispatchSetPlan} props.setPlan
 */
export const ResourcesPicker = ({ tferState, setPlan }) => {
  return (
    <div>
      <div className="tfer-app__resources-picker">
        {
          SUPPORTED_RESOURCES.map(resourceType => <ResourcePicker key={resourceType} resourceType={resourceType} resources={tferState.resources[resourceType]} setPlan={setPlan} />)
        }
      </div>
    </div>
  );
};
