import React, { useEffect } from "react";
import styles from "./PagerDutyReconnect.module.css";
import logo from "../../../../assets/PagerDuty-WhiteRGB_white.svg";
import checkmark from "../../../../assets/checkmark.svg";
import PainPointCard from "./components/PainPointCard/PainPointCard";
import StartIcon from "../../../../assets/StartIcon.svg";
import UniversityIcon from "../../../../assets/UniversityIcon.svg";
import ServiceIcon from "../../../../assets/ServiceIcon.svg";
import ScriptingIcon from "../../../../assets/ScriptingIcon.svg";
import BellRegular from "../../../../assets/BellRegular.svg";
import SolutionCard from "./components/SolutionCard/SolutionCard";
import { apiRequest, apiRoutes } from "../../../../services";
import { useState } from "react";
import Jwt from "../../../../utils/jwt";
import 'bootstrap/dist/js/bootstrap.min.js'
import { freeTrialButton } from "../../../../services/subjects";

const solutions = [
  {
    iconSrc: StartIcon,
    title: "Professional Services",
    description: "Optimizations, Migrations, Automation Services",
  },
  {
    iconSrc: UniversityIcon,
    title: "PagerDuty University",
    description: "Training & Certifications",
  },
  {
    iconSrc: ServiceIcon,
    title: "Service Request Accelerators",
    description: "Bundle of Service Request Hours",
  },
  {
    iconSrc: ScriptingIcon,
    title: "Custom Solutions, Integrations & Reporting",
    description: "and more...",
  },
];

const painPoints = [
  {
    title: "Reduce Alert & Incident Noise",
    description:
      "Use our latest AIOps and Automation features to focus on what’s most critical",
  },
  {
    title: "Improve Operational Maturity",
    description: "Enhance Collaboration, On-Call Readiness, Noise Reduction and Automation across Teams",
  },
  {
    title: "Automate Incident Management",
    description:
      "Modernize Enterprise Incident Management with automation, communications and continuous improvement",
  },
  {
    title: "Adopt Latest Features",
    description:
      "Migrate from legacy features and accelerate operational transformation",
  },
];

const daysRemaining = (targetDate) => {
  const today = new Date();
  const targetTime = new Date(targetDate).getTime();
  const currentTime = today.getTime();
  const timeDifference = targetTime - currentTime;
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  
  return daysDifference;
}
const ButtonActionRed = () => {
  const scrollToSection = () => {
    const section = document.getElementById('claimNow');
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};
  return (
    <button onClick={scrollToSection} className={`${styles.dangerActionButton} ${styles.centered} dangerActionButton btn btn-danger`} >  

      <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25 12 21m0 0-3.75-3.75M12 21V3" />
      </svg>

    <span>
      Get your <br />
        Free Health Check
    </span>
    </button>
    );
  };
  

function PagerDutyReconnect({onClaim, onStartTour}) {
const [showClaimButton, setShowClaimButton] = useState(true);
const [trialEndDate, setTrialEndDate] = useState(0);


useEffect(() => {
freeTrialButton.subscribe((data) => {
  setShowClaimButton(data);
});
}, []);

useEffect(() => {
  const tokenData = Jwt.getItem("all");
  if (tokenData) {
  setTrialEndDate(tokenData?.claims?.trial_end_date);
  }
}, []);

  useEffect(() => {
    const getTrialStatus = async () => {
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.freeTrial}`,
      });
      setShowClaimButton(!(data?.healthcheck_requests?.length > 0));
    };
    getTrialStatus();
  }, []);

  return (
    <div className={styles.reconnectContainer}>
      <div className={styles.headerReconnect+ ' mb-4'}>
        <div className={styles.headerBackground}>
          <img
            src="https://images.innovation.csg.pagerduty.com/ReconnectBackground1.png"
            alt=""
            className={styles.backgroundImage}
          />
        </div>
        <div className={styles.headerContent}>
          <div className={styles.headerText}>
            <img src={logo} alt="PagerDuty" className={styles.textLogo} />
            <h1 className={styles.greeting}>
              Hi there, it's been a long time...
            </h1>
            <p className={styles.subGreeting}>
              <strong>You Changed, We Evolved</strong>
              <br />
              Thank you for the opportunity to be of service to you in past Professional Services engagements.
            </p>
            <p className={`${styles.subGreeting } ${styles.MarginParragraph }`}>
              Many things have changed over the past few years, discover the new era of PagerDuty Professional Services.
            </p>
          </div>
          <img
            src="https://images.innovation.csg.pagerduty.com/ReconnectWomen.png"
            alt="PagerDuty interface"
            className={styles.headerImage}
          />
        </div>
      </div>
      <main className={styles.mainContent}>
     
      {showClaimButton && <div className={`${styles.stickyButton}`}>
        <ButtonActionRed />
      </div>}

        <section className={styles.mainSection}>
          <div className={styles.contentWrapper}>
            <img
              src="https://images.innovation.csg.pagerduty.com/Group+342+(1).png"
              alt="PagerDuty dashboard"
              className={styles.dashboardImage}
            />
            <div className={styles.textContent}>
            <span className={styles.solutionsSubtitle}>
            REDISCOVER PAGERDUTY PROFESSIONAL SERVICES
            </span>
              <h2 className={styles.sectionTitle}>
              Reconnect with us and accelerate your Business and Technology Transformation
              </h2>
              <p className={styles.sectionDescription+' '+styles.titleDescription}>
              Over the past decade, your business and operational needs have grown, and new challenges and opportunities have emerged. Has your use of the PagerDuty Operations Cloud kept up?
              </p>
              <div className={`${styles.pdAccordion } accordion`} id="rediscoverAccordion">
                <div className={`${styles.accordionBox }  accordion-item`}>
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    What our data indicates about our customers
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#rediscoverAccordion">
                    <div className={`${styles.accordionContent } accordion-body`}>
                    Our data shows that customers without a regular Professional Services relationship are slow to realize the expected business outcomes and value available today when using the latest features and best practices.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureBox}>
                <img src={checkmark} alt="" className={styles.featureIcon} />
                <p className={styles.featureText}>We recognize your business and operational needs and are ready to support you globally</p>
              </div>
              <div className={styles.featureBox}>
                <img src={checkmark} alt="" className={styles.featureIcon} />
                <p className={styles.featureText}>
                We are ready to help ensure you are prepared for the next unexpected business impacting outage and approaching peak business and holiday seasons
                </p>
              </div>
              <div className={styles.featureBox}>
                <img src={checkmark} alt="" className={styles.featureIcon} />
                <p className={styles.featureText}>
                We have 100s of new and innovative Professional Services Solutions available today for your business and operations teams
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.painPointsSection}>
          <div className={styles.containerPP}>
            <div className={styles.contentPP}>
              <div className={styles.textContentPP}>
                <div className={styles.headerPP}>
                  <span className={styles.solutionsSubtitle}>NEW FOCUS</span>
                  <h2 className={styles.titlePP}>
                  We can improve your business and operational challenges and pain points
                  </h2>
                </div>
                <p className={styles.descriptionPP+' '+styles.titleDescription}>
                Whether you’re facing extended business impacts from the latest IT outage or growing pains from your cloud migration or digital transformation strategy, we can help you in these ways:
                </p>
                <div className={styles.cardGridPP}>
                  {painPoints.map((point, index) => (
                    <PainPointCard
                      key={index}
                      title={point.title}
                      description={point.description}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.imageWrapperPP}>
                <img
                  src="https://images.innovation.csg.pagerduty.com/Group+34.png"
                  alt="Illustration of pain points solutions"
                  className={styles.imagePP}
                />
              </div>
            </div>
          </div>
        </section>
        <section className={styles.solutionsSection}>
          <span className={styles.solutionsSubtitle}>NEW SOLUTIONS</span>
          <h2 className={styles.solutionsTitle}>
            <span className={styles.solutionsTitle2}>
              {" "}
              Don't know where to start?
            </span>

            <br />
            <span className={styles.solutionsTitle3}>
              Visit our new Solutions Gallery
            </span>
          </h2>
          <div className={styles.solutionsDescriptionContainer}>
            <p className={styles.solutionsDescription+' '+styles.titleDescription}>
            With this limited Premium Customer Portal Experience, you will have access to explore our Solutions Gallery, your one stop to explore our latest offerings, solutions, education and accelerators available today.
            </p>
          </div>
          <div className="row">
            <div className={`${styles.solutionsGrid} col-10 mx-auto`}>
              {solutions.map((solution, index) => (
                <SolutionCard key={index} {...solution} />
              ))}
            </div>
          </div>
          <img
            src="https://images.innovation.csg.pagerduty.com/Vector.png"
            alt="PagerDuty dashboard"
            role="display"
            className={styles.solutionsBackground}
          />
          <button className={`${styles.startTourButton} ${styles.secondaryButton} mx-auto ${styles.heartbeatAnimation}`} onClick={() => onStartTour()}>Start Tour</button>
        </section>
        <section className={styles.healthCheckSection}>
          <div className={styles.healthCheckContent}>
          <div className={styles.healthCheckText}>
              <span className={styles.newSolutionsLabel}>NEW OFFERINGS</span>
              <h2 className={styles.healthCheckTitle}>Operational Maturity & Configuration Health Check</h2>
              <p className={`${styles.healthCheckDescription} ${ styles.titleDescription } ${styles.MBNone}`}>
              Keeping your PagerDuty environment well configured and healthy is vital for operational maturity and success.
              </p>
              <p className={`${styles.healthCheckDescription} ${ styles.titleDescription } ${styles.MBNone}`}>
                Without regular check-ups included in our Premium Services offerings, customers often see their operational maturity and configuration health decline leading to increased customer and business impact from incidents.
              </p>
              <p className={`${styles.healthCheckDescription} ${ styles.titleDescription }`}>
                  Claim your free Health Check today (a $10,000 value)!
              </p>
              <div className={`${styles.pdAccordion } accordion`} id="newOfferingsAccordion">
                <div className={`${styles.accordionBox }  accordion-item`}>
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    What our data indicates about our customers
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#newOfferingsAccordion">
                    <div className={`${styles.accordionContent } accordion-body`}>
                      <p>
                        Customers without a regular Professional Services relationship often have 100s-1000s of Health Check Alerts. During your Premium Customer Portal Experience, use the Health Check App to review the Health Check Dashboard, generate reports, and export to CSV to create your Get Well Plans today!
                      </p>
                      <p className={styles.MarginParragraph}>
                      Our Professional Service team will be using/creating a read-only API Key in your instance to extract data or for the Health Check purpose.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.imageContainer}>
              {/*<img
                src="https://images.innovation.csg.pagerduty.com/imagen-frame+(2).png"
                alt=""
                className={styles.backgroundImage2}
              />*/}
              <div className={styles.featureBox}>
                <img src={checkmark} alt="" className={styles.featureIcon} />
                <p className={styles.featureText}>Full PagerDuty Configuration Hygiene & Operational Usage Assessment</p>
              </div>
              <div className={styles.featureBox}>
                <img src={checkmark} alt="" className={styles.featureIcon} />
                <p className={styles.featureText}>Actionable Health Check Alerts with Get Well Plans to improve Operational Maturity and Configurations</p>
              </div>
              <div className={styles.featureBox}>
                <img src={checkmark} alt="" className={styles.featureIcon} />
                <p className={styles.featureText}>Explore Service Request Accelerators to see how Professional Services can help today</p>
              </div>
            </div>
          </div>
          {showClaimButton && <button id="claimNow" className={`${styles.claimNowButton} ${styles.primaryButton} ${styles.heartbeatAnimation}`} onClick={() => onClaim()}>Claim Now</button>}
        </section>
      </main>
      <div className={styles.demoCountdownContainer}>
        <div className={styles.demoCountdown}>
          <div className={styles.countdownContent}>
            <img
              src={BellRegular}
              alt=""
              role="display"
              className={styles.countdownIcon}
            />
            <p className={styles.countdownText}>
              You have <strong className={styles.daysLeft}>{trialEndDate ? daysRemaining(trialEndDate) : 0} days</strong>{" "}
              until your limited Premium Customer Portal Experience ends.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PagerDutyReconnect;
