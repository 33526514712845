import React, { useState, useEffect } from 'react';
import './SupportModal.css';

const SupportModal = ({ subdomain, email, onFormDataChange, initialFormData }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [isValid, setIsValid] = useState(false);
  
  const isValidField = (field) => typeof field === 'string' && field.trim() !== '';

  const requiredFields = ['email', 'subject', 'details'];
  const allFields = [...requiredFields, 'subdomain'];
  
  const valid = requiredFields.every(field => isValidField(formData[field]));
  const subDomainExists = allFields.every(field => isValidField(formData[field]));

  const validateForm = () => { 
    if( subdomain !== undefined || subdomain !== null) {
      setIsValid(valid);
    } else {
      setIsValid(subDomainExists);
    }
  };
  
  useEffect(() => {
    validateForm();
    onFormDataChange(formData);
  }, [formData, onFormDataChange]);

  useEffect(() => {
    const newFormData = {
      email,
      subject: formData.subject || '',
      details: formData.details || ''
    };
  
    if (subdomain != null) {
      newFormData.subdomain = subdomain;
    }
  
    setFormData(newFormData);
  }, [subdomain, email, formData.subject, formData.details]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <section className='premium-support-request__modal-main'>
      <section className='premium-support-request__modal-form'>
        <section className="premium-support-request__modal-col2">
          <div className="premium-support-request__modal-group">
            <label htmlFor="subdomain" className="premium-support-request__modal-label">Subdomain</label>
            <input
              id="subdomain"
              name="subdomain"
              className="premium-support-request__modal-input"
              type="text"
              value={subdomain || ''}
              disabled
            />
          </div>
          <div className="premium-support-request__modal-group">
            <label htmlFor="email" className="premium-support-request__modal-label">Email</label>
            <input
              id="email"
              name="email"
              className="premium-support-request__modal-input"
              type="email"
              value={email || ''}
              disabled
            />
          </div>
        </section>
        <section className="premium-support-request__modal-col1">
          <div className="premium-support-request__modal-group">
            <label htmlFor="subject" className="premium-support-request__modal-label">Request Subject <span className='fw-light'><em>(Required)</em></span></label>
            <input
              id="subject"
              name="subject"
              className="premium-support-request__modal-input"
              type="text"
              value={formData.subject || ''}
              onChange={handleInputChange}
            />
          </div>
        </section>
        <section className="premium-support-request__modal-col1">
          <div className="premium-support-request__modal-group">
            <label htmlFor="details" className="premium-support-request__modal-label">Request Details <span className='fw-light'><em>(Required)</em></span></label>
            <textarea
              id="details"
              name="details"
              className="premium-support-request__modal-textarea"
              rows={10}
              value={formData.details || ''}
              onChange={handleInputChange}
            />
          </div>
        </section> 
      </section>
    </section>
  );
};

export default SupportModal;