import { Subject, BehaviorSubject } from 'rxjs';

export const notificationSubject = new Subject();
export const sessionModal = new Subject();
export const openMenuSubject = new Subject();
export const talkingPageySubject = new Subject();
export const feedbackSubject = new Subject();
export const cartSolution = new Subject();
export const cartAlert = new Subject();
export const showCartAlert = new Subject();
export const controlTour = new Subject();
export const reconnectModal = new Subject();
export const resetTourSteps = new Subject();
export const freeTrialButton = new Subject();
export const configurationSubject = new Subject();
export const bannerSubject = new Subject();


const initialStepsState = {
  step1: { isLoaded: false },
};

export const stepsState = new BehaviorSubject(initialStepsState);
