import React from 'react';
import './ HorizontalCard.css'; 

const HorizontalCard = ({ className, children, borderColor }) => {
  return (
    <div className={`horizontal__card${className ? ` ${className}` : ''}`} style={{ borderLeft: `3px solid ${borderColor}`}}>
      {children}
    </div>
  );
};

export default  HorizontalCard;
