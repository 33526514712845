import * as apiRoutesAlias from "./api-routes";
import { apiRequest, API_BASE_URL } from "./api-instance";

let apiRoutes = {};

if (API_BASE_URL && API_BASE_URL.includes('127.0.0')) {
    const updatedUrls = Object.keys(apiRoutesAlias.default).reduce((acc, key) => {
        acc[key] = apiRoutesAlias.default[key].replace(/ms-home\//g, '');
        return acc;
    }, {});
    apiRoutes = updatedUrls;
} else {
    apiRoutes = apiRoutesAlias.default;
}

export { apiRoutes, apiRequest, API_BASE_URL };