// export const pageTitles = {
//     '/': 'Home',
//     '/login': 'Login',
//     '/logout': 'Logout',
//     '/unauthorized': 'Unauthorized',
//     '/validate': 'Validate',
//     '/select-client': 'Select Client',
//     '/validate-oauth': 'Validate OAuth',
//     '/faq': 'FAQ',
//     '/profile': 'Profile',
//     '/users': 'Users',
//     '/health-check-client': 'Health Check Client',
//     '/health-check': 'Health Check',
//     '/health-check-data-viewer': 'Health Check Data Viewer',
//     '/success-on-demand': 'Success On Demand',
//     '/add-user': 'Add User',
//     '/edit-user': 'Edit User',
//     '/onboarding': 'Onboarding',
//     '/ps-status': 'PS Status',
//     '/pdu-app': 'PDU App',
//     '/solution-gallery': 'Solution Gallery',
//     '/my-alerts-app': 'My Alerts App',
//     '/authorization': 'Authorization',
//     '/deck-generator': 'Deck Generator',
//     '/customer-snapshots': 'Customer Snapshots',
//     '/solution-sub-category': 'Solution Sub Category',
//     '/terraformer': 'Terraformer',
//   };

  export const getTitle = (path) => {
    switch (path) {
        case '/':
            return 'Dashboard';
        case '/home':
            return 'Dashboard';
        case '/dashboard':
            return 'Dashboard';
        case '/deck-generator':
            return 'Deck Generator';
        case '/solution-gallery':
            return 'Solution Gallery';
        case '/my-alerts-app':
            return 'My Alerts App';
        case '/success-on-demand':
            return 'Success on Demand';
        case '/health-check':
            return 'Health Check App';
        case '/health-check-client':
            return 'Health Check App';
        case '/health-check-data-viewer':
            return 'Health Check Data Viewer';
        case '/migration-wizard':
            return 'Migration Visualization App';
        case '/ps-status':
            return 'PS Status App';
        case '/pd-university':
            return 'PDU Status App';
        case '/onboarding':
            return 'Onboarding App';
        case '/users':
            return 'Administration App';
        case '/terraformer':
            return 'Terraformations Factory';
        default:
            return 'App Page';
      }
  };
