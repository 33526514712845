import React, { useState, useEffect } from "react";
import { apiRoutes, apiRequest } from "../../services";
import LoadingPagey from "../../components/LoadingPagey";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

import {
  AreaContainer,
  Button,
  CirclesContainer,
  FormContainer,
  LoginContainer,
  RightPanel,
  RightPanelBottom,
  RightPanelContainer,
  WallpaperContainer,
  OrLabel,
} from "./loginStyled";
import InnovationLogo from "../../assets/InnovationLogo.png";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Banner from "../../components/Banner";
import { bannerSubject } from "../../services/subjects";
export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [featureFlags, setFeatureFlags] = useState(null);
  const [selectedNewIndex, setSelectedNewIndex] = useState(0);
  const newsArray = [
    {
      image: "https://images.innovation.csg.pagerduty.com/loginBackgound1.png",
      area: "CSG Innovation Services",
      title: "October Release",
      description: "New portal features to adapt the new Pagerduty standard brand.",
      launch: false,
      items: [
        {
          "title": "Fresh New Look",
          "item": [
          ]
        },
        {
          "title": "Health Check Alert App",
          "item": [
            "Health Check Alerts alignment to Operational Maturity Model (OMM), with capability to filter based on each OMM category in the Health Check Alerts Overview",
            "Teams Comparison by total number of alerts per entity type for the teams of interest",
            "2 new Health Check Alerts: Escalation Policies with Gaps Count and Team Escalation Policies with Gaps %",
          ]
        },
        {
          "title": "Solutions Gallery",
          "item": [
            "New filters, for a more agile and intuitive search",
          ]
        }
      ],
    },

    {
      image: "https://images.innovation.csg.pagerduty.com/loginBackgound1.png",
      area: "CSG Innovation Services",
      title: "Premium Services Customer Entitlements",
      description: "New portal features to adapt the new Pagerduty standard brand.",
      items: [
        {
          "item": [
            "Service Request Hours ",
            "PagerDuty University Learning & Certifications",
            "Professional Services Optimize & Automation Engagements",
            "Premium Support Cases "
          ]
        }
        // "Service Request Hours ",
        // "PagerDuty University Learning & Certifications",
        // "Professional Services Optimize & Automation Engagements",
        // "Premium Support Cases "
      ],
      launch: false
    },
    {
      image: "https://images.innovation.csg.pagerduty.com/loginBackgound1.png",
      area: "CSG Innovation Services",
      title: "Universal Request",
      description: "New portal features to adapt the new Pagerduty standard brand.",
      items: [
        {
          "item": [
            "Service Request",
            "Premium Support Request",
            "PagerDuty University Request",
            "Feature Request",
          ]
        },
        // "Service Request",
        // "Premium Support Request",
        // "PagerDuty University Request",
        // "Feature Request",
      ],
      launch: false
    },
    {
      image: "https://images.innovation.csg.pagerduty.com/loginBackgound1.png",
      area: "CSG Innovation Services",
      title: "Health Check Alerts",
      description: "New portal features to adapt the new Pagerduty standard brand.",
      items: [
        {
          "item": [
            "Urgent Recommendations", "One-click Service Request submission from each Health Check Alert."
          ]
        },
        // "Urgent Recommendations", "One-click Service Request submission from each Health Check Alert."
      ],
      launch: false
    },
    // {
    //   image: "https://images.innovation.csg.pagerduty.com/loginBackgound1.png",
    //   area: "CSG Innovation Services",
    //   title: "Product Subscriptions, Add-Ons and Deployment Metrics",
    //   description: "New portal features to adapt the new Pagerduty standard brand.",
    //   launch: false
    // },
  ];

  const getLoginWithOkta = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes["login"],
      });

      if (!error) {
        window.location.replace(data.url);
      }
    }, 1500);
  };

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem("mostrarModal");
    getFeatureFlag();
  }, []);



  useEffect(() => {
    if (featureFlags && featureFlags.portal_maintenance) {
      bannerSubject.next({
        // message: 'The team is currently making updates related to the monthly release, and some features in the portal may be unavailable and the estimated time to release those changes is 1 to 3 hours.',
        message: 'We are currently updating our data, which may result in some temporary inconsistencies.',
        type: 'error',
        icon: faWarning
      });
    } else {
      bannerSubject.next(null);
    }

  }, [featureFlags]);

  const getFeatureFlag = async () => {
    const [error, res] = await apiRequest({
      method: "get",
      url: apiRoutes.featureFlags,
    });
    if (res) {
      setFeatureFlags(res.features);
    }
  };

  useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => {
      setSelectedNewIndex(() =>
        selectedNewIndex !== newsArray.length - 1 ? selectedNewIndex + 1 : 0
      );
    }, 10000);


    return () => {
      clearInterval(intervalId);
    };
  }, [isHovered, selectedNewIndex]);

  const getLoginWithOauth = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.loginOauth,
      });
      if (!error) {
        window.location.replace(data.url);
      }
    }, 1500);
  };

  return (

    <div className="login-page-wrapper">
      {featureFlags && <Banner />}
      <LoginContainer>
        <AreaContainer>
          <CirclesContainer>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </CirclesContainer>
        </AreaContainer>
        {isLoading ? (
          <>
            <FormContainer>
              <LoadingPagey state={0}></LoadingPagey>
            </FormContainer>
          </>
        ) : (
          <>
            <FormContainer>
              <RightPanelContainer>
                <RightPanel>
                  <img
                    src={InnovationLogo}
                    alt="Innovation Logo"
                    style={{
                      height: "60px",
                      marginBottom: "15px",
                      alignSelf: "baseline",
                    }}
                  />
                  <span
                    style={{
                      alignSelf: "baseline",
                      fontSize: "12px",
                      marginBottom: "15px",
                      color: "grey",
                      textAlign: "left",
                    }}
                  >
                    The CSG Premium Service Portal is more than just a portal; it's your one-stop command center for maximizing the value of your PagerDuty Operations Cloud and Premium Services investment. From a centralized view of your Premium Services entitlements, a streamlined request experience, product deployment insights and proactive health check alerts, the portal empowers you to drive desired outcomes with ease. It's your centralized hub for action, success, and maximizing your PagerDuty investment.
                  </span>
                  <Button onClick={() => getLoginWithOauth()}>
                    Premium Customer Login (PagerDuty Login/Password)
                  </Button>
                  <OrLabel>or</OrLabel>
                  <Button onClick={() => getLoginWithOkta()}>
                    Dutonian Login (Okta)
                  </Button>
                  <span style={{ marginTop: "20px" }}>
                    Problems?, <a href="mailto:CSG-Innovation@pagerduty.com">CSG-Innovation@pagerduty.com</a>
                  </span>
                </RightPanel>
                <RightPanelBottom>
                  Copyright © {new Date().getFullYear()} CSG Innovation Services -
                  Pagerduty, Inc. All rights reserved.
                </RightPanelBottom>
              </RightPanelContainer>
              <WallpaperContainer
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  backgroundImage: `url(${newsArray[selectedNewIndex].image}`,
                }}
              >
                {/* <div>
                  <h2>Highlights</h2>
                  <ul role="list" className="login-features">
                    <li class="relative">
                      <div class="login-feature__item">
                        <FeatureContainer>
                          <p>Premium Services Customer Entitlements</p>
                        </FeatureContainer>
                      </div>
                    </li>
                    <li class="relative">
                      <div class="login-feature__item">
                        <FeatureContainer>
                          <p>Product Subscriptions, Add-Ons and Deployment Metrics</p>
                        </FeatureContainer>
                      </div>
                    </li>
                    <li class="relative">
                      <div class="login-feature__item">
                        <FeatureContainer>
                          <p>Universal Request </p>
                        </FeatureContainer>
                      </div>
                    </li>
                    <li class="relative">
                      <div class="login-feature__item">
                        <FeatureContainer>
                          <p>Health Check Alerts</p>
                        </FeatureContainer>
                      </div>
                    </li>
                  </ul>
                </div> */}
                <div
                  style={{
                    alignSelf: "baseline",
                    textAlign: "left",
                    fontSize: "30px",
                  }}
                >
                  {newsArray[selectedNewIndex].title}
                </div>
                {newsArray[selectedNewIndex].items?.length > 0 && <div style={{ display: "flex" }}>
                  <ul className="item-list">
                    {newsArray[selectedNewIndex].items.map((item) => (
                      <div>
                        <div className="title__sliderLogin">{item?.title}</div>
                        {item?.item?.map(description => (
                          <li className="item-feature">{description}</li>
                        ))}
                      </div>
                    ))}
                  </ul>
                </div>}
                {/* { newsArray[selectedNewIndex].launch && <Fireworks autorun={{ speed: 3 }} />

                } */}
                {/* <WallpaperContainerBottom>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {newsArray[selectedNewIndex].area}
                    <div style={{ display: "flex", columnGap: "5px" }}>
                      <ArrowButton
                        onClick={() =>
                          setSelectedNewIndex(
                            selectedNewIndex !== 0
                              ? selectedNewIndex - 1
                              : newsArray.length - 1
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </ArrowButton>
                      <ArrowButton
                        onClick={() =>
                          setSelectedNewIndex(
                            selectedNewIndex !== newsArray.length - 1
                              ? selectedNewIndex + 1
                              : 0
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </ArrowButton>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "left",
                      marginTop: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {newsArray[selectedNewIndex].description}
                  </div>
                </WallpaperContainerBottom> */}
              </WallpaperContainer>
            </FormContainer>
          </>
        )}
      </LoginContainer>
    </div>
  );
}