import styled from "styled-components";

export const ChartContainerRelative = styled.div`
  position: relative;
  width: 72px;
  height: 72px;
`;

export const ChartContainerAbsolute = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  transform: translate(-33%, -21%);
`;
