import React from "react";

const TableHead = ({ headings }) => {
  return (
    <thead className="Table-Head">
      <tr>
        {headings &&
          headings.map((item, i) => (
            <th key={`table-head-cell-${i}`}>{item}</th>
          ))}
      </tr>
    </thead>
  );
};

export default TableHead;
