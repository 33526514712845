import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addNotification = (notif) => {
    setNotifications((prevNotifs) => [...prevNotifs, notif]);
  };

  const updateNotification = (id, progress) => {
    setNotifications((prevNotifs) =>
      prevNotifs.map((notif) => notif.id === id ? { ...notif, progress: progress } : notif)
    );
  };

  const updateFinishNotification = (id, message) => {
    setNotifications((prevNotifs) =>
      prevNotifs.map((notif) => notif.id === id ? { ...notif, message: message, progress: undefined} : notif)
    );
    setTimeout(() => {
      removeNotification(id);
    }, 6000);
  };

  const removeNotification = (id) => {
    setNotifications((prevNotifs) => prevNotifs.filter((notif) => notif.id !== id));
  };

  const value = {
    notifications,
    addNotification,
    updateNotification,
    removeNotification,
    updateFinishNotification,
    isModalOpen,
    setIsModalOpen
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};