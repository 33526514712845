import React from 'react';
import './ContentTabs.css'

const ContentTabs = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="container__tabs">
      {tabs.map(tab => (
        <div
          key={tab.entity_id}
          className={activeTab === tab.id ? 'tab__active' : 'tab__inactive'}
          onClick={() => onTabClick(tab.id)}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );
};

export default ContentTabs;
