import React from 'react';
import './SummaryTable.css'; 

const SummaryTable = ({ data, headers }) => {
  return (
    <div className="summary-table">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{row.objectType}</td>
              {row.severityLevels.map((level, levelIndex) => (
                <td key={levelIndex}>{level}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummaryTable;
