import { useState, useEffect, useRef, useCallback } from "react";
import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IfoIcon } from "../../assets/Info-icon.svg";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";
import { ReactComponent as Rocket } from "../../assets/rocket.svg";
import { ReactComponent as Circle } from "../../assets/circle.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../components/Modal";
import ModalHome from "../../components/ModalHome/ModalHome.js";
import Pagination from "../../components/PaginationA";
import UxPagination from "../../components/UxPagination";
import Copyright from "../../components/Copyright"
import useCSV from "../../components/DownloadCSV/index.js"
import { apiRoutes, apiRequest } from "../../services";
import SkeletonNews from "../../components/Skeletons/News/News";
import pager from "../../assets/pagey.png"
import ThankYou from "../../components/ThankYou";
import VerticalCard from "../../components/VerticalCard"
import './MyAlertsApp.css'
import { useNavigate  } from "react-router-dom";
import { controlTour } from "../../services/subjects.js";
import { showCartAlert } from "../../services/subjects.js";
import MultiCheckbox from "../../components/MultiCheckbox";
import ContainerCard from '../../components/ContainerCard'
import TitleApp from '../../components/TitleApp'
import Dashboard from "./Dashboard/Dashboard.js"
import DashboardTeam from "./DashboardTeam/DashboardTeam.js"
import ModalHealthCheckAlert from  "./ModalHealthCheckAlert.js"
import Jwt from "../../utils/jwt";
import { useAuth } from "../../UserContext.js";
import Spinner from '../../components/Spinner/Spinner'
import CloudCheckMark from "../../assets/CloudCheckMark.svg";
import {
  SEVERITY_CRITICAL_ID,
  SEVERITY_MAJOR_ID,
  SEVERITY_HIGH_ID,
  SEVERITY_MEDIUM_ID,
  SEVERITY_LOW_ID,
  SEVERITY_MINOR_ID,
  SEVERITY_CRITICAL,
  SEVERITY_MAJOR,
  SEVERITY_HIGH,
  SEVERITY_MEDIUM,
  SEVERITY_LOW,
  SEVERITY_MINOR,
} from "../../utils/constants.js";
import ContentTabs from '../../components/ContentTabs'
import { colorBySeverity, backgroundOmm, returnIcon } from "../../utils/colors";
import CartAlert from './CartAlert/CartAlert.js'



export default function MyAlertsApp() {
  const { addAlertToCart, deleteAlertsInCart, cartAlert, clearAllAlerts } = useAuth()
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const [openFreeTrialModal, setOpenFreeTrialModal] = useState(false);
  const [checkedSortByAlphabetically, setCheckedSortByAlphabetically] = useState(false);
  const [onSearchNews, setOnSearchNews] = useState('');
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [newsCurrent, setNewsCurrent] = useState([]);
  const [loadingNews, setLoadingNews] = useState(false);
  const [severity, setSeverity] = useState([]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [, setIsOpenDropdownEntity] = useState(false);
  const [isOpenDropdownEntityTeams, setIsOpenDropdownEntityTeams] = useState(false);
  const dropdownRef = useRef(null);
  const [filterSeverity, setFilterSeverity] = useState([]);
  const [filterServices, setFilterServices] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [filterEscalationPolicy, setFilterEscalationPolicy] = useState([]);
  const [filterSchedules, setFilterSchedules] = useState([]);
  const [filterEntitiesTeams, setFilterEntitiesTeams] = useState([])
  const [filterOmmCategories, setFilterOmmCategories] = useState([]);
  const [showModalCustomerRequest, setShowModalCustomerRequest] = useState(false);
  const [loadingRequestForm, setLoadingRequestForm] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [formValues, setFormValues] = useState({
    requestType: 'Account Team Request',
    requestUrgency: "Normal Urgency 7-10 Business Days",
    requestDetails: ''
  });
  const [showErrorCustomerRequest, setShowErrorCustomerRequest] = useState(false);
  const [premiumRequest, setPremiumRequest] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});
  const [, setFirstElement] = useState(0);
  const [, setLastElement] = useState(7);
  const [queryParams, setQueryParams] = useState({});
  const [isOpenDropdownUsers, setIsOpenDropdownUsers] = useState(false);
  const [isOpenDropdownServices, setIsOpenDropdownServices] = useState(false);
  const [isOpenDropdownEscalationPolicy, setIsOpenDropdownEscalationPolicy] = useState(false);
  const [isOpenDropdownSchedules, setIsOpenDropdownSchedules] = useState(false);
  const [isOpenDropdownOmmCategories, setIsOpenDropdownOmmCategories] = useState(false);
  const [showModalOpenCart, setShowModalOpenCart] = useState(false);
  const [showGWP, setShowGWP] = useState(false);
  const [GWP, setGWP] = useState([]);
  const [selectedGWP, setSelectedGWP] = useState({});
  const [loadingGWP, setLoadingGWP] = useState(false);
  const [searchSeverity, setSearchSeverity] = useState('');
  const [searchOmmCategories, setSearchOmmCategories] = useState('');
  const [searchUsers, setSearchUsers] = useState('');
  const [searchServices, setSearchServices] = useState('');
  const [searchEscalationPolicy, setSearchEscalationPolicy] = useState('');
  const [searchSchedules, setSearchSchedules] = useState('');
  const [currentSession, setCurrentSession] = useState('team');
  const [totalAlerts, setTotalAlerts] = useState('0');
	const [totalByTypeEntity, setTotalByTypeEntity] = useState([]);
  const [showModalYesNot, setShowModalYesNot] = useState(false);
  const dropdownEntityTeamsRef = useRef(null);
  const dropdownRefUsers = useRef(null);
  const dropdownServicesRef = useRef(null);
  const dropdownEscalationPolicyRef = useRef(null);
  const dropdownSchedulesRef = useRef(null);
  const dropdownOmmCategoriesRef = useRef(null);
  const [showModalHealthCheckAlert, setShowModalHealthCheckAlert] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadMonthlyLoading, setDownloadMonthlyLoading] = useState(false);
  const [onePage, setOnePage] = useState({});
  const [entitiesTeams, setEntitiesTeams] = useState([]);
  const [entitiesUsers, setEntitiesUsers] = useState([]);
  const [entitiesServices, setEntitiesServices] = useState([]);
  const [escalationPolicy, setEscalationPolicy] = useState([]);
  const [ommCategories, setOmmCategories] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [errorReport, setErrorReport] = useState(false);
  const [isUsersEmpty, setIsUsersEmpty] = useState(false);
  const [isServicesEmpty, setIsServicesEmpty] = useState(false);
  const [isEscalationPolicyEmpty, setIsEscalationPolicyEmpty] = useState(false);
  const [isSchedulesEmpty, setIsSchedulesEmpty] = useState(false);
  const [selectNew, setSelectNew] = useState({});
  const [searchEntityTeam, setSearchEntityTeams] = useState('');
  const [profileRegion, setProfileRegion] = useState('');
  const [userRole, setUserRole] = useState("");
  const [pageNavigationClick, setPageNavigationClick] = useState(false);
  const { downloadCSV } = useCSV();


  useEffect(() => {
    getProfile()
    getPremiumRequest()
    getSeverity()
    getTotalAlert()
    getEntityTeams('Teams',setEntitiesTeams)
    getOmmCategories()


    showCartAlert.subscribe((state) => {
      setShowModalOpenCart(state)
    });

    const tokenData = Jwt.getItem("all");
    if (tokenData !== null) {
        getRole(tokenData);
    }
  }, [])
  useEffect(() => {
    const currentStep = sessionStorage.getItem("currentStep");
    setTimeout(() => {
      if (currentStep === "6") {
        onChangeCurrentSession('alerts');
          controlTour.next(true);
      }
    }, 100);
  }, [sessionStorage.getItem("currentStep")]);

  useEffect(() => {
    if(filterEntitiesTeams.length > 0) {
      getAllFilters()
    } else {
    setEntitiesUsers([])
    setEntitiesServices([])
    setEscalationPolicy([])
    setSchedules([])
  }

  if(filterEntitiesTeams.length === 0  && (filterUsers.length > 0 || filterEscalationPolicy.length > 0 || filterSchedules.length > 0 )) {
    delete queryParams.entity_id
    setFilterEntitiesTeams([])
    setFilterUsers([])
    setFilterServices([])
    setFilterEscalationPolicy([])
    setFilterSchedules([])
    setOnSearchNews('')
    setNewsCurrent(news)
    setTotalPage(totalAlerts)
  }
  }, [filterEntitiesTeams]);


  useEffect(() => {
    const sortNews = (newsArray, ascending) => {
      return newsArray.sort((a, b) => 
        ascending ? a?.title.localeCompare(b?.title) : b?.title.localeCompare(a?.title)
      );
    };

    const sortedNewsCurrent = sortNews([...newsCurrent], checkedSortByAlphabetically);
    const sortedNews = sortNews([...news], checkedSortByAlphabetically);

    setNews(sortedNews);
    setNewsCurrent(sortedNewsCurrent);
  }, [checkedSortByAlphabetically]);


  useEffect(() => {
    if(Object.keys(queryParams).length > 0) {
      setCurrentPage(1, getNewsParams())
    } else {
      currentSession === 'alerts' && getNews()
    }
  }, [queryParams]);

  useEffect(() => {
    // if(pageNavigationClick) {
    //   getNewsParams()
    //   setPageNavigationClick(false)
    // }
      getNewsParams()
    // if(currentPage === 0) {
    //   setNewsCurrent(news)
    // }
  }, [currentPage]);


  useEffect(() => {
    if (onSearchNews === '') {
      addQueryParam('search_text', onSearchNews)
    }
  }, [onSearchNews]);


  const closeDropdowns = useCallback((event) => {
    const dropdowns = [
      { ref: dropdownRef, setter: setIsOpenDropdown },
      { ref: dropdownRefUsers, setter: setIsOpenDropdownUsers },
      { ref: dropdownServicesRef, setter: setIsOpenDropdownServices },
      { ref: dropdownEscalationPolicyRef, setter: setIsOpenDropdownEscalationPolicy },
      { ref: dropdownSchedulesRef, setter: setIsOpenDropdownSchedules },
      { ref: dropdownEntityTeamsRef, setter: setIsOpenDropdownEntityTeams },
      {ref: dropdownOmmCategoriesRef, setter: setIsOpenDropdownOmmCategories }
    ];

    dropdowns.forEach(({ ref, setter }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setter(false);
      }
    });
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeDropdowns);
    return () => document.removeEventListener('click', closeDropdowns);
  }, [closeDropdowns]);


  const getRole = (tokenData) => {
    tokenData.claims.roles.map((role) => {
      setUserRole(role.role);
    });
  };


  const getPremiumRequest = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.premiumRequest,
    });
    if (!error) {
      setPremiumRequest(data?.data?.request_severity)
    }
  }

  const getAllFilters = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getFilterTeams}?entity_id=${filterEntitiesTeams}`,
    });

    if(Object.keys(data).length > 0) {
      setEntitiesUsers(data['users'])
      setEntitiesServices(data['services'])
      setEscalationPolicy(data['escalation_policies'])
      setSchedules(data['schedules'])
      setIsUsersEmpty(data['users'].length === 0);
      setIsServicesEmpty(data['services'].length === 0);
      setIsEscalationPolicyEmpty(data['escalation_policies'].length === 0);
      setIsSchedulesEmpty(data['schedules'].length === 0);
    }
  }

  const getTotalAlert = async () => {
    setLoadingTotal(true);
    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getTotalAlert,
      });

      if (data?.status_code === 200) {
        data?.news_summary?.total_rows && setTotalAlerts(data?.news_summary?.total_rows);
        data?.news_summary?.news_summary && setTotalByTypeEntity(data?.news_summary?.news_summary);
      } else {
        console.error('Error fetching total alerts:', error);
      }
    } catch (error) {
      console.error('Error during API request:', error);
    } finally {
      setLoadingTotal(false);
    }
  }

  const getNews = async () => {
    setNews([])
    setNewsCurrent([])
    setLoadingNews(true)
    setErrorAlert(false)

    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getNews}?limit=100&page=${currentPage}`,
    });


    if (data?.news?.news) {
      setNews(data?.news?.news)
      setTotalPage(data?.news?.total_rows)
      setNewsCurrent(data?.news.news)
      setLoadingNews(false)
    } else {
      setErrorAlert(true)
      setLoadingNews(false)
      console.error('Error fetching:', error);
    }
  };
  
  const getNewsParams = async () => {
    setNewsCurrent([])
    setLoadingNews(true)
    setErrorAlert(false)
    let updatedQueryParams = queryParams

    if (
      (filterEntitiesTeams.length > 0 && filterSeverity.length > 0 && 
       filterUsers.length === 0 &&
       filterServices.length === 0 &&
       filterEscalationPolicy.length === 0 &&
       filterSchedules.length === 0 &&
       onSearchNews === '' &&
       filterOmmCategories.length === 0 
      ) || 
      (filterEntitiesTeams.length > 0 && filterSeverity.length === 0 && 
       filterUsers.length === 0 &&
       filterServices.length === 0 &&
       filterEscalationPolicy.length === 0 &&
       filterSchedules.length === 0 && 
       onSearchNews === '' &&  filterOmmCategories.length === 0 ) ||
       (filterEntitiesTeams.length > 0 && filterOmmCategories.length > 0 && 
        filterUsers.length === 0 &&
        filterServices.length === 0 &&
        filterEscalationPolicy.length === 0 &&
        filterSchedules.length === 0 &&
        onSearchNews === '' 
       )
    ) 
    {
      const matchedRelations = entitiesTeams
        .filter(entity => filterEntitiesTeams.includes(entity.entity_id))
        .map(entity => entity.relations_data)
        .flat();

      const updatedEntityIds = [...new Set([...matchedRelations, ...(queryParams.entity_id || [])])];
    
      updatedQueryParams = {
        ...queryParams,
        entity_id: updatedEntityIds,
      };
    }
    

    const newQueryString = Object.keys(updatedQueryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(updatedQueryParams[key])}`)
    .join('&');


      // const page = Object.keys(queryParams).length > 0 ? 1 : currentPage
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.getNews}?${newQueryString}&limit=100&page=${currentPage}`,
      });

      if (data.status_code === 200) {
          setLoadingNews(false)
          setNewsCurrent(data?.news.news);
          setTotalPage(data?.news?.total_rows)
      } else {
        setErrorAlert(true)
        setLoadingNews(false)
        console.error('Error fetching:', error);
      }
    // }
  };


  const handleToggleAlphabetically = () => setCheckedSortByAlphabetically(!checkedSortByAlphabetically);

  const handleDropdownClick = () => {
    setIsOpenDropdownEntity(false); 
    setIsOpenDropdown(!isOpenDropdown);
  };

  const handleDropdownEntityTeamsClick = () => setIsOpenDropdownEntityTeams(!isOpenDropdownEntityTeams)
  
  const handleDropdownUsersClick = () => setIsOpenDropdownUsers(!isOpenDropdownUsers)
  
  const handleDropdownServicesClick = () => setIsOpenDropdownServices(!isOpenDropdownServices)
  
  const handleDropdownEscalationPolicyClick = () => setIsOpenDropdownEscalationPolicy(!isOpenDropdownEscalationPolicy)
  
  const handleDropdownClickSchedules = () => setIsOpenDropdownSchedules(!isOpenDropdownSchedules)
    
  const handleDropdownClickOmmCategories = () => setIsOpenDropdownOmmCategories(!isOpenDropdownOmmCategories)


  const onSubmit = async () => {
    setLoadingRequestForm(true)
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.premiumRequest,
      data: {
        "request_type": 'Account Team Request',
        "request_urgency": formValues?.requestUrgency,
        "request_details": formValues?.requestDetails,
        'request_alert_title': selectedAlert?.title
      }
    });
    if (data?.status === 200) {
      setShowThankYou(true)
      setLoadingRequestForm(false)
      setTimeout(() => {
        setShowThankYou(false)
        setFormValues({
          requestType: 'Account Team Request',
          requestUrgency: "Critical Urgency 1-3 Business Days",
          requestDetails: ''
        })
        setShowModalCustomerRequest(!showModalCustomerRequest)
      }, 1000);
    } else {
      setLoadingRequestForm(false)
      setShowErrorCustomerRequest(true)
      console.error('Error fetching:', error);
    }
  }

  const handleInputChange = (e) => {
    const fieldName = e.target.name;

    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: e.target.value,
    }))
  }

  const showTextSeverity = (severity) => {
    const severityMapping = {
      1: 'Critical',
      13: 'Major',
      11: 'High',
      4: 'Medium',
      2: 'Low',
      12: 'Minor',
    };
    return severityMapping[severity] || '';
  }

  const backgroundStatus = (status) => {
    const severityMapping = {
      1: { 'backgroundStatus': '#FFE7E7', 'color': '#f00' },
      13: { 'backgroundStatus': '#ff872e', 'color': '#fff' },
      11: { 'backgroundStatus': '#f7c604', 'color': '#fff' },
      4: { 'backgroundStatus': '#690375', 'color': '#fff' },
      2: { 'backgroundStatus': '#329cad', 'color': '#fff' },
      12: { 'backgroundStatus': '#cecece', 'color': '#808080' },
    };
    return severityMapping[status] || '';
  }


  const toggleCheckbox = (severity) => {
    addQueryParam('severity', severity);
    toggleCheckboxAll(severity, setFilterSeverity)
  }

  const  toggleCheckboxEntitiesTeams  = (entities) => { 
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterEntitiesTeams)
  }

  const toggleCheckboxUsers = (entities) => {
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterUsers)
  }

  const toggleCheckboxServices = (entities) => {
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterServices)
  }

  const toggleCheckboxEscalationPolicy = (entities) => {
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterEscalationPolicy)
  }

  const toggleCheckboxSchedules = (entities) => {
    addQueryParam('entity_id', entities);
    toggleCheckboxAll(entities, setFilterSchedules)
  }
  const toggleCheckboxOmmCategories = (ommCategories) => {
    addQueryParam('omm_categories', ommCategories);
    toggleCheckboxAll(ommCategories, setFilterOmmCategories)
  }

  

  const addQueryParam = (key, value) => {
    setQueryParams(prevParams => {
      const newParams = { ...prevParams };
      const isEmptyValue = value === undefined || value === null || value === '';

      if (!isEmptyValue) {
        if (newParams.hasOwnProperty(key)) {
          const index = newParams[key].indexOf(value);
          if (index !== -1) {
            newParams[key] = newParams[key].filter(v => v !== value);
            if (newParams[key].length === 0) {
              delete newParams[key];
            }
          } else {
            newParams[key] = [...newParams[key], value];
          }
        } else {
          newParams[key] = [value];
        }
      } else {
        const { [key]: removedParam, ...restParams } = newParams;
        return restParams;
      }
  
      return newParams;
    });
  };
  

  const getSeverity = async () => {
    try {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getSeverity,
      });
      if (data?.data) {
        setSeverity(data.data)
      }
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  const onSearchAlerts = () => {
    addQueryParam('search_text', onSearchNews)
  }


  const onShowGWP = async (gwpId) => {
    setLoadingGWP(true)
    setGWP([])
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getGWP}/${gwpId}`,
      newDomain: true,
    });
    if (data?.length > 0) {
      setGWP(data)
      setLoadingGWP(false)
    } else {
      setLoadingGWP(false)
    }
  }

  const getOmmCategories = async () => { 
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getOmmCategories}`,
    });

    if (!error) {
      const mappedData = Object.keys(data).map(key => ({
        ids: data[key],
        description: key
      }));
      setOmmCategories(mappedData)
    } else {
    }
  };

  const goToSra = (sraSelect) => {
    let sra = sraSelect?.map(item => item?.name).join(','); 
    navigate(`/solution-gallery/${sra}`)
  }

  const getSelectedGWP = (alert) => setSelectedGWP(alert)
  

  const handleBackSpace = (e) => {
    if (!queryParams?.search_text?.includes(onSearchNews) && e.key === 'Enter') {
      addQueryParam('search_text', '');
      onSearchAlerts(); 
    } 
  }

  const toggleCheckboxAll = async (item, setState) => {
    setState(prevState => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);

      if (itemIndex === -1) {
        newState.push(item);
      } else {
        newState?.splice(itemIndex, 1);
      }
      return newState;
    });
  };

  const filteredOptions = (options, searchState, field) => {
    return options?.filter(option =>
      (field ? option[field] : option)?.toLowerCase().includes(searchState?.toLowerCase()) 
    );
  };

  const onChangeCurrentSession = (item) => {
    allAlert()
    setCurrentSession(item)
  }
  
  const downloadAlerts = async (status) => {
    setDownloadLoading(true)
    setShowModalYesNot(false)
    let params = ''
    if (status === 'yes') {
      params = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
    }

    try {
      await downloadCSV(`${apiRoutes.downloadAlerts}?${params}`, "Alerts", "csv");
    } catch (error) {
      console.error('Error downloading support summary CSV:', error);
    } finally {
      setDownloadLoading(false);
    }
  } 

  const downloadMonthlyOverview =  async (status) => {
    setDownloadMonthlyLoading(true);
    try {
      const resp  = await downloadCSV(apiRoutes.downloadMonthlyOverview, "MonthlyOverview", "pdf");
    
      if (resp[0].status === 404) {
        setErrorReport(true)
        setTimeout(() => setErrorReport(false), 1000);
      }
    } catch (error) {
      console.error('Error downloading support summary CSV:', error);
    } finally {
      setDownloadMonthlyLoading(false);
    }
  }

  const validateParameterToShowModal = async () => {
    let status = ''
    if (Object.keys(queryParams).length >  0) {
      setShowModalYesNot(true)
      status = 'yes'
      return
    }
    setShowModalYesNot(false)
    downloadAlerts(status)
  }

  const getOnePage = async (onePage, newItem) => { 
    setSelectNew(newItem)
    setOnePage(onePage)
  }

  const getEntityTeams = async (entities, setEntities) => {
    setEntities([])
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.filterEntity}?pd_entities=${entities}`,
    });

    if (data?.data) {
      setEntities(data?.data?.entities)
    }
  }

  const allAlert  = async () => {
    setFilterEntitiesTeams([])
    setFilterUsers([])
    setFilterServices([])
    setFilterEscalationPolicy([])
    setFilterSchedules([])
    setFilterSeverity([])
    setFilterOmmCategories([])
    setQueryParams({})
    setOnSearchNews('')
    setNewsCurrent(news)
  }

  const tryAgin = () => {
    if(Object.keys(queryParams).length  === 0) {
      getNews()
    } else {
      getNewsParams()
    }
  }

  const getProfile = async () => { 
    const tokenData = Jwt.getItem("all");
    if (tokenData) {
      tokenData?.claims?.roles.map(async (role) => {
        if(role.role  === 'Admin' || role.role === 'Premium Csg Member') {
          const region = localStorage.getItem("region");
          setProfileRegion(region)
        }
        if (role.role  === 'Customer') {
          const [error, data] = await apiRequest({
            method: "get",
            url: apiRoutes.userProfile,
          });
          if (data?.data) {
            setProfileRegion(data?.data.customer_region || []);
          } 
        }
      })
    }
  };


  const formatTime = (minutes) => {  
    if (minutes === 60) {
      return "1 hour";
    } else if (minutes % 60 === 0) {
      return `${minutes / 60} ${minutes / 60 === 1 ? 'hour' : 'hours' } `;
    } else if (minutes < 60) {
      return `${parseFloat(minutes).toFixed(0)} ${parseFloat(minutes).toFixed(0) === '1' ? 'minute' :  'minutes'}`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours > 0 ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''} ${remainingMinutes > 0 ? `${remainingMinutes.toFixed(0)}  ${remainingMinutes.toFixed(0) === 1 ?  'minute' :  'minutes' }` : ''}`.trim();
    }
  }


  const returnIdSeverity = (severity) => {
    const severityToIdMap = {
      [SEVERITY_CRITICAL]: SEVERITY_CRITICAL_ID,
      [SEVERITY_MAJOR]: SEVERITY_MAJOR_ID,
      [SEVERITY_HIGH]: SEVERITY_HIGH_ID,
      [SEVERITY_MEDIUM]: SEVERITY_MEDIUM_ID,
      [SEVERITY_LOW]: SEVERITY_LOW_ID,
      [SEVERITY_MINOR]: SEVERITY_MINOR_ID,
    };
  
    return severityToIdMap[severity] || ''; 
  };

  const filterBySeverityClickDashboard = (alert) => {


    let score = 0
    let severity = ""
    const criticalAlert = alert.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_CRITICAL)
    const criticalMajor = alert.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_MAJOR)
    const criticalHigh = alert.alerts_count_by_severity.filter(severity => severity.alert_level === SEVERITY_HIGH)

    if (criticalAlert.length > 0) {
      criticalAlert.forEach(alert => {
        if (alert.alert_count > score) {
          score = alert.alert_count
          severity = alert.alert_level
        }
      });
    } else if (criticalMajor.length > 0) {
      criticalMajor.forEach(alert => {
        if (alert.alert_count > score) {
          score = alert.alert_count
          severity = alert.alert_level
        }
      });
    } else if (criticalHigh.length > 0) {
      criticalHigh.forEach(alert => {
        if (alert.alert_count > score) {
          score = alert.alert_count
          severity = alert.alert_level
        }
      });
    }
    toggleCheckbox(returnIdSeverity(severity))
  }
  
  const renderExpectedValue = (parts) => {
    const expectedParts = parts[1]?.split(' The expected value is : ');
    return expectedParts.length > 1 ? expectedParts[1] : 'N/A';
  };

  const renderSections = (parsedValue) => (
    parsedValue?.metadata?.sections?.map((section, index) => (
      <div key={index} style={{ marginTop: '3px' }}>
        <span style={{ fontWeight: section.bold ? 'bold' : 'normal' }}>
          {section.label}:
        </span> {section.value}
      </div>
    ))
  );


  const filteredOptionsTypeEntityTeams = filteredOptions(entitiesTeams, searchEntityTeam, 'entity_name')
  const filteredOptionsUsers = filteredOptions(entitiesUsers, searchUsers, 'entity_name');
  const filteredOptionsServices = filteredOptions(entitiesServices, searchServices, 'entity_name');
  const filteredOptionsEscalationPolicy = filteredOptions(escalationPolicy, searchEscalationPolicy, 'entity_name');
  const filteredOptionsSchedules = filteredOptions(schedules, searchSchedules, 'entity_name');
  const filteredOptionsSeverity = filteredOptions(severity, searchSeverity, 'description');
  const filteredOptionsOmmCategories = filteredOptions(ommCategories, searchOmmCategories, 'description');
  const disabledBtnSave = formValues.requestType !== '' && formValues.requestUrgency !== '' ? '' : 'disabled__dtnSave'
  const buttonTabs =[{
    'name':`Team's Dashboard`,
    'id': 'team'
  },
  {
    'name':`Health Check Alerts Overview`,
    'id': 'alerts'
  },
  {
    'name':`Health Check Alerts Dashboard`,
    'id': 'dashboard'
  }
]

  return (
    <div className='container__newsAdministrationAlerts'>
      <div className="">
          <ContentTabs
            tabs={buttonTabs}
            activeTab={currentSession}
            onTabClick={onChangeCurrentSession}
          />
      </div>
      {currentSession === 'dashboard' && 
        <Dashboard  
          returnIdSeverity={returnIdSeverity}
          toggleCheckbox={toggleCheckbox}
          searchedSelectedAlert={(alert, isFilterActive) => {
            allAlert()
            addQueryParam('search_text', alert?.alert_name || alert)
            setOnSearchNews(alert?.alert_name || alert)
            setCurrentSession('alerts')
            isFilterActive && filterBySeverityClickDashboard(alert)
          }}
        />}
      {currentSession === 'team' && <DashboardTeam
        searchedSelectedAlert={(alert) => {
          allAlert()
          addQueryParam('search_text', alert)
          setOnSearchNews(alert)
          setCurrentSession('alerts')
        }
        } 
        toggleCheckboxEntitiesTeams={(id) => {
          toggleCheckboxEntitiesTeams(id)
          setCurrentSession('alerts')
        }
        }
      />}
      {currentSession === 'alerts' && 
        <ContainerCard>
          <div className="container__filtersAlert" id="advancedFilters">
            <div className="container__filterAlerts"> 
              <MultiCheckbox
                label="Team Name"
                options={entitiesTeams}
                filterState={filterEntitiesTeams}
                setFilterState={setFilterEntitiesTeams}
                isOpenDropdown={isOpenDropdownEntityTeams}
                searchState={searchEntityTeam}
                setSearchState={setSearchEntityTeams}
                filteredOptions={filteredOptionsTypeEntityTeams}
                handleDropdownClick={handleDropdownEntityTeamsClick}
                toggleCheckbox={toggleCheckboxEntitiesTeams}
                mapFunction={(item) => item?.entity_id}
                nameLabel={(item) => item?.entity_name}
                dropdownRef={dropdownEntityTeamsRef}
                tooltip={true}
                disabled={!filteredOptionsTypeEntityTeams.length > 0}
                disabledItem={loadingNews}
                /> 
                <MultiCheckbox
                label="Users"
                options={entitiesUsers}
                filterState={filterUsers}
                setFilterState={setFilterUsers}
                searchState={searchUsers}
                setSearchState={setSearchUsers}
                filteredOptions={filteredOptionsUsers}
                handleDropdownClick={handleDropdownUsersClick}
                toggleCheckbox={toggleCheckboxUsers}
                isOpenDropdown={isOpenDropdownUsers}
                mapFunction={(item) => item?.entity_id}
                nameLabel={(item) => item?.entity_name}
                dropdownRef={dropdownRefUsers}
                disabled={filterEntitiesTeams.length === 0}
                isEmptyValue={isUsersEmpty}
                disabledItem={loadingNews}
                />
                <MultiCheckbox
                label="Services"
                options={entitiesServices}
                filterState={filterServices}
                setFilterState={setFilterServices}
                searchState={searchServices}
                setSearchState={setSearchServices}
                filteredOptions={filteredOptionsServices}
                handleDropdownClick={handleDropdownServicesClick}
                toggleCheckbox={toggleCheckboxServices}
                isOpenDropdown={isOpenDropdownServices}
                mapFunction={(item) => item?.entity_id}
                nameLabel={(item) => item?.entity_name}
                disabled={filterEntitiesTeams.length === 0}
                dropdownRef={dropdownServicesRef}
                isEmptyValue={isServicesEmpty}
                disabledItem={loadingNews}
                />
                <MultiCheckbox
                label="Escalation Policy"
                options={escalationPolicy}
                filterState={filterEscalationPolicy}
                setFilterState={setFilterEscalationPolicy}
                searchState={searchEscalationPolicy}
                setSearchState={setSearchEscalationPolicy}
                filteredOptions={filteredOptionsEscalationPolicy}
                handleDropdownClick={handleDropdownEscalationPolicyClick}
                toggleCheckbox={toggleCheckboxEscalationPolicy}
                isOpenDropdown={isOpenDropdownEscalationPolicy}
                mapFunction={(item) => item?.entity_id}
                nameLabel={(item) => item?.entity_name}
                disabled={filterEntitiesTeams.length === 0}
                dropdownRef={dropdownEscalationPolicyRef}
                isEmptyValue={isEscalationPolicyEmpty}
                disabledItem={loadingNews}
                />
                <MultiCheckbox
                label="Schedules"
                options={schedules}
                filterState={filterSchedules}
                setFilterState={setFilterSchedules}
                searchState={searchSchedules}
                setSearchState={setSearchSchedules}
                filteredOptions={filteredOptionsSchedules}
                handleDropdownClick={handleDropdownClickSchedules}
                toggleCheckbox={toggleCheckboxSchedules}
                isOpenDropdown={isOpenDropdownSchedules}
                mapFunction={(item) =>  item?.entity_id}
                nameLabel={(item) => item?.entity_name}
                dropdownRef={dropdownSchedulesRef}
                disabled={filterEntitiesTeams.length === 0}
                isEmptyValue={isSchedulesEmpty}
                disabledItem={loadingNews}
                />
                <MultiCheckbox
                label="Severity"
                options={severity}
                filterState={filterSeverity}
                setFilterState={setFilterSeverity}
                searchState={searchSeverity}
                setSearchState={setSearchSeverity}
                filteredOptions={filteredOptionsSeverity}
                handleDropdownClick={handleDropdownClick}
                toggleCheckbox={toggleCheckbox}
                isOpenDropdown={isOpenDropdown}
                mapFunction={(item) => item?.id}
                nameLabel={(item) => item?.description}
                dropdownRef={dropdownRef}
                disabled={loadingNews}
                />
    
                <MultiCheckbox
                label="Operational Maturity Categories"
                options={ommCategories}
                filterState={filterOmmCategories}
                setFilterState={setFilterOmmCategories}
                searchState={searchOmmCategories}
                setSearchState={setSearchOmmCategories}
                filteredOptions={filteredOptionsOmmCategories}
                handleDropdownClick={handleDropdownClickOmmCategories}
                toggleCheckbox={toggleCheckboxOmmCategories}
                isOpenDropdown={isOpenDropdownOmmCategories}
                mapFunction={(item) => item?.ids}
                nameLabel={(item) => item?.description}
                dropdownRef={dropdownOmmCategoriesRef}
                disabled={loadingNews}
                tooltip={true}
                />
            </div>
            <div className="input__containerNewsAdministration">
                <input
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="search__input"
                  placeholder="Search by Health Check Alert Title..."
                  value={onSearchNews}
                  onChange={(e) => setOnSearchNews(e.target.value)}
                  onKeyDown={(e) => handleBackSpace(e)}
                />
              </div>
              <div className="container__buttonsFilters">
                  <div className="container__toggleDownload">
                    <div className="newsAdministration__toggle filterAZ">
                      <input
                        type="checkbox"
                        id="toggleAlphabetically"
                        checked={checkedSortByAlphabetically}
                        onChange={handleToggleAlphabetically}
                        className="toggle__orderInput"
                      />
                      <label htmlFor="toggleAlphabetically" className="toggle__sliderMyAlerts" >
                        {!checkedSortByAlphabetically && <div className="toggle__active">Az</div>}
                        {checkedSortByAlphabetically && <div className="toggle__disabled">Za</div>}
                      </label>
                    </div>
                    <div className="container__downloadClear">
                      <div>	
                      <div className="container__buttonDownload">
                        <div className="button__andSpinner">
                            <div onClick={() => validateParameterToShowModal()} className="button__downloadHealthCheckAlerts">
                              Download Health Check Alerts
                              {downloadLoading &&  <div>
                              <svg viewBox="25 25 50 50" className="small-svg">
                              <circle r="20" cy="50" cx="50"></circle>
                            </svg></div>}
                              </div>
                          
                        </div>
                        <div className=" button__andSpinner">
                            <div onClick={() => downloadMonthlyOverview()} className="button__downloadHealthCheckAlerts">
                              Historical Health Check Report
                              {downloadMonthlyLoading &&  <div>
                                  <svg viewBox="25 25 50 50" className="small-svg">
                                  <circle r="20" cy="50" cx="50"></circle>
                                </svg></div>}
                            </div>
                        </div>
                      </div>
                      {errorReport && <div className="not__haveHistory">We do not have enough history at this time to generate this report.</div>}
                        {showModalYesNot && 
                          <div className="container__yesAndNot">
                            <div> Do you want to download it with the filters you have selected?</div>
                            <div className="buttons__yesNot">
                                <button onClick={()=> downloadAlerts('yes')} className="selectSession__button buttonDownload">Yes</button>
                                <button onClick={()=> downloadAlerts('no')} className="selectSession__button buttonDownload">No</button>
                                <button onClick={()=> setShowModalYesNot(false)} className="selectSession__button buttonDownload">Close</button>
                            </div>
                        </div>}
                      </div>
                    </div>
                    <div className="text__clear " onClick={() => clearAllAlerts()} >
                      Clear cart
                    </div>
                  </div>
                  <div className="container__clearSearch">
                    <div className="text__clear" onClick={() => allAlert()}>Clear filter</div>
                    <button 
                    onClick={() => {
                      if (!queryParams?.search_text?.includes(onSearchNews)) {
                        addQueryParam('search_text', '');
                        onSearchAlerts(); 
                      } 
                    }} 
                    disabled={queryParams?.search_text?.some(text => text === onSearchNews) ? true : false}
                    className="btn__search" >Search</button>
                  </div>
              </div>
        </div>
        <div className="container__paginationAlert">
          {/* <Pagination
            numberOfElements={totalPage}
            showElementsPerPage={100}
            onPaginationChange={(firstElement, lastElement) => {
              setFirstElement(firstElement);
              setLastElement(lastElement);
            }}
            currentPage={(e) => setCurrentPage(e)}
            pageClickNavigation={(e) => setPageNavigationClick(e)}
            /> */}
            <UxPagination
              productsPage={100}
              currentPage={
                currentPage
              }
              setCurrentPage={
                setCurrentPage
              }
              totalProducts={totalPage || 1}
            />
        </div>
        <div className="container__alertsFound">
            <div><Circle /></div>
            <div>{totalPage}</div>
            <div>Alerts found</div>
        </div>
        <div className="container__alertAdministration">
          <div style={{width: '100%'}}>
            {loadingNews && !newsCurrent.length && <SkeletonNews />}
            <div className="alert-grid">
              {newsCurrent?.map((newItem, index) =>   (
                <VerticalCard borderColor={colorBySeverity(newItem?.severity)?.color}>
                  <div  key={index} className="container__healthCheckAlerts">
                      <div className="healthCheckAlerts__header">
                          <div style={{color: colorBySeverity(newItem?.severity)?.color}} >{showTextSeverity(newItem?.severity)}</div>
                          <div className="healthCheckAlerts__headerDate">
                              <div className="headerDate__alert"> 
                                <Calendar/>
                                <span className="creation_date">{newItem?.creation_date}</span>
                              </div>
                              <div >
                                <button 
                                  disabled={newItem?.one_pager_data?.length === 0}
                                  onClick={() => {
                                    setShowModalHealthCheckAlert(true)
                                    getOnePage(newItem?.one_pager_data?.length && newItem?.one_pager_data[0], newItem)
                                  }} 
                                  className="icon__infoMyAlert"><IfoIcon />
                                </button>
                              </div>
                          </div>
                      </div>
                      <div className="healthCheckAlerts__body">
                          <TitleApp>{newItem?.title}</TitleApp>
                          <div className="body__entity" >
                              <div ><FontAwesomeIcon  icon={returnIcon(newItem?.pd_entities)} /></div>
                              <div>{newItem?.pd_entities_labels ? newItem?.pd_entities_labels : newItem?.pd_entities}</div>
                          </div>
                          <div className="body__descriptionAlert">{newItem?.description?.split('The current value is : ')[0]?.replace('. .', '.') || 'N/A'}</div>
                          <p className="descriptionAlert__currentValue">
                            {(() => {
                              const parts = newItem?.description?.split('The current value is : ');
                              if (parts && parts.length > 1) {
                                const currentValue = parts[1]?.split(' The expected value is : ')[0];
                                const parsedValue = currentValue?.startsWith('{') ? JSON.parse(currentValue) : null;

                                return (
                                  <>
                                    <div>
                                      <b className="expected__value">The current value is : </b> <span className="body__descriptionAlert">{parsedValue?.current_value || currentValue || 'N/A'}</span>
                                    </div>
                                    <div >
                                      <b className="expected__value">The expected value is : </b> <span className="body__descriptionAlert">{renderExpectedValue(parts)}</span>
                                    </div>

                                    {parsedValue?.metadata && (
                                      <div style={{ marginBottom: '15px', marginTop: '10px' }}>
                                        <b className="expected__value">{parsedValue.metadata.title}:</b>
                                        <div style={{ marginTop: '4px', marginBottom: '15px' }}>
                                          {renderSections(parsedValue)}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              }
                              return 'N/A';
                            })()}
                          </p>
                      </div>
                      {
                        newItem?.omm_categories_data?.length > 0 &&
                        <div>
                          <div>Operational Maturity Alignment</div>
                          <div className="container__omm">
                            {newItem?.omm_categories_data.map(omm =>
                              <div style={{backgroundColor: backgroundOmm(omm).background}} className="item__omm ">{omm}</div>
                            )}
                          </div>
                      </div>
                      }
                      <div className="container__buttonsCardAlerts">
                        <div >
                          <button 
                            disabled={newItem?.sra_data?.length === 0}
                            onClick={() => goToSra(newItem?.sra_data)}
                            className="name__viewSRA" 
                          >View SRA</button>
                        </div>
                        <div>
                          <div className="button__getWellPlan" onClick={() => 
                            {
                              onShowGWP(newItem?.gwp_id)
                              setShowGWP(true)
                              getSelectedGWP(newItem)
                            }}>
                              Get Well Plan <span className="icon__rocket"><Rocket/></span>
                          </div>
                      </div>
                      <div>
                        {cartAlert?.some(alert => alert?.id === newItem?.id) ? 
                          <button onClick={() => {
                            deleteAlertsInCart(newItem?.id)
                            }} 
                            className="btn__serviceRequest"><FontAwesomeIcon icon={faTrash} /></button> :
                          <button onClick={() => {
                              addAlertToCart(newItem)
                            }} 
                            className="btn__serviceRequest">Service Request</button> 
                          }
                      </div> 
                      </div>
                  </div>
                </VerticalCard>
              ))}
            </div>
            {errorAlert && !newsCurrent.length &&
              <div className="container__dataNotFoundAlerts">
                <div>Oops, it looks like we weren’t able to find your Alerts information. We kindly request you to try Again later.</div>
                <button className="button__try" onClick={() => tryAgin()}>Try Again</button>
              </div>}
            {!newsCurrent.length && !loadingNews && !errorAlert && profileRegion !== "JAPAN" &&
              <div className="container__notAlerts">
                <div>
                  <div className="text__noAlert">No alerts found</div>
                  <img className="container__imgNoAlerts" src={pager}   alt=''></img>
                </div>
              </div>}
              {profileRegion === "JAPAN" &&  !loadingNews && !newsCurrent.length && !errorAlert &&
                <div className="container__japan">
                  Are you interested in seeing the health of your current PagerDuty environment? Please contact your PagerDuty Account Team and request to be onboarded for PagerDuty Automated Health Check services for Premium Customers.
                </div>}
          </div>
        </div>
        </ContainerCard>}
      <div className="container__copy"><Copyright /></div>
      {showModalOpenCart && 
        <CartAlert
        userRole={userRole}
        setShowModalOpenCart={setShowModalOpenCart}
        setOpenFreeTrialModal={setOpenFreeTrialModal}
        />
      }
      <Modal
        show={showModalCustomerRequest}
        handleClose={() => setShowModalCustomerRequest(!showModalCustomerRequest)}
        modalFitContent
        title={'Customer Request Form'}
        type="small"
      >
        {loadingRequestForm && <Spinner/>}
        {!loadingRequestForm && !showThankYou &&
          <div className="projects mb-4">
            <div className="container__fields">
              <div className="title__modal">Request Type</div>
              <div className="text__typeRequest">{formValues?.requestType}</div>
            </div>
            <div className="container__fields">
              <div className="title__modal">Alert title</div>
              <div className="text__typeRequest">{selectedAlert?.title}</div>
            </div>
            <div className="container__fields">
              <div className="title__modal">Request Urgency</div>
              <div>
                {premiumRequest?.length > 0 &&
                  <select
                    name="requestUrgency"
                    onChange={handleInputChange}
                    className="select__requestUrgency"
                    value={formValues.requestUrgency}
                  >
                    {premiumRequest?.map((item) => (
                      <option key={item.id} value={item?.description}>{item?.description}</option>
                    ))}
                  </select>}
              </div>
            </div>
            <div className="container__fields">
              <div className="title__modal">Request Detail</div>
              <div>
                <textarea name='requestDetails' onChange={handleInputChange} value={formValues.requestDetails} className="textarea__requestDetailAlert">
                </textarea>
              </div>
            </div>
            {showErrorCustomerRequest &&
              <div className="container__fields container__error">
                <div> We apologize for the inconvenience caused. Our team is currently facing a technical issue and we are working hard to resolve it. We kindly request you to try Again later. If you require any assistance, please feel free to contact our support team on the Slack channel #csg-innovation-portal.Thank you for your understanding.CSG Innovation Services Team.</div>
                <button className="button__try"
                  onClick={() => {
                    setLoadingRequestForm(false)
                  }}>Try Again</button>
              </div>}
            <div>
              <button
                className={`submit__button ${disabledBtnSave}`}
                disabled={formValues.requestType === '' || formValues.requestUrgency === '' || formValues.requestDetails === ''}
                onClick={() => { onSubmit() }}
              >
                Submit
              </button>
            </div>
          </div>}

        {showThankYou && <div className="container__thankYou"><ThankYou /></div>}
      </Modal>
      <Modal
        show={showGWP}
        handleClose={() => setShowGWP(!showGWP)}
        modalFitContent
        title={`Get Well Plan: ${selectedGWP?.title}`}
        type="small"
      >
        <div>
          {loadingGWP &&  <Spinner/>}
          <div>
            {GWP?.map((item, index) =>
              <div key={index}>
                  <div className="title__steps">The following steps are provided to guide you in addressing this Health Check Alert. PagerDuty Professional Services is always available to help via a Service Request.</div>
                  <div className="description__steps">{item?.description}</div>
                  <div className="container__steps">
                    <div className="steps__title">
                      <div>Steps </div>
                      <div>Duration: {formatTime(item.total_effort)}</div>
                    </div>
                    <ul className="ul__steps">
                        {item?.steps?.map((item, index) => (
                          <li key={index} className="next_steps">
                            {item?.description}
                            <span className="effort__description">{formatTime(item.effort_value)}</span>
                          </li>
                        ))}
                    </ul>
                </div>
              </div>
            )}
          </div>
          {GWP.length === 0 && !loadingGWP &&
            <div className="not__foundGwp">
                No data found
            </div>
          }
        </div>
      </Modal>
      <ModalHome
        show={openFreeTrialModal}
        handleClose={() =>
          setOpenFreeTrialModal(!openFreeTrialModal)
        }
        title={"New Request"}
        modalFitContent
      >
        <div>
          <div className="popupModalHomeSmall">
            <div
              className="round__icon__modal"
              style={{ backgroundColor: "#E2F0E7" }}
            >
              <img src={CloudCheckMark} alt="" role="display" />
            </div>
            <div className="popupModalHomeSmallText">
              <span className="popupModalHomeSecondSmallTextTitle">
              Thank you for your interest, your Customer Success Manager will follow up in the next{" "}
                <span className="popupModalHomeSecondBoldSmallTextTitle">
                72 business hours!
                </span>{" "}
              </span>
            </div>
          </div>
          <button
            onClick={() => {
              setOpenFreeTrialModal(false);
            }}
            className="popupModalHomeSmallButton"
          >
            Close
          </button>
        </div>
      </ModalHome>
      {showModalHealthCheckAlert && <ModalHealthCheckAlert  showGWP={true} getSelectedGWP={getSelectedGWP} setShowGWP={setShowGWP} onShowGWP={onShowGWP} newItem={selectNew} onePage={onePage} onShowModalHealthCheckAlert={setShowModalHealthCheckAlert}/>}
    </div>
  )
}