import React from "react";
import { Terraformer } from "./Terraformer";
import { TerraformerErrorBoundary } from "./TerraformerErrorBoundary";

export default function TerraformerApp() {
  return (
    <TerraformerErrorBoundary>
      <Terraformer />
    </TerraformerErrorBoundary>
  );
}
