import React from 'react';
import './MetricCard.css'; 

const MetricCard = ({ title, value,onClick, text }) => {
  return (
    <div className="metric__card" onClick={onClick}>
      <div className="metric__cardTitle">{title}</div>
      <div className='metric__containerText' >
        <div className="metric__cardValue ">{value}</div>
        <div className='metric__text'>{text}</div>
      </div>
    </div>
  );
};

export default MetricCard;
