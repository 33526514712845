import { useState, useEffect,useRef } from "react";
import { useNavigate  } from "react-router-dom";
import {
  faInfoCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faMagnifyingGlass,
  faCaretDown,
  faCaretUp,
  faArrowUpAZ,
  faArrowUpZA,
  faX,
  faTrash,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import healthCheck from "../../assets/wallpaper1.png";
import PagerdutyServiceRequest from "../../assets/PagerdutyServiceRequest.jpg";
import CorePlatform from "../../assets/CorePlatform.jpg";
import OpenSource from "../../assets/OpenSource.jpg";
import ProfessionalServices from "../../assets/ProfessionalServices.jpg";
import IncidentManagement from "../../assets/IncidentManagement.jpg";
import OnRequestReport from "../../assets/OnRequestReport.jpg";
import PDU from "../../assets/PDU.jpg";
import Solutions from "../../assets/Solutions.jpg";
import SolutionAccelerator from "../../assets/SolutionAccelerator.jpg";
import Copyright from "../../components/Copyright"
import Pagination from "../../components/PaginationA";
import logo from "../../assets/PagerDuty-WhiteRGB_white.svg";
import pageyUser from "../../assets/pageyUser.png";
import { apiRoutes, apiRequest } from "../../services";
import { cartSolution, controlTour } from "../../services/subjects.js";
import SkeletonTop from "../../components/Skeletons/SolutionGallery/SolutionGallery";
import './SolutionGallery.css'



export default function SolutionGallery() { 
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [solutionType, setSolutionType] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [operationCloudProducts, setOperationCloudProducts] = useState([]);
  const [operationCloudAreaProducts, setOperationCloudAreaProducts] = useState([]);
  const [solutionFeature, setSolutionFeature] = useState([]);
  const [card, setCard] = useState([]);
  const [cardTop, setCardTop] = useState([]);
  const [onSearchCategory, setOnSearchCategory] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [productByPage, setProductByPage] = useState(5)
  const [firstElement, setFirstElement] = useState(0);
  const [lastElement, setLastElement] = useState(5);
  const [showRefineResults, setShowRefineResults] = useState(true);
  const [modalOpenDetailSolution, setModalOpenDetailSolution] = useState(false);
  const [isOpenSolutionCategories, setIsOpenSolutionCategories] = useState(false);
  const [isOpenCategories, setIsOpenCategories] = useState(false);
  const [isOpenClassification, setIsOpenClassification] = useState(false);
  const [isOpenEstimatedHours, setIsOpenEstimatedHours] = useState(false);
  const [isOpenSolutionType, setIsOpenSolutionType] = useState(false);
  const [msgCart, setMsgCart] = useState(false);
  const [searchCategories, setSearchCategories] = useState('');
  const [searchClassifications, setSearchClassifications] = useState('');
  const [searchSolutionCategory, setSearchSolutionCategory] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [selectedSolutionCategory, setSelectedSolutionCategory] = useState([]);
  const [selectedSolutionType, setSelectedSolutionType] = useState([]);
  const [productsInTheCart, setProductsInTheCart] = useState([]);
  const [searchSolutionType, setSearchSolutionType] = useState('');
  const [searchFeatureArea, setSearchEstimatedHours] = useState('');
  const [selectedFeatureArea,   setSelectedEstimatedHours] = useState([]);
  const [selectProduct, setSelectProduct] = useState('');
  const [solutionHours, setSolutionHours] = useState({});
  const [loadingTop, setLoadingTop] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [LoadingSRA, setLoadingSRA] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [detailProduct,   setDetailProduct] = useState({});
  const [selectHours, setSelectHours] = useState('');
  const [sraNameSelected, setSraNameSelected] = useState('');
  const sliderRef = useRef(null);
  const dropdownRefClassifications = useRef(null);
  const dropdownRefSolutionCategories = useRef(null);
  const dropdownRefCategories = useRef(null);
  const dropdownRefSolutionType = useRef(null);
  const dropdownRefFeatureArea = useRef(null);
  const originalCardListRef = useRef([]);
  

  useEffect(() => {
    const route = window.location.pathname
    const splitRoute = route.split("/");
    const nameSra = splitRoute[2];
    setSraNameSelected(nameSra)
    if (nameSra === undefined) {
      getSolutionSra()
    }
    getSolutionCategory()
    getOperationCloudProducts()
    getClassifications()
    getOperationCloudAreaProducts()
    getCart()
    getHours()
    getSolutionType()
  }, [])


  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRefClassifications.current && !dropdownRefClassifications.current.contains(event.target)) {
        setIsOpenClassification(false);
      }
      if (dropdownRefCategories.current && !dropdownRefCategories.current.contains(event.target)) {
        setIsOpenCategories(false);
      }
      if (dropdownRefSolutionCategories.current && !dropdownRefSolutionCategories.current.contains(event.target)) {
        setIsOpenSolutionCategories(false);
      }
      if (dropdownRefSolutionType.current && !dropdownRefSolutionType.current.contains(event.target)) {
        setIsOpenSolutionType(false);
      }
      if (dropdownRefFeatureArea.current && !dropdownRefFeatureArea.current.contains(event.target)) {
        setIsOpenEstimatedHours(false);
      }
    };

    document.addEventListener('click', closeDropdown);
  }, [])

  useEffect(() => {
    cartSolution.subscribe((state) => {
      setProductsInTheCart(state)
    });
  }, []);

  useEffect(() => {
    if (onSearchCategory?.length >= 5) {
      addQueryParamSearch('search_text', onSearchCategory)
    } else {
      // addQueryParamSearch('search_text', '')
      setCard(originalCardListRef.current)
    }
  }, [onSearchCategory]);

  useEffect(() => {
    const route = window.location.pathname
    const splitRoute = route.split("/");
    const nameSra = splitRoute[2] || '';
    if (nameSra === '' ){
      getSolutionProducts();
    }
  }, [queryParams]);


  useEffect(() => {
    if (selectedSolutionCategory?.length === 0 || selectedSolutionType?.length === 0) {
      setCard(originalCardListRef.current)
    } 
  }, [selectedSolutionCategory, selectedSolutionType]);


  useEffect(() => {
    if (sraNameSelected !== undefined) {
      getSolutionSraTitle(sraNameSelected)
    } 
  }, [sraNameSelected]);

  const getSolutionType = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.solutionType,
    });

    if (data?.status_code === 200) {
      setSolutionType(data?.data)
    }
  }


  const getSolutionSra = async () => {
    setLoadingTop(true)
    setLoadingProducts(true)
    setLoadingSRA(true)
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.solutionProduct,
    });
    let top = []
    let card = []
    if (data?.status_code === 200) {
      data?.data?.map(item => item?.top !== null ? top.push(item) : card.push(item))
      top?.sort((a, b) =>  a?.top - b?.top);
      originalCardListRef.current = data?.data
      setLoadingTop(false)
      setLoadingProducts(false)
      setLoadingSRA(false)
      setCardTop(top)
      setCard(data?.data)
    }
  }
  
  useEffect(() => {
    const currentStep = sessionStorage.getItem("currentStep");
    if (cardTop.length > 0 && currentStep === "4") {
      setTimeout(() => {
        controlTour.next(true)
      }, 100);
    }
  }, [cardTop, sessionStorage.getItem("currentStep")]);

  const getSolutionProducts = async () => {
    setCard([])
    setLoadingProducts(true)
    const newQueryString = Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');

    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.solutionProduct}?${newQueryString}`,
    });
    let card = []
    if (data?.status_code === 200) {
      setLoadingProducts(false)
      setCard(data?.data)
    }
  }

  const getSolutionSraTitle = async (sraName) => {
    if(sraName !== '') {
      setCard([])
      setLoadingProducts(true)
      const [error, data] = await apiRequest({
        method: "get",
        url: `${apiRoutes.solutionProduct}?title=${sraName}`,
      });
      let card = []
      if (data?.status_code === 200) {
        setLoadingProducts(false)
        setCard(data?.data)
      }
    }
  }

  const getSolutionCategory= async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.solutionCategory,
    });

    if (data?.status_code === 200) {
      setCategory(data.data)
    }
  }

  const getOperationCloudProducts = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.operationCloudProducts,
    });

    if (data?.status_code === 200) {
      setOperationCloudProducts(data.data)
    }
  }

  const getClassifications = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.classifications,
    });

    if (data?.status_code === 200) {
      setClassifications(data.data)
    }
  }

  const getOperationCloudAreaProducts = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getOperationCloudArea}`,
    });

    if (data?.status_code === 200) {
      setOperationCloudAreaProducts(data.data)
    }
  }

  const getSolutionFeature= async (productArea) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.solutionFeature}?category_id=${productArea}`,
    });
    if (data?.status_code === 200) {
      setSolutionFeature(data.data)
    }
  }

  const getHours = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.hoursExpire,
    });
    if (data?.status_code === 200) {
      setSolutionHours(data.data)
    }
  }

  const addCart = (item) => {
    setMsgCart(true)
    setSelectProduct(item)
    setProductsInTheCart(prevState => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);

      if (itemIndex === -1) {
        newState.push(item);
      } 
      cartSolution.next(newState)
      localStorage.setItem("cart", JSON.stringify(newState));
      return newState;
    });
    setTimeout(() => {
      setMsgCart(false)
    }, 1000);
  }

  const isOpenShowRefineResults = () => {
    setShowRefineResults(!showRefineResults)
  }

  const handleDropdownClickSolutionCategories = () => {
    setIsOpenSolutionCategories(!isOpenSolutionCategories);
  }

  const handleDropdownClickCategories = () => {
    setIsOpenCategories(!isOpenCategories);
  }
  const handleDropdownClickClassifications = () => {
    setIsOpenClassification(!isOpenClassification);
  }

  const handleDropdownClickSolutionType  = () => {
    setIsOpenSolutionType(!isOpenSolutionType);
  }
  
  const handleDropdownClickFeatureArea = () => {
    setIsOpenEstimatedHours(!isOpenEstimatedHours);
  }

  const handleSearchChangeCategories = (e) => {
    setSearchCategories(e.target.value);
  };
  const handleSearchChangeClassifications = (e) => {
    setSearchClassifications(e.target.value);
  };

  const handleSearchFeatureArea = (e) => {
    setSearchEstimatedHours(e.target.value);
  }

  const toggleCheckbox = async (item, setState) => {
    setState(prevState => {
      const newState = [...prevState];
      const itemIndex = newState.indexOf(item);
      if (itemIndex === -1) {
        newState.push(item);
      } else {
        newState.splice(itemIndex, 1);
      }
      return newState;
    });
  };

  
  const addQueryParam = (key, value) => {
    
    setQueryParams(prevState => {
      // Get current values as array
      const currentValues = prevState[key] ? 
        typeof prevState[key] === 'string' ? 
          prevState[key].split(',') : 
          Array.isArray(prevState[key]) ? 
            prevState[key] : 
            [prevState[key]]
        : [];
  
      // Handle incoming value - split if it's a comma-separated string
      const incomingValues = Array.isArray(value) ? 
        value[0].split(',') : 
        typeof value === 'string' ? 
          value.split(',') : 
          [value];

  
      // Check if all incoming values exist in current values
      const allValuesExist = incomingValues.every(v => 
        currentValues.includes(v.toString().trim())
      );
  
      if (allValuesExist) {
        // Remove all incoming values from current values
        const updatedValues = currentValues.filter(v => 
          !incomingValues.includes(v.toString().trim())
        );
  
        // If no values left, remove the key entirely
        if (updatedValues.length === 0) {
          const { [key]: removed, ...rest } = prevState;
          return rest;
        }
  
        return {
          ...prevState,
          [key]: updatedValues.join(',')
        };
      } else {
        // Add new values to existing values, avoiding duplicates
        const uniqueValues = [...new Set([...currentValues, ...incomingValues])];
        return {
          ...prevState,
          [key]: uniqueValues.join(',')
        };
      }
    });
  };
  
  // Helper function to normalize values for the toggleCheckbox functions
  const normalizeValue = (value) => {
    return Array.isArray(value) ? value : [value.toString()];
  };
  
  const toggleCheckboxCategories = async (productArea) => {
    addQueryParam('operation_area_id', normalizeValue(productArea.id));
    toggleCheckbox(productArea.id, setSelectedCategories);
  };
  
  const toggleCheckboxClassifications = async (classification) => {
    addQueryParam('classification_id', normalizeValue(classification.id));
    toggleCheckbox(classification.id, setSelectedClassifications);
  };
  
  const addQueryParamSearch = (key, value) => {
    if (value !== undefined && value !== null && value !== '') {
      setQueryParams(prevState => ({
        ...prevState,
        [key]: value
      }));
    } else {
      setQueryParams(prevState => {
        const { [key]: removedParam, ...restParams } = prevState;
        return restParams;
      });
    }
  };

  const handleSearchChangeSolutionCategory = (e) => {
    setSearchSolutionCategory(e.target.value);
  };

  const handleSearchChangeSolutionType = (e) => {
    setSearchSolutionType(e.target.value);
  };

  const toggleCheckboxSolutionCategory = async (solutionCategory) => {
    const index = selectedSolutionCategory.indexOf(solutionCategory?.name);
    if (index !== -1) {
      setSelectedSolutionCategory([]);
      setQueryParams(prevState => {
        const { 'solution_category': removedParam, ...restParams } = prevState;
        return restParams;
      });
    } else {
      setSelectedSolutionCategory([solutionCategory?.name]);
      setQueryParams(prevState => ({
        ...prevState,
        'solution_category': solutionCategory.name
        }));
    }
  }


  const toggleCheckboxSolutionType = async (solutionType) => {
    const index = selectedSolutionType.indexOf(solutionType.name);
    if (index !== -1) {
      setSelectedSolutionType([]);
      setQueryParams(prevState => {
        const { 'solution_type': removedParam, ...restParams } = prevState;
        return restParams;
      });
    } else {
      setSelectedSolutionType([solutionType.name]);
      setQueryParams(prevState => ({
        ...prevState,
        'solution_type': solutionType.name
        }));
    }
  }

  const toggleCheckboxFeatureArea = async (feacture) => {
    toggleCheckbox(feacture.id, setSelectedEstimatedHours)
    addQueryParam('sub_category_id', feacture.id);
  }

  const filteredOptions = (options, searchState) => {
    return options?.filter(option =>
      option?.name ? option?.name?.toLowerCase().includes(searchState?.toLowerCase()) :option?.description?.toLowerCase().includes(searchState?.toLowerCase())
    );
  };


  const toggleSotBySortByAlphabetical = (sort)  => {
    handleSort(sort, card)
  }
  
  const handleSort = (sortByAlphabetical, cardList) => {
    let sortedList = [...cardList];
    
    if (sortByAlphabetical === 'AZ') {
      sortedList?.sort((a, b) => a?.solution_name?.localeCompare(b?.solution_name));
    } else {
      sortedList?.sort((a, b) => b?.solution_name?.localeCompare(a?.solution_name));
    }
    setCard(sortedList)
  };

  const handleHours = (hours) => {
    setSelectHours(hours);
    const cardList = hours === 'all' ? originalCardListRef.current : 
      originalCardListRef.current.filter((item) => item?.solution_hour_cost?.split(' ')[0].trim() === hours);
    setCard(cardList);
  };

  const deleteProduct  = (product) => {
    const filterProduct = productsInTheCart?.filter((item) => item?.solution_id !== product)
    setProductsInTheCart(filterProduct)
    cartSolution.next(filterProduct);
    localStorage.setItem("cart", JSON.stringify(filterProduct));
  }

  const getCart = () => {
    const cartString = localStorage.getItem("cart"); 
    if (cartString) {
      const productsInTheCart = JSON.parse(cartString); 
      setProductsInTheCart(productsInTheCart)
      cartSolution.next(productsInTheCart)
    } 
  }

  const getDetailProduct = async (idProduct) => {
    setDetailProduct(idProduct)
  }

  const onClearSection = () => {
    setCard([])
    navigate('/solution-gallery')
    getSolutionSra()
    setSelectedClassifications([])
    setSelectedCategories([])
    setSelectedEstimatedHours([])
    setSelectedSolutionCategory([]);
    setSelectedSolutionType([])
    setOnSearchCategory('')
  }

  const onLinkSra = (nameSra) => {
    setOnSearchCategory(nameSra)
    setModalOpenDetailSolution(false)
  }

  const returnImg = (img) => {
    const images = {
      'PagerdutyServiceRequest.jpg': PagerdutyServiceRequest,
      'CorePlatform.jpg': CorePlatform,
      'OpenSource.jpg': OpenSource,
      'ProfessionalServices.jpg': ProfessionalServices,
      'PDU.jpg': PDU,
      'Solutions.jpg': Solutions,
      'IncidentManagement.jpg': IncidentManagement,
      'SolutionAccelerator.jpg': SolutionAccelerator,
      'OnRequestReport.jpg': OnRequestReport
    };
    return images[img] || PagerdutyServiceRequest;
  }


  const arrayHours= ['1', '2', '4', '6', '8']
  const filteredOptionsClassifications = filteredOptions(classifications, searchClassifications);
  const filteredOptionsOperationCloudProducts = filteredOptions(operationCloudAreaProducts, searchCategories);

  return (
    <div className='container__solutionGallery'>
      <div className="container__headerSolution">
        <div className="container__textWelcome">
          <div className="header__text welcome__textSolution">Welcome to the PagerDuty Premium Services Solutions Gallery!</div>
          <div className="header__text">Explore our Premium Services Solutions currently available to accelerate deployment, adoption, maturity and your business outcomes using your PagerDuty Operations Cloud platform entitlements.</div>
        </div>
        <div className="container__circle">
          <div className="container__hoursLeft">
              <div className="hoursLef__title">Hours left: {solutionHours?.hours_available || 'none'}</div>
              <div className="hoursLef__title">Expire: {solutionHours?.project_current_active_quarter_end_date || 'none'}</div>
          </div>
          <div className="container__imgUser">
            <img
              className="img__pageyUser"
              src={pageyUser}
            />
          </div>
        </div>
      </div>
      {cardTop && cardTop.length > 0 && 
        <div className="container__sliderText">
          <div className="header__sliderText">Most Requested Solutions from Similar Customers</div>
          <div className="container__sliderSolution">
            <button
                className="btn__sliderSolution"
                onClick={() =>
                  setStartIndex((startIndex - 4 > 0) ? startIndex - 4 : 0)
                }
                disabled={startIndex === 0}
              >
                <FontAwesomeIcon icon={faChevronCircleLeft} />
            </button>
            <div className="container__cardSlider" ref={sliderRef}>
              {!loadingTop && cardTop?.slice(startIndex, startIndex + 4).map((item, indexI) => {
                return (
                  <div className="card__solutionTop" id={indexI === 0 ? "card__solutionTop" : 'card__solutionTopExtras'}>
                    <div className="container__imgSolutionCard">
                      <div className="container__topCard">#{item?.top} Most Requested</div>
                      <div className="container__faInfoCircle" 
                        onClick={() =>{
                          setModalOpenDetailSolution(!modalOpenDetailSolution)
                          getDetailProduct(item)
                        }}
                      ><FontAwesomeIcon icon={faInfoCircle} /></div>
                      <img
                        className="img__card"
                        src={healthCheck}
                      />
                    </div>
                    <div className="body__card">
                      <div className="name__card">{item?.solution_name}</div>
                      <div className="card__nameAndType">
                        <div>{item?.category_name}</div>
                      </div>
                      <div className="container__typicalActivities">
                        {item?.typical_activities?.map(activities => (
                          <div className="description__card">{activities}</div>
                        ))}
                      </div>
                    </div>
                    <div className="container__footerTop">
                        <div className="footer__card">
                          <div className="footer__targetHour">
                            <div className="solution_targetTop">{item?.solution_target}</div>
                            {item?.solution_hour_cost && <div className="card__type">{item?.solution_hour_cost}</div>}
                          </div>
                        </div>
                        {productsInTheCart?.some(product => product?.solution_id === item?.solution_id)? 
                            <div className="container__cardTop "><div className="card__addCarTop" onClick={() => deleteProduct(item?.solution_id)}><FontAwesomeIcon icon={faTrash} /></div></div>
                          : <div className="container__cardTop "><div className="card__addCarTop" onClick={() => addCart(item) }>Add to cart</div></div>
                          }
                        {msgCart && productsInTheCart?.some(product => product?.solution_id === item?.solution_id) && selectProduct?.solution_id === item?.solution_id &&
                          <div className="product__added ">Product added to cart</div>}
                      </div>
                  </div>
                );
              })}
              {loadingTop && <SkeletonTop/>}
              {cardTop?.length === 0 && !loadingTop && <div className="not__data">We have not found any SRA</div>}
            </div>
            <button
              className="btn__sliderSolution"
              onClick={() => setStartIndex(startIndex + 4)}
              disabled={startIndex + 4 >= (cardTop?.length - 1)}
            >
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </button>
          </div>
        </div>}
      <div className="container__filter">
          <div className="text__containerSearch">Discover Available Solutions to Accelerate your Business Objectives</div>
          <div className="container__searchSolutionGallery">
            <div className="input__containerSolutionGallery">
              <input
                name="search-form"
                id="search-form"
                className="searchSolutionGallery__input"
                placeholder="Search by current state challenges, pain points, future state outcomes, product offerings or features"
                value={onSearchCategory}
                onChange={(e) => setOnSearchCategory(e.target.value)}
              />
              {onSearchCategory === '' &&<div className="icon__containerSearch">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </div>}
              {onSearchCategory !== '' &&
                <div className="icon__containerSearch"  onClick={() => setOnSearchCategory('')}>
                  <FontAwesomeIcon icon={faX} />
                </div>}
            </div>
          </div>
          <div className="container__selectGallery">
              <div>
                <span className="title__select">Classification</span>
                <div className="container__solutionCategories">
                    <div className="multiSelect__filterSolutionGallery" ref={dropdownRefClassifications}>
                      <div className="dropdown-toggleButtonSolutionCategories" onClick={() => handleDropdownClickClassifications()} >
                        <div className="container__buttonFilter">
                          {selectedClassifications?.map(filter => (
                            filteredOptionsClassifications?.map(item => (
                              filter === item.id && 
                              <div>
                                <p className="item__filter">{item.description} <FontAwesomeIcon  onClick={() => toggleCheckboxClassifications(item)} icon={faTimes} /></p>
                              </div>
                            ))
                          ))}
                        </div>
                      </div>
                      {isOpenClassification && (
                        <div className="dropdown__solutionCategories_solutionGallery">
                          <input
                            className="search__subdomainHomeFilter"
                            type="text"
                            placeholder="Search classifications..."
                            value={searchClassifications}
                            onChange={handleSearchChangeClassifications}
                          />
                          <ul>
                            {filteredOptionsClassifications
                              ?.sort((a, b) => a.description.localeCompare(b.description))
                              .map(option => (
                                <li key={option.project_id}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={selectedClassifications.includes(option?.id)}
                                      onChange={() => toggleCheckboxClassifications(option)}
                                    />
                                    <span className="customer_name">{option?.description}</span>
                                  </label>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                </div>
              </div>
              <div>
                <span className="title__select">Operations Cloud Product Area</span>
                <div className="container__solutionCategories">
                    <div className="multiSelect__filterSolutionGallery" ref={dropdownRefCategories}>
                    <div 
                      className="dropdown-toggleButtonSolutionCategories" 
                      onClick={() =>  handleDropdownClickCategories()}
                      style={{ 
                        cursor: 'pointer',
                        opacity: '1'
                      }}
                    >
                      <div className="container__buttonFilter">
                        {selectedCategories?.map(filter => (
                          filteredOptionsOperationCloudProducts?.map(item => (
                            filter === item.id && 
                            <div key={item.id}>
                              <p className="item__filter">
                                {item.name}
                                <FontAwesomeIcon  
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent dropdown from opening/closing
                                    toggleCheckboxCategories(item);
                                  }} 
                                  icon={faTimes} 
                                />
                              </p>
                            </div>
                          ))
                        ))}
                      </div>
                    </div>
                      {isOpenCategories && (
                        <div className="dropdown__solutionCategories_solutionGallery">
                          <input
                            className="search__subdomainHomeFilter"
                            type="text"
                            disabled={selectedClassifications.length === 0}
                            placeholder="Search categories..."
                            value={searchCategories}
                            onChange={handleSearchChangeCategories}
                          />
                          <ul>
                            {filteredOptionsOperationCloudProducts?.sort((a, b) => a - b).map(option => (
                              <li key={option.project_id}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedCategories.includes(option?.id)}
                                    onChange={() => toggleCheckboxCategories(option)}
                                  />
                                  <span className="customer_name">{option?.name}</span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                </div>
              </div>
              {/* <div>
                <span className="title__select">Solution Feature Area</span>
                  <div className="container__solutionCategories">
                      <div className="multiSelect__filterSolutionGallery" ref={dropdownRefFeatureArea}>
                        <div className="dropdown-toggleButtonSolutionCategories" onClick={() => handleDropdownClickFeatureArea()} >
                          <div className="container__buttonFilter">
                            {selectedFeatureArea?.map(filter => (
                              filteredOptionsFeatureArea?.map(item => (
                                filter === item?.id && <p className="item__filter">{item.name} <FontAwesomeIcon  onClick={() => toggleCheckboxFeatureArea(item)} icon={faTimes} /></p>
                              ))
                            ))}
                          </div>
                        </div>
                        {isOpenEstimatedHours && (
                          <div className="dropdown__solutionCategories_solutionGallery">
                            <input
                              className="search__subdomainHomeFilter"
                              type="text"
                              placeholder="Search feature area..."
                              value={searchFeatureArea}
                              onChange={handleSearchFeatureArea}
                            />
                            <ul>
                              {filteredOptionsFeatureArea?.sort((a, b) => a - b).map(option => (
                                <li key={option?.project_id}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={selectedFeatureArea.includes(option?.id)}
                                      onChange={() => toggleCheckboxFeatureArea(option)}
                                    />
                                    <span className="customer_name">{option?.name}</span>
                                  </label>
                                </li>
                              ))}
                            </ul>
                            {filteredOptionsFeatureArea.length === 0 && <div className="select__theOperations">Select the Operations Cloud product area</div>}
                          </div>
                        )}
                      </div>
                  </div>
              </div>
              <div>
                <span className="title__select">Solution Category</span>
                <div className="container__solutionCategories">
                    <div className="multiSelect__filterSolutionGallery" ref={dropdownRefSolutionCategories}>
                      <div className="dropdown-toggleButtonSolutionCategories" onClick={() => handleDropdownClickSolutionCategories()} >
                        <div className="container__buttonFilter">
                          {selectedSolutionCategory?.map(filter => (
                            filteredOptionsSolutionCategory?.map(item => (
                              filter === item?.name && 
                              <div>
                                <p className="item__filter">{item?.name} <FontAwesomeIcon  onClick={() =>   setSelectedSolutionCategory([])} icon={faTimes} /></p>
                              </div>
                            ))
                          ))}
                        </div>
                      </div>
                      {isOpenSolutionCategories && (
                        <div className="dropdown__solutionCategories">
                            <input
                              className="search__subdomainHomeFilter"
                              type="text"
                              placeholder="Search solution category..."
                              value={searchSolutionCategory}
                              onChange={handleSearchChangeSolutionCategory}
                            />
                          <ul>
                            {filteredOptionsSolutionCategory?.sort((a, b) => a - b).map(option => (
                                <li key={option?.project_id}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={selectedSolutionCategory.includes(option?.name)}
                                      onChange={() => toggleCheckboxSolutionCategory(option)}
                                    />
                                    <span className="customer_name">{option?.name}</span>
                                  </label>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                </div>
              </div>
              <div>
                <span className="title__select">Solution Type</span>
                <div className="container__solutionCategories">
                    <div className="multiSelect__filterSolutionGallery" ref={dropdownRefSolutionType}>
                      <div className="dropdown-toggleButtonSolutionCategories" onClick={() => handleDropdownClickSolutionType()} >
                        <div className="container__buttonFilter">
                          {selectedSolutionType?.map(filter => (
                            filteredOptionsSolutionType?.map(item => (
                              filter === item?.name && 
                              <div>
                                <p className="item__filter">{item.name} <FontAwesomeIcon  onClick={() =>   setSelectedSolutionType([])} icon={faTimes} /></p>
                              </div>
                            ))
                          ))}
                        </div>
                      </div>
                      {isOpenSolutionType && (
                        <div className="dropdown__solutionCategories">
                           <input
                              className="search__subdomainHomeFilter"
                              type="text"
                              placeholder="Search solution type..."
                              value={searchSolutionType}
                              onChange={handleSearchChangeSolutionType}
                            />
                          <ul>
                            {filteredOptionsSolutionType?.sort((a, b) => a - b).map(option => (
                                  <li key={option.project_id}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={selectedSolutionType.includes(option?.name)}
                                        onChange={() => toggleCheckboxSolutionType(option)}
                                      />
                                      <span className="customer_name">{option?.name}</span>
                                    </label>
                                  </li>
                                ))}
                          </ul>
                        </div>
                      )}
                    </div>
                </div>
              </div> */}
          </div>
          <div  className="container__paginationSolution">
            <Pagination
                numberOfElements={card?.length}
                showElementsPerPage={10}
                onPaginationChange={(firstElement, lastElement) => {
                  setFirstElement(firstElement);
                  setLastElement(lastElement);
                }}
              />
            <div className="container__totalAndClear">
              <div className="clear__selection" onClick={() => onClearSection()}>Reload selection</div>
            </div>
          </div>
          <div></div>
      </div>
      <div className="container__cardAndFilter">
          <div className="container__refineResults">
            <div>
                <div className="tittle__refineResults">
                  <div>Refine Results</div>
                  <div onClick={() => isOpenShowRefineResults()}>
                   {showRefineResults && <FontAwesomeIcon icon={faCaretDown} /> }
                   {!showRefineResults && <FontAwesomeIcon icon={faCaretUp} /> }
                  </div>
                </div>
                {showRefineResults &&
                  <div className="container__resultsFilter">
                      <div className="results__filter">
                        <span className="filter__title">Sort By:</span>
                        <div className="container__itemFilter">
                          <div>
                            <input  
                            onChange={(e) => toggleSotBySortByAlphabetical(e.target.value)} 
                            type="radio" 
                            id="html" 
                            name="fav_language" 
                            value="AZ" 
                            className="input__az"/></div>
                          <div className="icon__az"><FontAwesomeIcon icon={faArrowUpAZ} /> </div>
                        </div>
                        <div className="container__itemFilter">
                          <div>
                            <input  
                              onChange={(e) => toggleSotBySortByAlphabetical(e.target.value)} 
                              type="radio" 
                              id="html" 
                              name="fav_language" 
                              value="ZA" 
                              className="input__az"/>
                          </div>
                          <div className="icon__za"><FontAwesomeIcon icon={faArrowUpZA} /> </div>
                        </div>
                      </div>
                        <div className="results__filter">
                        <span className="filter__title">Hours Estimate (min):</span>
                          <div className="container__itemFilter">
                            <div>
                              <input 
                                type="radio" 
                                onChange={() => handleHours('all')}  
                                value={'all'}
                                checked={selectHours === 'all'}
                                className="input__checkbox" />
                              </div>
                            <div className="text__checkbox">All</div>
                          </div>
                          {arrayHours?.map(hour => (
                            <div className="container__itemFilter">
                              <div>
                                <input  
                                onChange={() => handleHours(hour)}  
                                type="radio" 
                                checked={selectHours === hour}
                                value={hour}
                                className="input__checkbox" /></div>
                              <div className="text__checkbox">{hour} {hour > 1 ? "Hours"  : "Hour"} (min)</div>
                            </div>
                          ))}
                      </div>
                  </div>}
            </div>
          </div>
          {modalOpenDetailSolution && 
          <div className="container__detail">
            <section className="container__section">
              <div className="modal__containerDetail">
                <div className="detail__categoryName">
                  <div>{detailProduct?.classification === "SRA" && `${detailProduct?.category_name}:`} {detailProduct?.sub_category_name} {detailProduct?.classification === "SRA" && "Service Request Accelerator (SRA)"}</div>
                  <di onClick={() => setModalOpenDetailSolution(false)}><FontAwesomeIcon icon={faX} /></di>
                </div>
                <div className="detail__header">{detailProduct?.solution_name}</div>
                <div className="container__subHeader">
                {detailProduct?.solution_target && detailProduct?.solution_hour_cost  && 
                  <div className="container__domainAndHour">
                      <div className="domain" >{detailProduct?.solution_target}</div>
                      <div className="hour">{detailProduct?.solution_hour_cost}</div>
                    </div>}
                 {detailProduct?.classification === "SRA" && <div className="solutionDetail">Professional Services Service Request</div>}
                </div>
                <div className="current__future">
                    {detailProduct?.current_state?.length > 0 &&  
                      <div className="container__currentFuture current">
                          <div className="title__currentFuture">Current State</div>
                          {detailProduct?.current_state?.map((item, index) => (
                          <div key={index} className="item__current__future">{item}</div>
                        ))}
                      </div>}
                    {detailProduct?.future_state?.length > 0 &&
                      <div className="container__currentFuture future">
                          <div className="title__currentFuture">Future State</div>
                          {detailProduct?.future_state?.map((item, index) => (
                          <div key={index} className="item__current__future">{item}</div>
                        ))}
                      </div>}
                </div>
                <div className="container__why">
                  {detailProduct?.typical_activities?.length > 0 && 
                    <div className="why__info">
                      {detailProduct.classification === "SRA" && <div className="why__title">Typical Activities</div>}
                      {detailProduct.classification === "SRA" && <div className="subtitle__activities">A Professional Services consultant will:</div>}
                     {detailProduct.classification === "SRA" && 
                      <di>
                        <ul className="why__ul">
                          {detailProduct?.typical_activities?.map((item, index) => (
                            <li key={index} className="why__li">{item}</li>
                          ))}
                          </ul>
                      </di>}
                      {detailProduct?.classification !== "SRA" && 
                      <div className="not__sra">
                        {detailProduct?.typical_activities?.map((item, index) => (
                          <div key={index} >{item}</div>
                        ))}
                      </div>}
                    </div>}
                  {detailProduct?.typical_activities?.length === 0 && 
                    <div className="not__sra">{detailProduct.description_card}</div>
                  }
                  {detailProduct?.typical_activities?.length === 0 && 
                    detailProduct.cards_field.map(item => (
                      <div style={{marginTop: "10px"}}>{item}</div>
                    ))
                  }
                </div>
                {detailProduct?.next_best?.length > 0 && 
                  <div className="container__whatDoes">
                    <div className="why__title">Next Best SRA(s)</div>
                    <di>
                        {detailProduct?.next_best?.map((item, index) => (
                          <div onClick={() => onLinkSra(item)}><a key={index} className="next_best">{item}</a></div>
                        ))}
                    </di>
                  </div>}
                <div className="footer__detail">
                  <img className="logo__footer" src={logo}/>
                </div>
              </div>
            </section>
          </div>}
          <div className="container__cardHorizontal">
            {card?.map((item, index) => index >= firstElement && index <= lastElement ? (
              <div className="cardHorizontal__container">
                <div className="header__cardHorizontal">
                  <img
                      className="img__cardHorizontal"
                      src={returnImg(item.image)}
                    />
                </div>
                <div className="body__cardHorinzontal">
                  <div className="name__card container__nameAndInfo">
                    <div>{item?.solution_name}</div>
                    <div onClick={() => {
                      setModalOpenDetailSolution(!modalOpenDetailSolution)
                      getDetailProduct(item)
                    }} 
                    className="icon__faInfoCircle">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                  </div>
                  <div className="card__nameAndType">
                    <div>{item?.category_name}</div>
                  </div>
                  <div className="container__typicalActivities">
                      {item.typical_activities.map(activities => (
                      <div className="description__card">{activities}.</div>
                    ))}
                  </div>
                  <div className="container__typicalActivities">
                    {item?.typical_activities.length === 0 &&
                      <div className="description__card">{item.description_card}</div>
                    }
                  </div>
                  <div className="footer__card">
                    <div className="footer__targetHour">
                      <div>{item?.solution_target}</div>
                      <div className="card__type">{item?.solution_hour_cost}</div>
                    </div>
                    {productsInTheCart?.some(product => product?.solution_id === item?.solution_id)? 
                        <div className="card__addCar" onClick={() => deleteProduct(item?.solution_id)}><FontAwesomeIcon icon={faTrash} /></div>
                      :  <div className="card__addCar" onClick={() => addCart(item) }>Add to cart</div>
                    }
                  </div>
                  {msgCart && productsInTheCart?.some(product => product?.solution_id === item?.solution_id) && selectProduct?.solution_id === item.solution_id &&
                    <div className="product__added ">Product added to cart</div>}
                </div>
              </div>): null)}
            {LoadingSRA && <SkeletonTop/>}
            {loadingProducts && <SkeletonTop/>}
            {card.length === 0 && !loadingProducts && !LoadingSRA && <div className="not__data">We have not found any SRA</div>}
          </div>
      </div>
      <div className="container__copy"><Copyright/></div>
    </div>
  )
}