// NotificationDisplay.js
import React, { useContext } from 'react';
import { useNotification } from './../../NotificationContext';
import './Notification.css';

const Notification = () => {
  const { notifications,removeNotification  } = useNotification();;

  const uniqueNotifications = notifications.reduce((unique, notif) => {
    const isDuplicate = unique.find(item => item.id === notif.id && item.message === notif.message);
    if (!isDuplicate) {
      unique.push(notif);
    }
    return unique;
  }, []);

  const handleClose = (id) => {
    removeNotification(id);
  };


  return (
    <div className="notification-container">
      {uniqueNotifications.map((notif) => (
        <div key={notif.id} className="notification">
          <div className='notification-icon__container'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className={`${
            notif.type === 'info' ? 'notification-icon__info' : 
            notif.type === 'warning' ? 'notification-icon__warning' : 
            notif.type === 'error' ? 'notification-icon__error' : ''
          }`}><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd"></path></svg>
          </div>
          <div className='notification-message__container'>
          <p className='notification-message__title'>{notif.message}</p>
          {notif.progress && <progress value={notif.progress} max="100">{notif.progress}%</progress>}
          </div>
          <div className='notification-button__container'>
           <button className='notification-button' onClick={() => handleClose(notif.id)}>
              <span className="notification_close"></span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="notification-button__icon"><path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path></svg>
            </button>
          </div>
          
        </div>
      ))}
    </div>
  );
};

export default Notification;
