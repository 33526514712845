import { useState, useMemo } from "react";
import MultiCheckbox from "./MultiCheckbox";
import { RESOURCE_TITLE_TEXT } from "../constants"

/** @param {Object} props
 * @param {import("../index").TFerStateResourceType} props.resourceType
 * @param {import("../index").TFerAsyncAction} props.resources
 * @param {import("../index").TFerDispatchSetPlan} props.setPlan
 */
export const ResourcePicker = ({ resourceType, resources, setPlan }) => {
  const resource = useResource(resourceType, resources, setPlan);

  return (
    <MultiCheckbox {...resource} />
  );
};

const OPTION_VALUE_FN = {};
const OPTION_VALUE_DEFAULT_FN = (item) => item.id;

const OPTION_LABEL_FN = {};
const OPTION_LABEL_DEFAULT_FN = (item) => item.summary;

const toOption = (key, list) =>
  (list || []).map((item) => {
    let valueFn = OPTION_VALUE_FN[key];
    if (typeof valueFn !== "function") {
      valueFn = OPTION_VALUE_DEFAULT_FN;
    }
    let labelFn = OPTION_LABEL_FN[key];
    if (typeof labelFn !== "function") {
      labelFn = OPTION_LABEL_DEFAULT_FN;
    }
    return { value: valueFn(item), label: labelFn(item) };
  });

/**
 * @param {string} key
 * @param {import("./index").TFerAsyncAction} action
 * @param {import("./index").TFerDispatchSetPlan} setPlan
 */
const useResource = (key, action, setPlan) => {
  if (action == null) action = {};
  const [checked, setChecked] = useState([]);
  const options = useMemo(() => toOption(key, action.data), [key, action.data]);
  return {
    label: RESOURCE_TITLE_TEXT[key],
    disabled: (action.data?.length || 0) > 0 && action.status !== "success",
    checked,
    options,
    onToggleCheckbox: (value) => {
      setChecked((prevState) => {
        const ix = prevState.indexOf(value);
        let newState = [];
        if (ix >= 0) {
          newState = [...prevState.slice(0, ix), ...prevState.slice(ix + 1)];
        } else {
          newState = [...prevState, value];
        }
        setPlan(key)(
          (action.data || []).filter((item) => newState.includes(item.id)),
        );
        return newState;
      });
    },
    pending: action.status === "pending" || action.status === "idle",
    // tooltip: TOOLTIP_TEXT[key],
  };
};

